@charset "UTF-8";
html, body {
  margin: 0;
  padding: 0;
}
html *, body * {
  box-sizing: border-box;
}
html .bg-white, body .bg-white {
  background-color: #fff;
}
html .bg-white *, body .bg-white * {
  color: #000;
}
html .bg-white .icons--dot,
html .bg-white .icons--dot:before, body .bg-white .icons--dot,
body .bg-white .icons--dot:before {
  background-color: #000;
}
html .bg-g-green, body .bg-g-green {
  background: radial-gradient(182% 293.5% at -60% 26%, #00CF9E 0%, #0F8763 100%);
}
html .bg-b-green, body .bg-b-green {
  background: radial-gradient(100% 381.59% at 0% 6.83%, #00CF9E 0%, #009A9A 100%);
}
html .bg-b-green .page-btn, body .bg-b-green .page-btn {
  color: #000 !important;
  background: #fff !important;
}
html .bg-b-green .steps-main__post--ico img, body .bg-b-green .steps-main__post--ico img {
  filter: grayscale(1) brightness(10);
}
html .bg-green, body .bg-green {
  background: radial-gradient(122.72% 100% at 2.53% 0%, #52D2B4 0%, #11425E 100%);
}
html .bg-blue, body .bg-blue {
  background: radial-gradient(122.72% 100% at 2.53% 0%, #019A9A 0%, #0B70A9 100%);
}
@media (min-width: 1024px) {
  html .bg-blue .header-content-nav__subchoose,
html .bg-blue .sub-menu, body .bg-blue .header-content-nav__subchoose,
body .bg-blue .sub-menu {
    background: radial-gradient(122.72% 100% at 2.53% 0%, #019A9A 0%, #0B70A9 100%);
  }
}
html .header-green-line, body .header-green-line {
  background: radial-gradient(10% 170.23% at 0.63% 0%, #00CF9E 0%, #009A9A 100%);
}
html .header-green, body .header-green {
  background: radial-gradient(10% 170.23% at 0.63% 0%, #00CF9E 0%, #009A9A 100%);
  background: radial-gradient(200px 100px at max(50% - 630px, 7vw) 0%, #00CF9E 0%, #009A9A 100%);
}
@media (min-width: 1024px) {
  html .header-green .header-content-nav__subchoose,
html .header-green .sub-menu, body .header-green .header-content-nav__subchoose,
body .header-green .sub-menu {
    background: radial-gradient(38.04% 48.78% at -106.73% 0%, #00CF9E 0%, #009A9A 100%);
  }
}
html .header-blue-line, body .header-blue-line {
  background: linear-gradient(270deg, #0083CC 68.62%, #019A9A 100%);
}
html .header-blue, body .header-blue {
  background: linear-gradient(270deg, #0083CC 68.62%, #019A9A 100%);
  background: radial-gradient(20% 170.23% at max(50% - 630px, 7vw) 0%, #019A9A 10%, #0083CC 100%);
  background: radial-gradient(200px 100px at max(50% - 630px, 7vw) 0%, #019A9A 10%, #0083CC 100%);
}
@media (min-width: 1024px) {
  html .header-blue .header-content-nav__subchoose,
html .header-blue .sub-menu, body .header-blue .header-content-nav__subchoose,
body .header-blue .sub-menu {
    background: linear-gradient(270deg, #0083CC 68.62%, #019A9A 100%);
  }
}
html .bg-blue *,
html .bg-green *,
html .bg-b-green *,
html .header-green *,
html .header-blue *, body .bg-blue *,
body .bg-green *,
body .bg-b-green *,
body .header-green *,
body .header-blue * {
  color: #fff;
}
html .bg-blue.wrapper-form .form__head *,
html .bg-blue.wrapper-form .form__main--check,
html .bg-blue.wrapper-form .main__accept,
html .bg-blue.wrapper-form .main__accept *,
html .bg-green.wrapper-form .form__head *,
html .bg-green.wrapper-form .form__main--check,
html .bg-green.wrapper-form .main__accept,
html .bg-green.wrapper-form .main__accept *,
html .bg-b-green.wrapper-form .form__head *,
html .bg-b-green.wrapper-form .form__main--check,
html .bg-b-green.wrapper-form .main__accept,
html .bg-b-green.wrapper-form .main__accept *,
html .header-green.wrapper-form .form__head *,
html .header-green.wrapper-form .form__main--check,
html .header-green.wrapper-form .main__accept,
html .header-green.wrapper-form .main__accept *,
html .header-blue.wrapper-form .form__head *,
html .header-blue.wrapper-form .form__main--check,
html .header-blue.wrapper-form .main__accept,
html .header-blue.wrapper-form .main__accept *, body .bg-blue.wrapper-form .form__head *,
body .bg-blue.wrapper-form .form__main--check,
body .bg-blue.wrapper-form .main__accept,
body .bg-blue.wrapper-form .main__accept *,
body .bg-green.wrapper-form .form__head *,
body .bg-green.wrapper-form .form__main--check,
body .bg-green.wrapper-form .main__accept,
body .bg-green.wrapper-form .main__accept *,
body .bg-b-green.wrapper-form .form__head *,
body .bg-b-green.wrapper-form .form__main--check,
body .bg-b-green.wrapper-form .main__accept,
body .bg-b-green.wrapper-form .main__accept *,
body .header-green.wrapper-form .form__head *,
body .header-green.wrapper-form .form__main--check,
body .header-green.wrapper-form .main__accept,
body .header-green.wrapper-form .main__accept *,
body .header-blue.wrapper-form .form__head *,
body .header-blue.wrapper-form .form__main--check,
body .header-blue.wrapper-form .main__accept,
body .header-blue.wrapper-form .main__accept * {
  color: #fff !important;
}
html .bg-blue.wrapper-form .form__main .main__accept .checkmark,
html .bg-green.wrapper-form .form__main .main__accept .checkmark,
html .bg-b-green.wrapper-form .form__main .main__accept .checkmark,
html .header-green.wrapper-form .form__main .main__accept .checkmark,
html .header-blue.wrapper-form .form__main .main__accept .checkmark, body .bg-blue.wrapper-form .form__main .main__accept .checkmark,
body .bg-green.wrapper-form .form__main .main__accept .checkmark,
body .bg-b-green.wrapper-form .form__main .main__accept .checkmark,
body .header-green.wrapper-form .form__main .main__accept .checkmark,
body .header-blue.wrapper-form .form__main .main__accept .checkmark {
  border: 1px solid #fff;
}
html .bg-blue.wrapper-form .form__main .main__accept input.error ~ span.checkmark,
html .bg-green.wrapper-form .form__main .main__accept input.error ~ span.checkmark,
html .bg-b-green.wrapper-form .form__main .main__accept input.error ~ span.checkmark,
html .header-green.wrapper-form .form__main .main__accept input.error ~ span.checkmark,
html .header-blue.wrapper-form .form__main .main__accept input.error ~ span.checkmark, body .bg-blue.wrapper-form .form__main .main__accept input.error ~ span.checkmark,
body .bg-green.wrapper-form .form__main .main__accept input.error ~ span.checkmark,
body .bg-b-green.wrapper-form .form__main .main__accept input.error ~ span.checkmark,
body .header-green.wrapper-form .form__main .main__accept input.error ~ span.checkmark,
body .header-blue.wrapper-form .form__main .main__accept input.error ~ span.checkmark {
  border-color: red;
}
html .main-page, body .main-page {
  max-width: 1920px;
  margin: auto;
  overflow-x: hidden;
}
html .wrapper-main, body .wrapper-main {
  display: flex;
  max-width: 1300px;
  width: 100%;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  padding: 40px 0;
  position: relative;
}
html .wrapper-main.z-index-on-top, body .wrapper-main.z-index-on-top {
  z-index: 2;
}
html .wrapper-main.przedsiebiorca, body .wrapper-main.przedsiebiorca {
  padding-bottom: 0;
}
html .wrapper-main.main-ksiegowy, body .wrapper-main.main-ksiegowy {
  padding-bottom: 0;
}
html .wrapper-main *, body .wrapper-main * {
  box-sizing: border-box;
}
html .wrapper-main section, body .wrapper-main section {
  padding-left: 25px;
  padding-right: 25px;
}
html .main-fluid, body .main-fluid {
  display: flex;
  max-width: 100%;
  width: 100%;
  box-shadow: none !important;
  padding: 0;
}
html .main-about, body .main-about {
  padding: 0;
}
html .main-map, body .main-map {
  padding: 0;
  z-index: 1;
}
html .main-map .main-map__wrapper, body .main-map .main-map__wrapper {
  padding-bottom: 30%;
  position: relative;
}
@media (max-width: 768px) {
  html .main-map .main-map__wrapper, body .main-map .main-map__wrapper {
    padding-bottom: 100%;
  }
}
html .main-map .main-map__wrapper iframe, body .main-map .main-map__wrapper iframe {
  position: basolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
@media (min-width: 768px) {
  html .main-map, body .main-map {
    margin: -90px auto 0;
  }
}
html .page-btn,
html .button, body .page-btn,
body .button {
  user-select: none;
  box-sizing: border-box;
  border-radius: 70px;
  font-family: Nunito, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 1;
  letter-spacing: 0em;
  text-align: center;
  height: 55px;
  margin: 20px 10px 20px 0;
  cursor: pointer;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  padding: 0 40px;
}
html .btn-sm, body .btn-sm {
  box-sizing: border-box;
  border-radius: 70px;
  font-family: Nunito, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 1;
  letter-spacing: 0em;
  text-align: center;
  height: 37px;
  margin: 20px 10px 20px 0;
  cursor: pointer;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  padding: 0 35px;
}
html .btn-blue, body .btn-blue {
  background: radial-gradient(122.72% 100% at 2.53% 0%, #019A9A 0%, #0B70A9 100%);
  border: 1px solid #FFFFFF;
  color: #FFFFFF;
  transition: 333ms;
  filter: brightness(1);
}
html .btn-blue:hover, body .btn-blue:hover {
  filter: brightness(1.16);
}
html .btn-white-blue, body .btn-white-blue {
  background: #fff;
  border: 1px solid #0083CC;
  color: #0083CC;
  transition: 333ms;
}
html .btn-white-blue:hover, body .btn-white-blue:hover {
  background: rgba(0, 131, 204, 0.13);
}
html .btn-white, body .btn-white {
  background: #fff;
  border: 1px solid #01bf9c;
  color: #0B8265;
  transition: 333ms;
}
html .btn-white:hover, body .btn-white:hover {
  background: rgba(11, 130, 101, 0.1);
}
html .btn-black, body .btn-black {
  background: #fff;
  border: 1px solid #fff;
  color: #000;
}
html .btn-black:hover, body .btn-black:hover {
  background: #eee;
}
html .btn-green,
html .button--primary.button--cta.button--outline, body .btn-green,
body .button--primary.button--cta.button--outline {
  background: radial-gradient(91.66% 306.32% at 0% 16.18%, #01CC9D 0%, #019A9A 100%);
  color: #FFFFFF;
  border: 0px solid transparent;
  transition: 333ms;
  filter: brightness(1);
}
html .btn-green:hover,
html .button--primary.button--cta.button--outline:hover, body .btn-green:hover,
body .button--primary.button--cta.button--outline:hover {
  filter: brightness(1.1);
}
html .section-bg-img, body .section-bg-img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  mix-blend-mode: multiply;
  z-index: 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
html .wdth100, body .wdth100 {
  width: 100%;
  max-width: 100%;
}
@media (min-width: 768px) {
  html .wdth35, body .wdth35 {
    max-width: 35%;
  }
}
@media (min-width: 768px) and (max-width: 1600px) {
  html .wdth40-med, body .wdth40-med {
    max-width: 40%;
  }
}
@media (min-width: 1600px) {
  html .wdth40, body .wdth40 {
    width: 40%;
  }
}
html .wdth500, body .wdth500 {
  max-width: 500px;
}
@media (max-width: 767.98px) {
  html .mobile-hidden, body .mobile-hidden {
    display: none !important;
  }
}
html .text-center, body .text-center {
  text-align: center !important;
}
html .more-padding, body .more-padding {
  padding: 30px 0;
}
html .no-shadow, body .no-shadow {
  box-shadow: none !important;
}
html .margin-side, body .margin-side {
  margin: 0 auto;
}
html .flex-items-start, body .flex-items-start {
  align-items: flex-start;
}
html .padding-top, body .padding-top {
  padding: 40px 0 0 !important;
}
@media (max-width: 767.98px) {
  html .no-margin, body .no-margin {
    margin: 0 !important;
  }
}
@media (min-width: 768px) {
  html .minus-margin, body .minus-margin {
    margin: -90px 0 0 !important;
  }
}
html .mt-50, body .mt-50 {
  margin-top: 50px !important;
}
html .mh-500, body .mh-500 {
  max-height: 500px;
}
html .maxw-0, body .maxw-0 {
  max-width: 0;
}
html .no-margin, body .no-margin {
  margin: 0;
}
html .d-flex, body .d-flex {
  display: flex !important;
}
html .border-none, body .border-none {
  border: none;
}
@media (min-width: 768px) {
  html .margin-left-left, body .margin-left-left {
    margin: auto auto auto -80px;
  }
  html .margin-left-left img:last-of-type, body .margin-left-left img:last-of-type {
    transform: scale(0.75);
  }
}
@media (min-width: 768px) and (max-width: 1279.98px) {
  html .margin-left-left img:first-child, body .margin-left-left img:first-child {
    transform: scale(1.25);
  }
}
html .font-light, body .font-light {
  font-weight: 300;
}
html .font-bold, body .font-bold {
  font-weight: 600;
}
html .font-black, body .font-black {
  font-weight: 900;
}
html .flex-wrap, body .flex-wrap {
  flex-wrap: wrap;
}
html .mh540, body .mh540 {
  max-height: 540px;
}

.anim-home {
  position: relative;
  max-width: 100%;
}
@media (max-width: 767px) {
  .anim-home {
    max-width: none;
    width: 160%;
    transform: translateX(-10%);
  }
}
.anim-home svg,
.anim-home img {
  max-width: 100%;
}
.anim-home svg {
  position: absolute;
  top: 0;
}

.main-przedsiebiorca .wrapper-ent-top__hero,
.main-przedsiebiorca .form__main--submit,
.main-przedsiebiorca .wrapper-numbers-boxes-num__ico {
  background: radial-gradient(54.26% 161.68% at 0% 16.18%, #00CF9E 0%, #019A9A 100%) no-repeat center/cover;
}
@media (min-width: 1080px) {
  .main-przedsiebiorca .wrapper-used-function-main:first-of-type .func-active .wrapper-used-function-main-box__main:after, .main-przedsiebiorca .wrapper-used-function-main:last-of-type .func-active .wrapper-used-function-main-box__main:after {
    position: absolute;
  }
}

.main-ksiegowy .margin-auto-top {
  margin: 0 !important;
}
.main-ksiegowy .form__main--submit:not(.btn-green),
.main-ksiegowy .wrapper-numbers-boxes-num__ico {
  background: radial-gradient(43.63% 128.84% at 10.62% 27.13%, #019A9A 0%, #0083CC 100%) !important;
}
.main-ksiegowy .slick-dots li {
  border: 1px solid #0083cc !important;
}
.main-ksiegowy .slick-dots li.slick-active {
  background: #0083cc !important;
}
.main-ksiegowy .wrapper-used-function-main-box__main--show {
  max-width: 100% !important;
}
@media (min-width: 1080px) {
  .main-ksiegowy .wrapper-used-function-main-box.func-active .wrapper-used-function-main-box__main {
    min-height: 310px !important;
    padding: 55px 85px 35px;
  }
}
.main-ksiegowy .wrapper-icons {
  margin: 0 auto 4px !important;
}
.main-ksiegowy .wrapper-icons--title {
  font-size: 25px;
  line-height: 34px;
  margin: 40px 0 15px;
}
.main-ksiegowy .wrapper-icons__box .icons--dot,
.main-ksiegowy .wrapper-icons__box .icons--dot:before {
  background-color: #D8D8D8;
}
.main-ksiegowy .wrapper-icons__box .icons--dot:after {
  color: #D8D8D8;
}
@media (min-width: 768px) {
  .main-ksiegowy .wrapper-icons__box:first-of-type .icons--dot:before {
    max-width: 1000px;
    width: 50vw;
  }
}
@media (max-width: 767.98px) {
  .main-ksiegowy .wrapper-acc-top {
    flex-direction: column;
  }
  .main-ksiegowy .wrapper-acc-top__hero {
    display: flex;
    margin: 0;
  }
  .main-ksiegowy .wrapper-acc-top__hero--img {
    margin: -4vw 0 -4vw auto;
    max-width: 40%;
  }
  .main-ksiegowy .wrapper-ent-top__header--title:before,
.main-ksiegowy .wrapper-acc-top__header--title:before,
.main-ksiegowy .wrapper-top__header--title:before {
    background: radial-gradient(122.72% 100% at 2.53% 0%, #019A9A 0%, #0B70A9 100%);
  }
  .main-ksiegowy .wrapper-ent-top__header--title span, .main-ksiegowy .wrapper-ent-top__header--title div,
.main-ksiegowy .wrapper-acc-top__header--title span,
.main-ksiegowy .wrapper-acc-top__header--title div,
.main-ksiegowy .wrapper-top__header--title span,
.main-ksiegowy .wrapper-top__header--title div {
    max-width: 65%;
  }
  .main-ksiegowy .wrapper-ent-top__header--title ul,
.main-ksiegowy .wrapper-acc-top__header--title ul,
.main-ksiegowy .wrapper-top__header--title ul {
    margin: 0;
  }
  .main-ksiegowy .margin-auto {
    margin: auto;
  }
}
@media (max-width: 400px) {
  .main-ksiegowy .wrapper-ent-top__header--title span, .main-ksiegowy .wrapper-ent-top__header--title div {
    max-width: 80%;
  }
}
@media (min-width: 768px) {
  .main-ksiegowy .wrapper-top__hero {
    background: radial-gradient(122.72% 100% at 2.53% 0%, #019A9A 0%, #0B70A9 100%);
    mix-blend-mode: initial;
    width: 50%;
  }
  .main-ksiegowy .wrapper-top__hero--img {
    max-width: 130%;
  }
  .main-ksiegowy .wrapper-acc-top {
    border: none;
    flex-wrap: wrap;
    max-width: 1600px;
  }
  .main-ksiegowy .wrapper-acc-top__hero {
    justify-content: flex-start;
    top: auto;
  }
  .main-ksiegowy .wrapper-acc-top--desc {
    max-width: 100%;
    width: 100%;
  }
  .main-ksiegowy .wrapper-acc-top--desc span {
    max-width: 940px;
    margin: 0 auto !important;
    display: block;
  }
  .main-ksiegowy .wrapper-imgbox__hero {
    margin: auto -60px;
  }
  .main-ksiegowy .flex-end article {
    margin-right: 2vw;
  }
  .main-ksiegowy .wrapper-ent-top__header--title {
    max-width: 500px;
  }
  .main-ksiegowy .wrapper-ent-top__header--desc {
    max-width: 450px;
  }
}
@media (min-width: 768px) and (max-width: 1599.98px) {
  .main-ksiegowy .wrapper-acc-top__hero {
    bottom: calc(-25vw + 420px) !important;
  }
}
@media (min-width: 1600px) {
  .main-ksiegowy .wrapper-acc-top__hero {
    top: 230px !important;
  }
  .main-ksiegowy .wrapper-acc-top__hero--img {
    width: 130%;
    max-width: 130%;
    margin: 200px 40px 0 -60px;
  }
}

.current-perspektywa {
  background-color: #F2F2F230;
}

@media (max-width: 1023.98px) {
  .perspektywa-mobile {
    position: absolute;
    font-size: 12px;
    width: 110px;
    bottom: 22px;
    right: 160px;
    text-align: center;
    padding: 0 0 3px 0;
    border-bottom: 1px solid #fff;
  }
}
@media (min-width: 1024.98px) {
  .perspektywa-mobile {
    display: none;
  }
}

.border-bottom {
  border-bottom: 1px solid #BDBDBD;
}
@media (min-width: 768px) {
  .border-bottom.mobile {
    border: none !important;
  }
}

.font-price-tit {
  font-family: Nunito, sans-serif;
  font-size: 30px !important;
  font-style: normal;
  line-height: 33px !important;
  letter-spacing: 0em;
  text-align: left;
}
@media (max-width: 767.98px) {
  .font-price-tit {
    font-size: 18px !important;
    line-height: 24px !important;
  }
}
.font-price-tit:after {
  background-size: contain !important;
}

.font-price-subtit {
  font-family: Nunito, sans-serif;
  font-size: 25px !important;
  font-style: normal;
  line-height: 29px !important;
  letter-spacing: 0em;
  text-align: left;
}
@media (max-width: 767.98px) {
  .font-price-subtit {
    font-size: 16px !important;
    line-height: 24px !important;
  }
}

.line-center:after {
  position: absolute;
  bottom: 50%;
  left: -150px;
  right: 0;
  margin: auto;
  width: 1600px;
  height: 1px;
  background: #BDBDBD;
  content: "";
}

.line-bottom {
  position: relative;
}
.line-bottom:after {
  position: absolute;
  bottom: 0%;
  margin: auto;
  width: 100vw;
  height: 1px;
  background: #BDBDBD;
  content: "";
  z-index: -1;
}
.line-bottom.bottom-margin:after {
  bottom: 15%;
}
@media (max-width: 768px) {
  .line-bottom.mobile:after {
    visibility: hidden;
  }
}

@media (min-width: 768px) {
  .mh-440 {
    max-height: 440px !important;
  }
}

@media (min-width: 768px) {
  .mw-55 {
    max-width: 55% !important;
  }
}

@media (min-width: 768px) and (max-width: 1599.98px) {
  .mt-90 {
    margin-top: 90px !important;
  }
}

@media (min-width: 768px) and (max-width: 1599.98px) {
  .mb-60 {
    margin-bottom: 60px !important;
  }
}

@media (min-width: 768px) and (max-width: 1599.98px) {
  .mt-mob-desk {
    margin-top: calc(15vw - 150px) !important;
  }
}
@media (min-width: 1600px) {
  .mt-mob-desk {
    margin-top: 100px !important;
  }
}

@media (min-width: 768px) and (max-width: 1599.98px) {
  .img-bigger {
    max-width: 110% !important;
    margin: 0 0 0 -10px;
  }
}

.gm-style .place-card-large {
  display: none !important;
}

@media (max-width: 767.98px) {
  .fz-12 {
    font-size: 12px !important;
  }
}

.wrapper-header-stuck-push {
  transition: all 0.25s, box-shadow 2s;
  z-index: 0;
  min-height: 52px;
}
@media (min-width: 1024px) {
  .wrapper-header-stuck-push {
    min-height: 93px;
  }
}

.wrapper-header {
  display: flex;
  max-width: 100%;
  width: 100%;
  font-family: Montserrat, sans-serif;
  margin: 0;
  padding: 0;
  justify-content: center;
  align-items: center;
  align-content: center;
  position: fixed;
  top: 0;
  z-index: 99;
  transition: all 0.25s, box-shadow 2s;
}
.wrapper-header.stuck {
  box-shadow: #000 0 0 25px -10px;
}
@media (min-width: 1024px) {
  .wrapper-header.stuck {
    min-height: 73px;
  }
}
.wrapper-header.stuck .header-content-nav .sub-menu, .wrapper-header.stuck .header-content-nav__subchoose {
  margin-top: 13px;
}
.wrapper-header .header-content {
  display: flex;
}
.wrapper-header.header-green .header-content .header-content-nav__subchoose {
  position: absolute;
  left: 0;
  z-index: 3;
  background: #009A9A;
}
.wrapper-header.header-blue .header-content .header-content-nav__subchoose {
  position: absolute;
  left: 0;
  z-index: 3;
  background: #0083CC;
}
@media (min-width: 1024px) {
  .wrapper-header {
    min-height: 93px;
  }
}
@media (max-width: 1023.98px) {
  .wrapper-header .header-content {
    display: flex;
    flex-direction: row;
    position: relative;
    margin: 0 auto;
    padding: 0;
    min-height: 66px;
    transition: all 0.25s;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  .wrapper-header .header-content__brand {
    margin: 0 auto 0 0;
    padding: 20px 30px;
    display: inline-block;
    transition: all 0.25s;
  }
  .wrapper-header .header-content__brand--logo {
    max-width: 95px;
  }
  .wrapper-header .header-content-nav {
    transition: all 0.25s;
    margin: 0;
    position: absolute;
    top: 66px;
    top: 0;
    right: 0;
    left: auto;
    height: 0;
    flex-direction: column;
    padding: 20px 30px;
    transition: all 0.25s;
    width: 320px;
    align-items: flex-start;
    display: flex;
    visibility: hidden;
    overflow: auto;
  }
  .wrapper-header .header-content-nav.header-green {
    background: radial-gradient(100% 100% at 0% 0%, #00CF9E 0%, #009A9A 100%);
  }
  .wrapper-header .header-content-nav.header-blue {
    background: radial-gradient(100% 100% at 0% 0%, #019A9A 10%, #0083CC 100%);
  }
  .wrapper-header .header-content-nav.toggled {
    height: 100vh;
    visibility: visible;
  }
  .wrapper-header .header-content-nav.toggled:before {
    content: "";
    background: #000000a6;
    position: fixed;
    inset: 0;
    z-index: -1;
  }
  .wrapper-header .header-content-nav.toggled .header-content-nav__btns--btn:first-of-type {
    position: static;
    border-radius: 4px;
    line-height: 15px;
    text-align: center;
    margin: 15px 0 10px;
    font-size: 16px;
    padding: 13px 26px;
    height: auto;
  }
  .wrapper-header .header-content-nav__choose, .wrapper-header .header-content-nav__menu, .wrapper-header .header-content-nav__subchoose,
.wrapper-header .header-content-nav .sub-menu {
    cursor: pointer;
    list-style: none;
    display: flex;
    flex-direction: column;
  }
  .wrapper-header .header-content-nav__subchoose,
.wrapper-header .header-content-nav .sub-menu {
    min-width: 100%;
  }
  .wrapper-header .header-content-nav__choose, .wrapper-header .header-content-nav__menu {
    margin: 0;
    padding: 0;
    position: relative;
    display: flex;
    font-size: 12px;
  }
}
@media (max-width: 1023.98px) and (min-width: 768px) {
  .wrapper-header .header-content-nav__choose, .wrapper-header .header-content-nav__menu {
    font-size: 14px;
  }
}
@media (max-width: 1023.98px) {
  .wrapper-header .header-content-nav__choose--item, .wrapper-header .header-content-nav__menu--item {
    margin: 0;
    padding: 0.5em 0;
  }
}
@media (max-width: 1023.98px) {
  .wrapper-header .header-content-nav__choose {
    border-bottom: 1px solid #F2F2F230;
    margin: 0;
  }
}
@media (max-width: 1023.98px) {
  .wrapper-header .header-content-nav__menu--item {
    font-style: normal;
    font-weight: 300;
    line-height: 20px;
    letter-spacing: 0em;
    font-size: 18px;
  }
  .wrapper-header .header-content-nav__menu--item a {
    text-decoration: none;
  }
}
@media (max-width: 1023.98px) {
  .wrapper-header .header-content-nav__subchoose,
.wrapper-header .header-content-nav .sub-menu {
    display: none;
    top: 100%;
    margin: 0;
    padding: 10px 0;
  }
  .wrapper-header .header-content-nav__subchoose--item,
.wrapper-header .header-content-nav__subchoose .header-content-nav__menu--item,
.wrapper-header .header-content-nav .sub-menu--item,
.wrapper-header .header-content-nav .sub-menu .header-content-nav__menu--item {
    margin: 0;
    padding: 0;
    font-size: inherit;
    cursor: pointer;
    font-style: normal;
    font-weight: 300;
    letter-spacing: 0em;
  }
  .wrapper-header .header-content-nav__subchoose--item a,
.wrapper-header .header-content-nav__subchoose .header-content-nav__menu--item a,
.wrapper-header .header-content-nav .sub-menu--item a,
.wrapper-header .header-content-nav .sub-menu .header-content-nav__menu--item a {
    transition: all 0.25s;
    display: block;
    padding: 9px 18px;
    text-decoration: none;
  }
  .wrapper-header .header-content-nav__subchoose--item a:hover,
.wrapper-header .header-content-nav__subchoose .header-content-nav__menu--item a:hover,
.wrapper-header .header-content-nav .sub-menu--item a:hover,
.wrapper-header .header-content-nav .sub-menu .header-content-nav__menu--item a:hover {
    background: #F2F2F230;
  }
}
@media (max-width: 1023.98px) {
  .wrapper-header .header-content-nav__btns {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
  }
  .wrapper-header .header-content-nav__btns--btn {
    text-decoration: none;
    font-family: Montserrat, sans-serif;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0em;
    border-radius: 4px;
    line-height: 15px;
    text-align: center;
    margin: 0;
    font-size: 16px;
    padding: 13px 26px;
  }
  .wrapper-header .header-content-nav__btns--btn:nth-of-type(odd) {
    background-color: #F2F2F230;
  }
  .wrapper-header .header-content-nav__btns--btn:nth-of-type(even) {
    border: 1px solid #F2F2F230;
  }
  .wrapper-header .header-content-nav__btns--btn:last-of-type {
    margin: 20px 0;
  }
  .wrapper-header .header-content-nav__btns--btn:first-of-type {
    visibility: visible;
    position: absolute;
    top: -44px;
    border-radius: 0;
    right: 45px;
    height: 22px;
    font-size: 12px;
    line-height: 22px;
    margin: 0;
    padding: 0 8px;
    z-index: 11;
  }
  .wrapper-header .header-content-nav__btns--btn.inverse {
    background: #fff;
    color: #009A9A;
  }
}
@media (max-width: 1023.98px) {
  .wrapper-header .header-content .menu-colapse {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    justify-content: center;
    width: 24px;
    height: 22px;
    background-color: #F2F2F230;
    right: 10px;
    top: 22px;
    cursor: pointer;
    text-align: center;
    margin-left: 28px;
    margin-right: 10px;
  }
  .wrapper-header .header-content .menu-colapse span {
    width: 16px;
    display: block;
    height: 2px;
    background-color: #fff;
    transition: all 0.25s;
    margin: 0;
    padding: 0;
  }
  .wrapper-header .header-content .menu-colapse span:nth-of-type(2) {
    margin: 3px 0;
  }
  .wrapper-header .header-content .menu-colapse.toggled span:nth-of-type(1) {
    transform: rotate(45deg) translate(3px, 3px);
  }
  .wrapper-header .header-content .menu-colapse.toggled span:nth-of-type(2) {
    opacity: 0;
  }
  .wrapper-header .header-content .menu-colapse.toggled span:nth-of-type(3) {
    transform: rotate(-45deg) translate(4px, -4px);
  }
}
@media (min-width: 1024px) {
  .wrapper-header .header-content {
    display: flex;
    max-width: 1366px;
    width: 100%;
    margin: 0 auto;
    padding: 0;
    justify-content: space-between;
    align-items: center;
  }
  .wrapper-header .header-content__brand {
    margin: 0;
    padding: 10px;
  }
}
@media (min-width: 1024px) and (min-width: 1300px) {
  .wrapper-header .header-content__brand {
    padding: 20px;
  }
}
@media (min-width: 1024px) {
  .wrapper-header .header-content__brand--logo {
    max-width: 150px;
  }
}
@media (min-width: 1024px) {
  .wrapper-header .header-content-nav {
    display: flex;
    max-width: 100%;
    width: 100%;
    margin: 0;
    padding: 10px;
  }
}
@media (min-width: 1024px) and (min-width: 1300px) {
  .wrapper-header .header-content-nav {
    padding: 10px 20px;
  }
}
@media (min-width: 1024px) {
  .wrapper-header .header-content-nav.header-green, .wrapper-header .header-content-nav.header-blue {
    background: transparent;
  }
}
@media (min-width: 1024px) {
  .wrapper-header .header-content-nav__choose {
    background: #F2F2F230;
    border-radius: 4px;
    padding: 0;
    margin: 0;
  }
  .wrapper-header .header-content-nav__choose--item {
    display: flex;
  }
  .wrapper-header .header-content-nav__choose--item i {
    margin-left: 5px;
  }
}
@media (min-width: 1024px) and (min-width: 1300px) {
  .wrapper-header .header-content-nav__choose {
    padding: 0;
    margin: 0;
  }
}
@media (min-width: 1024px) {
  .wrapper-header .header-content-nav__choose, .wrapper-header .header-content-nav__menu, .wrapper-header .header-content-nav__subchoose,
.wrapper-header .header-content-nav .sub-menu {
    list-style: none;
  }
  .wrapper-header .header-content-nav__choose--item, .wrapper-header .header-content-nav__menu--item, .wrapper-header .header-content-nav__subchoose--item,
.wrapper-header .header-content-nav .sub-menu--item {
    cursor: pointer;
    font-style: normal;
    font-weight: 300;
    line-height: 17px;
    letter-spacing: 0em;
    font-size: 12px;
  }
}
@media (min-width: 1024px) and (min-width: 1300px) {
  .wrapper-header .header-content-nav__choose--item, .wrapper-header .header-content-nav__menu--item, .wrapper-header .header-content-nav__subchoose--item,
.wrapper-header .header-content-nav .sub-menu--item {
    font-size: 14px;
  }
}
@media (min-width: 1024px) {
  .wrapper-header .header-content-nav__choose--item a, .wrapper-header .header-content-nav__menu--item a, .wrapper-header .header-content-nav__subchoose--item a,
.wrapper-header .header-content-nav .sub-menu--item a {
    text-decoration: none;
  }
}
@media (min-width: 1024px) {
  .wrapper-header .header-content-nav__menu, .wrapper-header .header-content-nav__subchoose,
.wrapper-header .header-content-nav .sub-menu {
    margin: 0;
    padding: 0;
  }
}
@media (min-width: 1024px) {
  .wrapper-header .header-content-nav__choose, .wrapper-header .header-content-nav__menu {
    position: relative;
    display: flex;
  }
  .wrapper-header .header-content-nav__choose--item, .wrapper-header .header-content-nav__menu--item {
    margin: 0;
    padding: 1em 15px;
  }
}
@media (min-width: 1024px) and (min-width: 1300px) {
  .wrapper-header .header-content-nav__choose--item, .wrapper-header .header-content-nav__menu--item {
    padding: 1em 19px;
  }
}
@media (min-width: 1024px) {
  .wrapper-header .header-content-nav__subchoose {
    left: -22px;
    margin: 25px 0 0;
    min-width: 100%;
  }
}
@media (min-width: 1024px) and (min-width: 1300px) {
  .wrapper-header .header-content-nav__subchoose {
    left: 0;
    margin: 24px 0 0;
  }
}
@media (min-width: 1024px) {
  .wrapper-header .header-content-nav .sub-menu {
    margin: 25px 0 0 -22px;
  }
}
@media (min-width: 1024px) and (min-width: 1300px) {
  .wrapper-header .header-content-nav .sub-menu {
    margin: 24px 0 0 -22px;
  }
}
@media (min-width: 1024px) {
  .wrapper-header .header-content-nav__subchoose,
.wrapper-header .header-content-nav .sub-menu {
    display: none;
    position: absolute;
    top: 100%;
    padding: 10px 0;
  }
  .wrapper-header .header-content-nav__subchoose:before,
.wrapper-header .header-content-nav .sub-menu:before {
    top: 0;
    left: 0;
    right: 0;
    height: 1px;
    content: "";
    max-width: 100%;
    padding: 0;
    position: absolute;
    background-color: #fff;
    margin: 0 22px;
  }
  .wrapper-header .header-content-nav__subchoose--item,
.wrapper-header .header-content-nav__subchoose .header-content-nav__menu--item,
.wrapper-header .header-content-nav .sub-menu--item,
.wrapper-header .header-content-nav .sub-menu .header-content-nav__menu--item {
    margin: 0;
    padding: 0;
  }
  .wrapper-header .header-content-nav__subchoose--item a,
.wrapper-header .header-content-nav__subchoose .header-content-nav__menu--item a,
.wrapper-header .header-content-nav .sub-menu--item a,
.wrapper-header .header-content-nav .sub-menu .header-content-nav__menu--item a {
    transition: all 0.25s;
    display: block;
    padding: 9px 18px;
  }
}
@media (min-width: 1024px) and (min-width: 1300px) {
  .wrapper-header .header-content-nav__subchoose--item a,
.wrapper-header .header-content-nav__subchoose .header-content-nav__menu--item a,
.wrapper-header .header-content-nav .sub-menu--item a,
.wrapper-header .header-content-nav .sub-menu .header-content-nav__menu--item a {
    padding: 13px 22px;
  }
}
@media (min-width: 1024px) {
  .wrapper-header .header-content-nav__subchoose--item a:hover,
.wrapper-header .header-content-nav__subchoose .header-content-nav__menu--item a:hover,
.wrapper-header .header-content-nav .sub-menu--item a:hover,
.wrapper-header .header-content-nav .sub-menu .header-content-nav__menu--item a:hover {
    background: #F2F2F230;
  }
}
@media (min-width: 1024px) {
  .wrapper-header .header-content-nav__btns {
    margin: 0 0 0 auto;
    padding: 0;
    display: flex;
  }
  .wrapper-header .header-content-nav__btns--btn {
    display: inline-flex;
    align-items: center;
    border-radius: 4px;
    text-decoration: none;
    font-family: Montserrat, sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: center;
    margin: 0 5px;
    font-size: 10px;
    padding: 9px 18px;
    transition: 333ms;
  }
  .wrapper-header .header-content-nav__btns--btn:hover {
    background: #ffffff48;
  }
  .wrapper-header .header-content-nav__btns--btn.inverse {
    background: #fff;
    color: #009A9A;
  }
  .wrapper-header .header-content-nav__btns--btn.inverse:hover {
    background: #ffffffe3;
  }
}
@media (min-width: 1024px) and (min-width: 1300px) {
  .wrapper-header .header-content-nav__btns--btn {
    margin: 0 5px;
    font-size: 12px;
    padding: 13px 40px;
  }
}
@media (min-width: 1024px) {
  .wrapper-header .header-content-nav__btns--btn:nth-of-type(odd) {
    background-color: #F2F2F230;
  }
  .wrapper-header .header-content-nav__btns--btn:nth-of-type(odd):hover {
    background-color: #ffffff48;
  }
}
@media (min-width: 1024px) {
  .wrapper-header .header-content-nav__btns--btn:nth-of-type(even) {
    border: 1px solid #F2F2F230;
  }
}
@media (min-width: 1024px) {
  .wrapper-header .menu-colapse {
    display: none;
  }
}

.wrapper-footer {
  display: flex;
  max-width: 100%;
  width: 100%;
  font-family: Montserrat, sans-serif;
  margin: 0;
  padding: 30px 10px;
  justify-content: center;
  align-items: center;
  align-content: center;
  transition: all 0.25s;
  background-color: #131313;
}
.wrapper-footer * {
  color: #fff;
}
@media (max-width: 767.98px) {
  .wrapper-footer {
    padding: 40px;
  }
}
.wrapper-footer .footer-content {
  display: flex;
  max-width: 1000px;
  width: 100%;
  margin: 0 auto;
  padding: 0;
  justify-content: space-between;
  align-items: center;
}
.wrapper-footer .footer-content-nav {
  display: flex;
  max-width: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  justify-content: space-between;
}
@media (max-width: 767.98px) {
  .wrapper-footer .footer-content-nav {
    flex-direction: column;
  }
}
.wrapper-footer .footer-content-nav__choose {
  display: flex;
  max-width: 20%;
  width: 100%;
  margin: 30px 0;
  padding: 0;
}
@media (max-width: 767.98px) {
  .wrapper-footer .footer-content-nav__choose {
    display: flex;
    max-width: 100%;
    width: 100%;
    margin: 0 !important;
  }
  .wrapper-footer .footer-content-nav__choose--item {
    width: 100%;
  }
}
.wrapper-footer .footer-content-nav__menu {
  display: flex;
  max-width: 80%;
  width: 100%;
  justify-content: space-between;
}
@media (max-width: 767.98px) {
  .wrapper-footer .footer-content-nav__menu {
    flex-direction: column;
    display: flex;
    max-width: 100%;
    width: 100%;
  }
}
.wrapper-footer .footer-content-nav__menu, .wrapper-footer .footer-content-nav__choose {
  margin: 30px 0;
  padding: 0;
}
.wrapper-footer .footer-content-nav__menu--item, .wrapper-footer .footer-content-nav__choose--item {
  font-size: 14px;
  line-height: 17px;
}
@media (max-width: 767.98px) {
  .wrapper-footer .footer-content-nav__menu--item, .wrapper-footer .footer-content-nav__choose--item {
    font-size: 18px;
    line-height: 22px;
  }
}
.wrapper-footer .footer-content-nav__subchoose,
.wrapper-footer .footer-content-nav .sub-menu {
  margin: 40px 0 0;
  padding: 0;
}
@media (max-width: 767.98px) {
  .wrapper-footer .footer-content-nav__subchoose,
.wrapper-footer .footer-content-nav .sub-menu {
    margin: 18px 0 60px;
  }
}
.wrapper-footer .footer-content-nav__subchoose--item,
.wrapper-footer .footer-content-nav__subchoose .footer-content-nav__menu--item,
.wrapper-footer .footer-content-nav .sub-menu--item,
.wrapper-footer .footer-content-nav .sub-menu .footer-content-nav__menu--item {
  font-size: 12px;
  line-height: 15px;
  margin: 0;
  padding: 8px 0;
}
@media (max-width: 767.98px) {
  .wrapper-footer .footer-content-nav__subchoose--item,
.wrapper-footer .footer-content-nav__subchoose .footer-content-nav__menu--item,
.wrapper-footer .footer-content-nav .sub-menu--item,
.wrapper-footer .footer-content-nav .sub-menu .footer-content-nav__menu--item {
    line-height: 15px;
  }
}
.wrapper-footer .footer-content-nav__subchoose--item {
  padding: 8px !important;
  border-radius: 4px;
}
@media (max-width: 767.98px) {
  .wrapper-footer .footer-content-nav__subchoose--item {
    width: 100%;
    transition: all 0.25s;
    padding: 18px !important;
    border: 1px solid #DFDFDF30;
    text-align: center;
  }
  .wrapper-footer .footer-content-nav__subchoose--item:first-of-type {
    margin-bottom: 3px;
  }
}
.wrapper-footer .footer-content-nav__subchoose--item:hover {
  background-color: #F2F2F230;
}
.wrapper-footer .footer-content-nav__menu, .wrapper-footer .footer-content-nav__choose, .wrapper-footer .footer-content-nav__subchoose,
.wrapper-footer .footer-content-nav .sub-menu {
  list-style: none;
  flex-direction: column;
  justify-content: flex-start;
  gap: 28px;
}
.wrapper-footer .footer-content-nav__menu--item, .wrapper-footer .footer-content-nav__choose--item, .wrapper-footer .footer-content-nav__subchoose--item,
.wrapper-footer .footer-content-nav .sub-menu--item {
  font-style: normal;
  font-weight: 300;
  letter-spacing: 0em;
  padding: 0;
}
.wrapper-footer .footer-content-nav__menu--item a, .wrapper-footer .footer-content-nav__choose--item a, .wrapper-footer .footer-content-nav__subchoose--item a,
.wrapper-footer .footer-content-nav .sub-menu--item a {
  text-decoration: none;
}
@media (min-width: 768px) {
  .wrapper-footer .footer-content-nav__menu--item, .wrapper-footer .footer-content-nav__choose--item, .wrapper-footer .footer-content-nav__subchoose--item,
.wrapper-footer .footer-content-nav .sub-menu--item {
    padding: 0 10px;
  }
}

.wrapper-hero {
  display: block;
  max-width: 900px;
  width: 100%;
  margin: 0 auto !important;
  padding: 0;
}
.wrapper-hero .hero__main {
  display: flex;
  max-width: 100%;
  width: 100%;
  margin: 0 auto !important;
  padding: 0;
  justify-content: center;
  flex-wrap: wrap;
  position: relative;
}
.wrapper-hero .hero__main:nth-of-type(2) {
  max-width: 400px;
}
@media (max-width: 767.98px) {
  .wrapper-hero .hero__main:nth-of-type(2) {
    max-width: 330px;
  }
}
.wrapper-hero .hero__main--title {
  width: 100%;
  margin: 20px auto 10px;
  font-family: Nunito, sans-serif;
  font-size: 40px;
  font-style: normal;
  font-weight: 300;
  line-height: 55px;
  letter-spacing: 0em;
  text-align: center;
  color: #000;
}
@media (max-width: 767.98px) {
  .wrapper-hero .hero__main--title {
    font-size: 28px;
    line-height: 1.3;
    margin-top: 0px;
  }
}
.wrapper-hero .hero__main--desc {
  max-width: 620px;
  justify-self: center;
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: center;
  color: #000;
}
@media (max-width: 767.98px) {
  .wrapper-hero .hero__main--desc {
    max-width: none;
    width: 100%;
    font-size: 14px;
    line-height: 20px;
  }
}
.wrapper-hero .hero__main--search {
  width: 100%;
  box-shadow: 0px 0px 30px 0px #00000026;
  font-family: Nunito, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: center;
  background: #FFFFFF;
  height: 55px;
  margin: 20px auto;
  max-width: 400px;
  border-radius: 70px;
  border: none;
  color: #000;
}
@media (max-width: 767.98px) {
  .wrapper-hero .hero__main--search {
    max-width: 330px;
  }
}
.wrapper-hero .hero__main--date {
  font-family: Montserrat, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  margin: 0;
  color: #000;
  justify-self: flex-end;
}
.wrapper-hero .hero__main--button {
  background: transparent;
  border: none;
  position: absolute;
  left: 87.67%;
  right: 7.42%;
  top: 30.24%;
  font-size: 20px;
  bottom: 32.47%;
  box-sizing: border-box;
  color: #0003;
  cursor: pointer;
}
.wrapper-hero .hero__img {
  max-width: 100%;
  height: 285px;
  margin: 0 0 15px;
  color: #000;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.wrapper-hero.hero-docs .hero__main {
  display: flex;
  max-width: 900px;
  width: 100%;
}
@media (min-width: 768px) {
  .wrapper-hero.hero-docs .hero__main {
    justify-content: flex-start;
  }
  .wrapper-hero.hero-docs .hero__main--title, .wrapper-hero.hero-docs .hero__main--desc {
    text-align: left;
  }
}
.wrapper-hero.hero-docs .hero__main--title {
  margin: 20px 0 5px;
}
.wrapper-hero.hero-docs .hero__main--desc {
  margin: 5px 0 20px;
}

.wrapper-content {
  display: block;
  max-width: 700px;
  width: 100%;
  margin: 30px auto 0;
  padding: 0;
}
.wrapper-content__main {
  display: block;
  max-width: 100%;
  width: 100%;
  margin: 0 auto;
  padding: 0;
}
.wrapper-content__main--xcrpt {
  margin: 0;
  padding: 0 0 1.2em;
  color: #000;
  font-family: Nunito, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 1.4;
  letter-spacing: 0em;
  text-align: left;
}
@media (max-width: 767.98px) {
  .wrapper-content__main--xcrpt {
    padding-bottom: 0;
  }
}
.wrapper-content__main--text {
  font-family: Nunito, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 1.4;
  letter-spacing: 0em;
  text-align: left;
  margin: 0;
  padding: 0 0 10px;
  color: #000;
}
.wrapper-content__main--text h2, .wrapper-content__main--text h3 {
  font-weight: 500;
  margin-top: 1em;
  margin-bottom: 0.6em;
}
.wrapper-content__main--text a {
  color: #009A9A;
  font-weight: bold;
}

.wrapper-popular {
  display: flex;
  max-width: 1040px;
  width: 100%;
  justify-content: center;
  margin: 0 auto !important;
  padding: 0;
  flex-wrap: wrap;
}
.wrapper-popular .popular__head {
  display: block;
  max-width: 100%;
  width: 100%;
  margin: 0 auto !important;
  padding: 0;
}
.wrapper-popular .popular__head--title {
  font-family: Nunito, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 300;
  line-height: 33px;
  letter-spacing: 0em;
  text-align: left;
  color: #000;
}
@media (max-width: 767.98px) {
  .wrapper-popular .popular__head--title {
    font-size: 18px;
    line-height: 25px;
    text-align: center;
  }
}
.wrapper-popular .popular-main {
  display: flex;
  max-width: 1040px;
  width: 100%;
  padding: 0;
  flex-wrap: wrap;
  margin: 0 -7px;
  width: auto;
}
@media (max-width: 767.98px) {
  .wrapper-popular .popular-main {
    flex-direction: column;
    align-items: center;
  }
}
@media (min-width: 768px) {
  .wrapper-popular .popular-main {
    justify-content: space-between;
  }
}
.wrapper-popular .popular-main__post:hover .popular-main__post--thumb {
  transform: scale(1.04);
}
.wrapper-popular .popular-main__post {
  display: flex;
  max-width: 33.3%;
  width: 100%;
  text-decoration: none;
  flex-direction: column;
  padding: 7px;
  margin: 0px;
}
@media (max-width: 767.98px) {
  .wrapper-popular .popular-main__post {
    max-width: none;
    width: 100%;
    margin-bottom: 30px;
  }
}
.wrapper-popular .popular-main__post:last-of-type {
  margin-right: auto;
}
.wrapper-popular .popular-main__post--thumb {
  max-width: none;
  padding-bottom: 50%;
  margin: 0 0 15px;
  color: #000;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  transition: 333ms ease-in-out;
}
.wrapper-popular .popular-main__post--date {
  font-family: Montserrat, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  margin: 0;
  color: #000;
}
.wrapper-popular .popular-main__post--title {
  font-family: Montserrat, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  margin: 0 0 20px;
  color: #000;
}
@media (max-width: 767.98px) {
  .wrapper-popular .popular-main__post--title {
    font-weight: 500;
  }
}
.wrapper-popular .popular-main__post--xcrpt {
  font-family: Montserrat, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  margin: 0;
  color: #000;
}
@media (max-width: 767.98px) {
  .wrapper-popular .popular-main__post--xcrpt {
    font-size: 14px;
    line-height: 20px;
  }
}
.wrapper-popular .popular-main__post--href {
  font-family: Montserrat, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #000;
  text-decoration: underline;
}

.wrapper-posts {
  display: flex;
  max-width: 100%;
  width: 100%;
  justify-content: center;
  margin: 0 auto !important;
  padding: 0;
  flex-wrap: wrap;
}
.wrapper-posts .posts__head {
  display: block;
  max-width: 1000px;
  width: 100%;
  margin: 0 auto !important;
  padding: 0;
}
.wrapper-posts .posts__head--title {
  margin: 20px 0;
  font-family: Nunito, sans-serif;
  font-size: 40px;
  font-style: normal;
  font-weight: 300;
  line-height: 55px;
  letter-spacing: 0em;
  text-align: left;
  color: #000;
}
@media (max-width: 767.98px) {
  .wrapper-posts .posts__head--title {
    font-size: 24px;
    line-height: 1.3;
    text-align: center;
  }
}
.wrapper-posts .posts-main {
  display: flex;
  max-width: 1000px;
  width: 100%;
  justify-content: center;
  margin: 15px auto !important;
  padding: 0;
  flex-wrap: wrap;
}
@media (max-width: 767.98px) {
  .wrapper-posts .posts-main {
    flex-direction: column;
    align-items: center;
  }
}
@media (min-width: 768px) {
  .wrapper-posts .posts-main {
    justify-content: space-between;
  }
}
.wrapper-posts .posts-main-post:hover .posts-main-post__head {
  transform: scale(1.02);
}
.wrapper-posts .posts-main-post {
  display: flex;
  max-width: 49%;
  width: 100%;
  flex-direction: column;
  margin: 0 0 60px;
  text-decoration: none;
}
@media (max-width: 767.98px) {
  .wrapper-posts .posts-main-post {
    width: 100%;
    max-width: none;
  }
}
.wrapper-posts .posts-main-post__head {
  height: 190px;
  padding-bottom: 40%;
  position: relative;
  margin: 0 0 15px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  transition: 333ms ease-in-out;
}
.wrapper-posts .posts-main-post__head--title {
  margin: 0;
  padding: 15px 30px;
  font-family: Nunito, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 300;
  line-height: 33px;
  letter-spacing: 0em;
  text-align: left;
  left: 0;
  bottom: 25px;
  position: absolute;
  background-color: #fff;
  color: #000;
}
@media (max-width: 767.98px) {
  .wrapper-posts .posts-main-post__head--title {
    font-size: 18px;
    line-height: 25px;
  }
}
.wrapper-posts .posts-main-post--xcrpt {
  margin: 15px 0 0;
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #000;
}
@media (max-width: 767.98px) {
  .wrapper-posts .posts-main-post--xcrpt {
    font-size: 12px;
    line-height: 22px;
  }
}
.wrapper-posts .posts-main-post--href {
  margin: 10px 0 0;
  font-family: Montserrat, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  text-decoration: underline;
  color: #000;
}

.wrapper-faq {
  display: flex;
  max-width: 100%;
  width: 100%;
  justify-content: flex-start;
  align-content: center;
  flex-direction: column;
  margin: 0 auto 100px !important;
  padding: 30px;
  min-height: 700px;
  flex-wrap: wrap;
}
.wrapper-faq .faq__head {
  display: block;
  max-width: 1040px;
  width: 100%;
  margin: 35px auto 25px !important;
  padding: 30px 0;
}
.wrapper-faq .faq__head--title {
  font-family: Nunito, sans-serif;
  font-size: 40px;
  font-style: normal;
  font-weight: 300;
  line-height: 55px;
  letter-spacing: 0em;
  text-align: center;
  color: #fff;
}
@media (max-width: 767.98px) {
  .wrapper-faq .faq__head--title {
    font-size: 24px;
    font-weight: 400;
    line-height: 33px;
  }
}
.wrapper-faq .faq__head--subtitle {
  font-family: Nunito, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: center;
}
.wrapper-faq .faq__art {
  display: flex;
  max-width: 930px;
  width: 100%;
  text-decoration: none;
  border-bottom: 1px solid #ffffff5b;
  flex-direction: column;
  margin: 0 auto;
}
@media (max-width: 767.98px) {
  .wrapper-faq .faq__art {
    display: flex;
    max-width: 95%;
    width: 100%;
  }
}
.wrapper-faq .faq__art--title {
  display: flex;
  max-width: 100%;
  width: 100%;
  justify-content: space-between;
  color: #fff;
  font-family: Nunito, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  letter-spacing: 0em;
  text-align: left;
  margin: 0;
  cursor: pointer;
  padding: 15px 0;
}
@media (max-width: 767.98px) {
  .wrapper-faq .faq__art--title {
    font-size: 12px;
  }
}
.wrapper-faq .faq__art--title span {
  width: 40px;
  margin: 0 40px 0 0;
  font-family: Montserrat, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0em;
  text-align: center;
  transition: all 0.25s;
  -o-transition: all 0.25s;
  -ms-transition: all 0.25s;
  -moz-transition: all 0.25s;
  -webkit-transition: all 0.25s;
}
@media (max-width: 767.98px) {
  .wrapper-faq .faq__art--title span {
    margin: 0;
    font-size: 12px;
  }
}
.wrapper-faq .faq__art--desc {
  display: block;
  margin: 0;
  padding: 20px 28px 35px;
  font-family: Nunito, sans-serif;
  font-size: 14px;
  line-height: 1.5em;
  font-style: normal;
  font-weight: 300;
  letter-spacing: 0em;
  text-align: left;
  color: #000;
  display: none;
  width: 100%;
  background-color: #fff;
  border-radius: 5px;
}
.wrapper-faq .faq__art--desc .faq__art--desc-wrap {
  min-height: none;
  column-count: 2;
  column-gap: 20px;
}
@media (max-width: 767.98px) {
  .wrapper-faq .faq__art--desc .faq__art--desc-wrap {
    column-count: 1;
  }
}
.wrapper-faq .faq__art--desc p {
  color: #000;
  margin: 0 7px;
  padding: 5px 0;
  break-inside: avoid-column;
}
.wrapper-faq .faq__art--desc p:after {
  content: "";
  display: block;
  border-bottom: 1px solid #000;
  break-after: avoid-column;
}

html .wrapper-main .wrapper-steps,
.wrapper-steps {
  display: flex;
  max-width: 1040px;
  width: 100%;
  justify-content: center;
  margin: 0 auto !important;
  flex-wrap: wrap;
}
html .wrapper-main .wrapper-steps .steps__head,
.wrapper-steps .steps__head {
  display: block;
  max-width: 100%;
  width: 100%;
  margin: 0 auto !important;
  padding: 40px 0 0;
}
@media (max-width: 767.98px) {
  html .wrapper-main .wrapper-steps .steps__head,
.wrapper-steps .steps__head {
    padding-top: 10px;
  }
}
html .wrapper-main .wrapper-steps .steps__head--title,
.wrapper-steps .steps__head--title {
  font-family: Nunito, sans-serif;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 1.3;
  letter-spacing: 0em;
  text-align: center;
  margin-top: 1.3em;
}
@media (max-width: 767.98px) {
  html .wrapper-main .wrapper-steps .steps__head--title,
.wrapper-steps .steps__head--title {
    font-size: 24px;
    font-weight: 300;
    margin: 0px 0 25px;
  }
}
html .wrapper-main .wrapper-steps .steps__head--desc,
.wrapper-steps .steps__head--desc {
  font-family: Montserrat, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: center;
  margin: 0 0 40px;
}
@media (max-width: 767.98px) {
  html .wrapper-main .wrapper-steps .steps__head--desc,
.wrapper-steps .steps__head--desc {
    font-size: 14px;
    line-height: 20px;
  }
}
html .wrapper-main .wrapper-steps .steps-main,
.wrapper-steps .steps-main {
  display: flex;
  max-width: 1040px;
  width: 100%;
  margin: 0 auto;
  padding: 0 0 20px;
  flex-wrap: wrap;
}
@media (max-width: 767.98px) {
  html .wrapper-main .wrapper-steps .steps-main,
.wrapper-steps .steps-main {
    flex-direction: column;
    align-items: center;
    margin-bottom: 0;
    padding-bottom: 0;
  }
}
@media (min-width: 768px) {
  html .wrapper-main .wrapper-steps .steps-main,
.wrapper-steps .steps-main {
    justify-content: space-between;
  }
}
html .wrapper-main .wrapper-steps .steps-main__post,
.wrapper-steps .steps-main__post {
  display: flex;
  max-width: 32.5%;
  width: 100%;
  margin: 0 0 20px;
}
@media (max-width: 767.98px) {
  html .wrapper-main .wrapper-steps .steps-main__post,
.wrapper-steps .steps-main__post {
    display: flex;
    max-width: 380px;
    width: 100%;
    border-bottom: 1px solid #ffffff6e;
    align-items: center;
  }
}
@media (min-width: 768px) {
  html .wrapper-main .wrapper-steps .steps-main__post,
.wrapper-steps .steps-main__post {
    flex-direction: column;
  }
}
html .wrapper-main .wrapper-steps .steps-main__post--ico,
.wrapper-steps .steps-main__post--ico {
  align-items: center;
  height: 75px;
  margin: 0 0 15px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 0 0 15px;
}
@media (max-width: 767.98px) {
  html .wrapper-main .wrapper-steps .steps-main__post--ico,
.wrapper-steps .steps-main__post--ico {
    display: flex;
    max-width: 85px;
    width: 100%;
    padding-bottom: 0;
    margin-bottom: 0;
  }
}
@media (min-width: 768px) {
  html .wrapper-main .wrapper-steps .steps-main__post--ico,
.wrapper-steps .steps-main__post--ico {
    display: flex;
    max-width: 100%;
    width: 100%;
    border-bottom: 1px solid #ffffff6e;
  }
}
html .wrapper-main .wrapper-steps .steps-main__post--ico img,
.wrapper-steps .steps-main__post--ico img {
  max-width: 100%;
}
html .wrapper-main .wrapper-steps .steps-main__post--title,
.wrapper-steps .steps-main__post--title {
  font-family: Montserrat, sans-serif;
  font-size: 16px;
  font-style: normal;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}
@media (max-width: 767.98px) {
  html .wrapper-main .wrapper-steps .steps-main__post--title,
.wrapper-steps .steps-main__post--title {
    font-size: 14px;
  }
}
html .wrapper-main .wrapper-steps .steps-main__post--desc,
.wrapper-steps .steps-main__post--desc {
  font-family: Montserrat, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  margin: 0 0 20px;
}
@media (max-width: 767.98px) {
  html .wrapper-main .wrapper-steps .steps-main__post--desc,
.wrapper-steps .steps-main__post--desc {
    font-size: 14px;
    margin-bottom: 0;
  }
}
@media (min-width: 768px) {
  html .wrapper-main .wrapper-steps .steps-main__post--desc,
.wrapper-steps .steps-main__post--desc {
    max-width: 80%;
  }
}
html .wrapper-main .wrapper-steps .steps-main__post--desc strong,
.wrapper-steps .steps-main__post--desc strong {
  display: block;
  margin: 0 0 5px;
  font-weight: 700;
}
@media (max-width: 767.98px) {
  html .wrapper-main .wrapper-steps .steps-main__post--desc strong,
.wrapper-steps .steps-main__post--desc strong {
    font-size: 14px;
  }
}

.wrapper-form {
  display: flex;
  max-width: 100%;
  width: 100%;
  justify-content: flex-start;
  align-content: center;
  flex-direction: column;
  margin: 0 auto !important;
  padding: 30px !important;
  flex-wrap: wrap;
}
.wrapper-form .form__head {
  display: block;
  max-width: 1040px;
  width: 100%;
  margin: 0 auto !important;
  padding: 30px 0;
}
.wrapper-form .form__head--title {
  max-width: 865px;
  font-family: Montserrat, sans-serif;
  font-size: 36px;
  font-style: normal;
  font-weight: 300;
  line-height: 44px;
  letter-spacing: 0em;
  text-align: center;
  color: #000;
  margin: 0 auto 10px;
  padding: 0;
}
@media (max-width: 767.98px) {
  .wrapper-form .form__head--title {
    font-size: 24px;
    line-height: 1.4em;
  }
}
.wrapper-form .form__head--desc {
  font-family: Montserrat, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: center;
  margin: 0;
  padding: 0;
  color: #000;
}
@media (max-width: 767.98px) {
  .wrapper-form .form__head--desc {
    font-size: 14px;
    line-height: 1.5em;
  }
}
.wrapper-form .form__main {
  display: flex;
  max-width: 400px;
  width: 100%;
  margin: 0 auto !important;
  padding: 0;
  flex-direction: column;
}
.wrapper-form .form__main--input {
  width: 100%;
  box-shadow: 0px 0px 30px 0px #00000026;
  font-family: Nunito, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: center;
  background: #FFFFFF;
  height: 55px;
  margin: 10px auto 0;
  max-width: 400px;
  border-radius: 70px;
  color: #000 !important;
  border: 3px solid #fff;
}
.wrapper-form .form__main--input.error {
  border-color: red;
}
@media (max-width: 767.98px) {
  .wrapper-form .form__main--input {
    max-width: 330px;
  }
}
.wrapper-form .form__main--input:focus {
  border: 3px solid #019A9A !important;
}
.wrapper-form .form__main--check {
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: #000 !important;
  margin: 10px 0;
  text-decoration: underline;
  cursor: pointer;
}
.wrapper-form .form__main .main__accept {
  position: relative;
  display: block;
  max-width: 100%;
  width: 100%;
  margin: 5px 0;
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: #000;
  padding: 0 0 0 35px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
@media (max-width: 767.98px) {
  .wrapper-form .form__main .main__accept {
    font-size: 12px;
    line-height: 15px;
  }
}
.wrapper-form .form__main .main__accept a, .wrapper-form .form__main .main__accept span.label--more {
  cursor: pointer;
  font-weight: 500;
  color: #000;
}
.wrapper-form .form__main .main__accept--checkbox {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
  margin: 0;
}
.wrapper-form .form__main .main__accept input:checked ~ span.checkmark::after {
  display: block;
}
.wrapper-form .form__main .main__accept .checkmark {
  background-color: #FFFFFF;
  position: absolute;
  border: 1px solid #000;
  height: 24px;
  width: 24px;
  left: 0;
  top: 5px;
  border-radius: 16px;
}
.wrapper-form .form__main .main__accept .checkmark::after {
  content: "";
  display: none;
  position: absolute;
  width: 60%;
  height: 60%;
  margin: auto;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: url(../img/check.svg) no-repeat center/cover;
}
.wrapper-form .form__main .main__accept input.error ~ span.checkmark {
  border-color: red;
}
.wrapper-form .form__main .main__accept .more--text {
  display: none;
}
.wrapper-form .form__main--submit {
  background: radial-gradient(91.66% 306.32% at 0% 16.18%, #01CC9D 0%, #019A9A 100%);
  border: 2px solid #FFFFFF;
  box-sizing: border-box;
  border-radius: 70px;
  font-family: Nunito, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 800;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: center;
  color: #FFFFFF;
  height: 55px;
  width: 100%;
  margin: 20px 0;
  cursor: pointer;
  transition: 333ms;
}
.wrapper-form .form__main--submit:hover {
  filter: brightness(1.1);
}

.wrapper-docs {
  display: flex;
  max-width: 900px;
  width: 100%;
  justify-content: space-between;
  align-content: center;
  flex-wrap: wrap;
  margin: 0 auto 50px !important;
  padding: 0;
}
@media (max-width: 767.98px) {
  .wrapper-docs {
    justify-content: center;
  }
}
.wrapper-docs .docs__head {
  display: block;
  max-width: 900px;
  width: 100%;
  margin: 0 auto !important;
  padding: 0;
}
.wrapper-docs .docs__head--title {
  font-family: Nunito, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 300;
  line-height: 33px;
  letter-spacing: 0em;
  text-align: left;
  color: #000;
  margin: 20px 0 10px;
}
@media (max-width: 767.98px) {
  .wrapper-docs .docs__head--title {
    text-align: center;
    font-size: 20px;
    line-height: 28px;
  }
}
.wrapper-docs .docs__art {
  display: flex;
  max-width: 100%;
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-between;
  color: #000;
  margin: 0;
}
@media (max-width: 767.98px) {
  .wrapper-docs .docs__art {
    display: flex;
    max-width: 445px;
    width: 100%;
  }
}
.wrapper-docs .docs__art--title {
  display: flex;
  max-width: 49%;
  width: 100%;
  justify-content: space-between;
  color: #000;
  font-family: Nunito, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  letter-spacing: 0em;
  text-align: left;
  margin: 0;
  padding: 12px 0;
  border-bottom: 1px solid #999;
  text-decoration: none;
  transition: 333ms;
}
.wrapper-docs .docs__art--title:hover {
  border-bottom: 1px solid #009A9A;
}
@media (max-width: 767.98px) {
  .wrapper-docs .docs__art--title {
    max-width: 100%;
    width: 100%;
  }
}
.wrapper-docs .docs__art--title a {
  width: 40px;
  margin: 0 10px 0 0;
  font-family: Montserrat, sans-serif;
  font-size: 0;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0em;
  text-align: center;
  text-decoration: none;
  color: #000;
  transition: all 0.25s;
  -o-transition: all 0.25s;
  -ms-transition: all 0.25s;
  -moz-transition: all 0.25s;
  -webkit-transition: all 0.25s;
}
.wrapper-docs .docs__art--title a:hover {
  transform: scale(1.1);
}
@media (max-width: 767.98px) {
  .wrapper-docs .docs__art--title a {
    margin: 0;
  }
}
.wrapper-docs .docs__art--title a img {
  height: 100%;
  max-width: 100%;
}

.wrapper-top {
  display: flex;
  max-width: 100%;
  width: 100%;
  align-items: center;
  margin: 0 0 20px;
  padding: 0 !important;
  border-bottom: 1px solid #BDBDBD;
}
@media (min-width: 768px) {
  .wrapper-top {
    min-height: 535px;
  }
}
@media (max-width: 767.98px) {
  .wrapper-top__header {
    display: flex;
    max-width: 100%;
    width: 100%;
    flex-direction: column;
    align-items: center;
  }
}
@media (min-width: 768px) {
  .wrapper-top__header {
    margin-left: 5%;
    display: block;
    max-width: 37%;
    width: 100%;
  }
  .wrapper-top__header.longer-than-before {
    display: block;
    max-width: 50%;
    width: 100%;
  }
  .wrapper-top__header .title--img {
    display: none;
  }
}
.wrapper-top__header--title {
  width: 100%;
  margin: 20px auto;
  font-family: Nunito, sans-serif;
  font-size: 40px;
  font-style: normal;
  font-weight: 300;
  line-height: 55px;
  letter-spacing: 0em;
  color: #000;
}
@media (max-width: 767.98px) {
  .wrapper-top__header--title {
    height: 46vw;
    position: relative;
    margin: 0;
    width: 100%;
    padding: 35px;
    font-size: 20px;
    line-height: 27px;
    color: #fff;
  }
  .wrapper-top__header--title:after, .wrapper-top__header--title:before {
    content: "";
    position: absolute;
    height: 100%;
  }
  .wrapper-top__header--title:before {
    left: 0;
    right: 0;
    top: 0;
    width: 100%;
    z-index: -1;
    background: radial-gradient(54.26% 161.68% at 0% 16.18%, #00CF9E 0%, #019A9A 100%) no-repeat center/cover;
  }
  .wrapper-top__header--title:after {
    right: 0;
    bottom: 0;
    width: 50%;
    background: url(../img/about.png) no-repeat right bottom/cover;
  }
}
.wrapper-top__header--desc {
  max-width: 455px;
  font-family: Nunito, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 26px;
  letter-spacing: 0em;
  color: #000;
}
@media (max-width: 767.98px) {
  .wrapper-top__header--desc {
    padding: 35px 0;
    margin: 0 20px;
    max-width: 325px;
    font-size: 12px;
    line-height: 16px;
  }
}
.wrapper-top__header .form__main {
  display: flex;
  max-width: 425px;
  width: 100%;
  margin: 0 !important;
  padding: 0;
  flex-direction: column;
  position: relative;
}
@media (max-width: 767.98px) {
  .wrapper-top__header .form__main {
    margin: 0 0 40px !important;
    display: flex;
    max-width: 330px;
    width: 100%;
  }
}
.wrapper-top__header .form__main input[type=password],
.wrapper-top__header .form__main .main__accept,
.wrapper-top__header .form__main .form__main--check {
  display: none;
}
.wrapper-top__header .form__main input[type=email] {
  border: none;
}
.wrapper-top__header .form__main input[type=submit] {
  position: absolute;
  top: -10px;
  border: none;
}
@media (max-width: 767.98px) {
  .wrapper-top__header .form__main input[type=submit] {
    max-width: 150px;
    font-size: 12px;
    left: 56%;
  }
}
@media (min-width: 768px) {
  .wrapper-top__header .form__main input[type=submit] {
    max-width: 55%;
  }
}
@media (min-width: 768px) and (max-width: 1599.98px) {
  .wrapper-top__header .form__main input[type=submit] {
    left: 20vw;
  }
}
@media (min-width: 1600px) {
  .wrapper-top__header .form__main input[type=submit] {
    left: 85%;
  }
}
.wrapper-top__header .form__main--input {
  width: 100%;
  box-shadow: 0px 0px 30px 0px #00000026;
  font-family: Nunito, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
  background: #FFFFFF;
  height: 55px;
  padding: 0 20px;
  margin: 10px auto 0;
  border-radius: 70px;
  color: #000;
  border: 3px solid #fff;
}
@media (max-width: 767.98px) {
  .wrapper-top__header .form__main--input {
    max-width: 330px;
  }
}
.wrapper-top__header .form__main--input:focus {
  border: 3px solid #019A9A !important;
}
.wrapper-top__header .form__main--check {
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: #000;
  margin: 10px 0;
  text-decoration: underline;
  cursor: pointer;
}
.wrapper-top__header .form__main .main__accept {
  max-width: 350px;
  position: relative;
  width: 100%;
  margin: 5px 0;
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: #000;
  padding: 0 0 0 35px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
@media (max-width: 767.98px) {
  .wrapper-top__header .form__main .main__accept {
    font-size: 12px;
    line-height: 15px;
  }
}
.wrapper-top__header .form__main .main__accept a, .wrapper-top__header .form__main .main__accept span.label--more {
  cursor: pointer;
  font-weight: 500;
  color: #000;
}
.wrapper-top__header .form__main .main__accept--checkbox {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
  margin: 0;
}
.wrapper-top__header .form__main .main__accept input:checked ~ span.checkmark::after {
  display: block;
}
.wrapper-top__header .form__main .main__accept .checkmark {
  background-color: #FFFFFF;
  position: absolute;
  border: 1px solid #000;
  height: 24px;
  width: 24px;
  left: 0;
  top: 5px;
  border-radius: 16px;
}
.wrapper-top__header .form__main .main__accept .checkmark::after {
  content: "";
  display: none;
  position: absolute;
  width: 60%;
  height: 60%;
  margin: auto;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: url(../img/check.svg) no-repeat center/cover;
}
.wrapper-top__header .form__main .main__accept .more--text {
  display: none;
}
.wrapper-top__header .form__main--submit {
  background: radial-gradient(91.66% 306.32% at 0% 16.18%, #01CC9D 0%, #019A9A 100%);
  border: 2px solid #FFFFFF;
  box-sizing: border-box;
  border-radius: 70px;
  font-family: Nunito, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 800;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: center;
  color: #FFFFFF;
  height: 55px;
  width: 100%;
  margin: 20px 0;
  cursor: pointer;
}
@media (max-width: 767.98px) {
  .wrapper-top__hero {
    display: none;
  }
}
@media (min-width: 768px) and (max-width: 1600px) {
  .wrapper-top__hero {
    right: 0;
    width: 50%;
  }
}
@media (min-width: 768px) and (max-width: 1600px) {
  .wrapper-top__hero.smaller-in-smaller {
    width: 40%;
  }
}
@media (min-width: 1600px) {
  .wrapper-top__hero {
    top: 60px;
    width: 65%;
    right: -150px;
  }
}
@media (min-width: 768px) {
  .wrapper-top__hero {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    align-items: flex-end;
    position: absolute;
    background: radial-gradient(54.26% 161.68% at 0% 16.18%, #00CF9E 0%, #019A9A 100%) no-repeat center/cover;
    mix-blend-mode: multiply;
    border-radius: 20px;
    margin: 0;
    max-height: 475px;
    overflow: hidden;
  }
  .wrapper-top__hero:after {
    content: "";
    border-radius: 20px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url(../img/about.jpg) no-repeat center/cover;
    mix-blend-mode: multiply;
    z-index: -1;
  }
  .wrapper-top__hero--img {
    max-width: 100%;
  }
}

.wrapper-years {
  display: flex;
  max-width: 1040px;
  width: 100%;
  padding: 0;
  flex-wrap: wrap;
}
@media (max-width: 767.98px) {
  .wrapper-years {
    flex-direction: column;
    align-items: center;
    margin: 0 auto 0 !important;
  }
}
@media (min-width: 768px) {
  .wrapper-years {
    margin: 70px auto 0 !important;
    justify-content: space-between;
  }
}
.wrapper-years__box {
  display: flex;
  max-width: 32.5%;
  width: 100%;
  flex-direction: column;
  margin: 0 0 20px;
}
@media (max-width: 767.98px) {
  .wrapper-years__box {
    display: flex;
    max-width: 380px;
    width: 100%;
  }
}
.wrapper-years__box--img {
  display: flex;
  max-width: 100%;
  width: 100%;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  height: 270px;
  margin: 0 0 15px;
  color: #000;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
@media (max-width: 767.98px) {
  .wrapper-years__box--img {
    align-items: center;
  }
}
.wrapper-years__box--img img {
  max-width: 100%;
  max-height: 100%;
}
.wrapper-years__box--title {
  display: flex;
  max-width: 100%;
  width: 100%;
}
@media (max-width: 767.98px) {
  .wrapper-years__box--title {
    border-bottom: 2px solid #D8D8D8;
  }
}
@media (min-width: 768px) {
  .wrapper-years__box--title {
    flex-direction: column;
  }
}
.wrapper-years__box--title span {
  font-weight: 700;
  font-style: normal;
  text-align: left;
  letter-spacing: 0em;
  color: #000;
}
.wrapper-years__box--title span:nth-of-type(1) {
  font-family: Nunito, sans-serif;
  font-size: 24px;
  line-height: 24px;
}
@media (max-width: 767.98px) {
  .wrapper-years__box--title span:nth-of-type(1) {
    font-size: 16px;
    margin-right: 10px;
  }
}
@media (min-width: 768px) {
  .wrapper-years__box--title span:nth-of-type(1) {
    padding: 0 0 15px;
    border-bottom: 2px solid #D8D8D8;
  }
}
.wrapper-years__box--title span:nth-of-type(2) {
  font-family: Montserrat, sans-serif;
  font-size: 16px;
  line-height: 24px;
}
@media (max-width: 767.98px) {
  .wrapper-years__box--title span:nth-of-type(2) {
    font-family: Nunito, sans-serif;
  }
}
@media (min-width: 768px) {
  .wrapper-years__box--title span:nth-of-type(2) {
    margin: 15px 0;
  }
}

.wrapper-whoweare {
  display: flex;
  max-width: 1040px;
  width: 100%;
  justify-content: center;
  margin: 0 auto !important;
  padding: 0 0 60px;
  flex-wrap: wrap;
}
.wrapper-whoweare__head {
  display: block;
  max-width: 100%;
  width: 100%;
  margin: 0 auto !important;
  padding: 0;
}
.wrapper-whoweare__head--title {
  font-family: Nunito, sans-serif;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 49px;
  letter-spacing: 0em;
  color: #000;
  text-align: center;
}
@media (max-width: 767.98px) {
  .wrapper-whoweare__head--title {
    margin: 40px auto 0;
    font-weight: 300;
    font-size: 18px;
    line-height: 25px;
  }
}
.wrapper-whoweare-main {
  display: flex;
  max-width: 1040px;
  width: 100%;
  padding: 0;
  margin: 40px auto;
}
@media (max-width: 767.98px) {
  .wrapper-whoweare-main {
    flex-direction: column;
    align-items: center;
    align-content: center;
    margin: 0 auto 40px;
  }
}
.wrapper-whoweare-main--title {
  flex-shrink: 2;
  display: block;
  max-width: 250px;
  width: 100%;
  font-family: Nunito, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 33px;
  letter-spacing: 0em;
  text-align: left;
  margin: 0 0 20px;
}
@media (max-width: 767.98px) {
  .wrapper-whoweare-main--title {
    text-align: center;
    font-size: 16px;
    line-height: 22px;
  }
}
.wrapper-whoweare-main__gfx {
  display: flex;
  max-width: 160px;
  width: 100%;
  flex-shrink: 1;
  margin: 0 20px;
  justify-content: center;
  align-items: center;
  align-content: center;
  height: 160px;
  clip-path: circle(50% at 50% 50%);
  -o-clip-path: circle(50% at 50% 50%);
  -ms-clip-path: circle(50% at 50% 50%);
  -moz-clip-path: circle(50% at 50% 50%);
  -webkit-clip-path: circle(50% at 50% 50%);
  background: radial-gradient(94.23% 94.23% at 0% 5.77%, #01CC9D 0%, #019A9A 100%);
}
.wrapper-whoweare-main__gfx--img {
  max-width: 100%;
}
.wrapper-whoweare-main__desc {
  flex-shrink: 1;
  display: block;
  max-width: 465px;
  width: 100%;
}
@media (max-width: 767.98px) {
  .wrapper-whoweare-main__desc {
    margin-top: 10px;
    text-align: center;
  }
}
.wrapper-whoweare-main__desc--title {
  font-family: Nunito, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 33px;
  letter-spacing: 0em;
  text-align: left;
  margin: 0;
}
@media (max-width: 767.98px) {
  .wrapper-whoweare-main__desc--title {
    text-align: center;
    font-size: 16px;
    line-height: 22px;
  }
}
.wrapper-whoweare-main__desc--subtitle {
  font-family: Nunito, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  margin: 0 0 10px;
}
@media (max-width: 767.98px) {
  .wrapper-whoweare-main__desc--subtitle {
    text-align: center;
    font-size: 16px;
    line-height: 22px;
  }
}
.wrapper-whoweare-main__desc--txt {
  margin: 0 auto;
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}
@media (max-width: 767.98px) {
  .wrapper-whoweare-main__desc--txt {
    margin: 0 auto 40px;
    text-align: center;
    font-size: 12px;
    line-height: 16px;
    max-width: 325px;
  }
}

.wrapper-crew {
  display: block;
  max-width: 100%;
  width: 100%;
  background: radial-gradient(122.72% 100% at 2.53% 0%, #52D2B4 0%, #11425E 100%);
  mix-blend-mode: multiply;
  position: relative;
}
.wrapper-crew:after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.2;
}
@media (max-width: 767.98px) {
  .wrapper-crew:after {
    background: url(../img/crewbg.png) no-repeat left bottom/cover;
    transform: matrix(-1, 0, 0, 1, 0, 0);
  }
}
@media (min-width: 768px) {
  .wrapper-crew:after {
    background: url(../img/crewbg.png) no-repeat center bottom/cover;
  }
}
.wrapper-crew__main {
  display: flex;
  max-width: 725px;
  width: 100%;
  flex-direction: column;
  align-items: center;
  margin: 40px auto 100px;
}
@media (max-width: 767.98px) {
  .wrapper-crew__main {
    margin: 40px auto 0;
    padding: 0 10px 20px;
  }
}
.wrapper-crew__main * {
  width: 100%;
}
.wrapper-crew__main--title {
  font-family: Nunito, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 55px;
  letter-spacing: 0em;
  text-align: center;
  color: #FFFFFF;
  margin: 40px 0 10px;
}
@media (max-width: 767.98px) {
  .wrapper-crew__main--title {
    text-align: left;
    font-size: 16px;
    line-height: 12px;
  }
}
.wrapper-crew__main--subtitle {
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  color: #FFFFFF;
  margin: 10px 0 40px;
}
@media (max-width: 767.98px) {
  .wrapper-crew__main--subtitle {
    margin: 10px 0;
    text-align: left;
    font-size: 10px;
    line-height: 15px;
  }
}
.wrapper-crew__main--desc {
  font-family: Montserrat, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #FFFFFF;
  margin: 10px 0 40px;
}
@media (max-width: 767.98px) {
  .wrapper-crew__main--desc {
    text-align: left;
    font-size: 12px;
    line-height: 18px;
  }
}

.wrapper-contact {
  display: flex;
  max-width: 100%;
  width: 100%;
  min-height: 800px;
  margin: 0 auto !important;
  background-color: #000;
  position: relative;
}
@media (max-width: 767.98px) {
  .wrapper-contact {
    flex-direction: column;
    align-items: center;
  }
}
@media (min-width: 768px) {
  .wrapper-contact {
    padding: 180px 30px 0 !important;
    justify-content: center;
  }
}
.wrapper-contact:before, .wrapper-contact:after {
  z-index: 0;
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.wrapper-contact:before {
  background: radial-gradient(54.26% 161.68% at 0% 16.18%, #00CF9E 0%, #0F8763 100%);
  opacity: 0.5;
}
.wrapper-contact:after {
  mix-blend-mode: multiply;
  background: url(../img/contact.jpg) no-repeat center/cover;
}
.wrapper-contact__address {
  flex-direction: column;
  z-index: 2;
}
@media (max-width: 767.98px) {
  .wrapper-contact__address {
    display: flex;
    max-width: 330px;
    width: 100%;
  }
}
@media (min-width: 768px) {
  .wrapper-contact__address {
    display: flex;
    max-width: 500px;
    width: 100%;
  }
}
@media (min-width: 768px) and (max-width: 1299.98px) {
  .wrapper-contact__address {
    margin-right: 5vw;
  }
}
@media (min-width: 1300px) {
  .wrapper-contact__address {
    margin-right: 100px;
  }
}
.wrapper-contact__address--title {
  font-family: Nunito, sans-serif;
  font-size: 36px;
  font-style: normal;
  font-weight: 300;
  line-height: 1.4;
  letter-spacing: 0em;
  color: #fff;
  margin: 0;
}
@media (max-width: 767.98px) {
  .wrapper-contact__address--title {
    text-align: left;
    margin: 40px auto 0;
    font-size: 18px;
    line-height: 1.4;
    margin-left: 0;
  }
}
.wrapper-contact__address--subtitle {
  font-family: Montserrat, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 1.4;
  letter-spacing: 0em;
  color: #fff;
  margin: 12px 0;
}
@media (max-width: 767.98px) {
  .wrapper-contact__address--subtitle {
    text-align: center;
    margin: 40px auto 0;
    font-size: 14px;
    line-height: 1.4;
  }
}
.wrapper-contact__address--desc {
  font-family: Montserrat, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 34px;
  letter-spacing: 0em;
  color: #fff;
  margin: 12px 0;
}
.wrapper-contact__address--desc a {
  text-decoration: none;
  color: #fff;
}
.wrapper-contact__address--addr {
  background-color: #fff;
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  letter-spacing: 0em;
  color: #000;
  margin: auto 0 0;
  padding: 30px;
  max-width: 345px;
}
@media (max-width: 767.98px) {
  .wrapper-contact__address--addr {
    display: none;
  }
}
.wrapper-contact-form {
  justify-content: flex-start;
  align-content: center;
  flex-direction: column;
  margin: 0 !important;
  padding: 20px 0 !important;
  flex-wrap: wrap;
  z-index: 2;
}
@media (max-width: 767.98px) {
  .wrapper-contact-form {
    display: flex;
    max-width: 330px;
    width: 100%;
  }
}
@media (min-width: 768px) {
  .wrapper-contact-form {
    display: flex;
    max-width: 435px;
    width: 100%;
  }
}
.wrapper-contact-form__head {
  display: block;
  max-width: 100%;
  width: 100%;
  margin: 0 auto !important;
  padding: 20px 0;
}
.wrapper-contact-form__head--desc {
  font-family: Montserrat, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 22px;
  letter-spacing: 0em;
  margin: 0;
  padding: 0;
  color: #fff;
}
.wrapper-contact-form__main {
  display: flex;
  max-width: 435px;
  width: 100%;
  margin: 0 auto !important;
  padding: 0;
  flex-direction: column;
  z-index: 2;
  position: relative;
}
.wrapper-contact-form__main ::-webkit-input-placeholder {
  font-weight: 300 !important;
}
.wrapper-contact-form__main ::placeholder {
  font-weight: 300 !important;
}
.wrapper-contact-form__main ::-ms-placeholder {
  font-weight: 300 !important;
}
.wrapper-contact-form__main ::-moz-placeholder {
  font-weight: 300 !important;
}
.wrapper-contact-form__main--input {
  font-family: Nunito, sans-serif;
  width: 100%;
  box-shadow: 0px 0px 30px 0px #00000026;
  font-family: Nunito, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0em;
  background: #FFFFFF;
  height: 55px;
  margin: 10px auto 0;
  border-radius: 70px;
  border: 3px solid #019A9A;
  color: #000;
  padding: 17px;
}
@media (max-width: 767.98px) {
  .wrapper-contact-form__main--input {
    max-width: 330px;
  }
}
.wrapper-contact-form__main--textarea {
  font-family: Nunito, sans-serif;
  width: 100%;
  box-shadow: 0px 0px 30px 0px #00000026;
  font-family: Nunito, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0em;
  background: #FFFFFF;
  padding: 17px;
  margin: 10px auto 0;
  border-radius: 25px;
  border: 3px solid #019A9A;
  color: #000;
}
@media (max-width: 767.98px) {
  .wrapper-contact-form__main--textarea {
    max-width: 330px;
  }
}
.wrapper-contact-form__main--submit {
  background: radial-gradient(91.66% 306.32% at 0% 16.18%, #01CC9D 0%, #019A9A 100%);
  border: 2px solid #FFFFFF;
  box-sizing: border-box;
  border-radius: 70px;
  font-family: Nunito, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 800;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: center;
  color: #FFFFFF;
  height: 55px;
  width: 100%;
  margin: 20px 0;
  cursor: pointer;
}

html, body {
  font-family: Nunito, sans-serif;
}

input:focus, input:focus-visible, textarea:focus, textarea:focus-visible {
  outline: none;
}

.wrapper-jumbo {
  display: flex;
  max-width: 900px;
  width: 100%;
  margin: 0 auto;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-end;
  padding: 0;
  z-index: 1;
}
.wrapper-jumbo.jumbo-fluid {
  max-width: 1600px;
}
.wrapper-jumbo--mini, .wrapper-jumbo--big {
  display: block;
  font-family: Nunito, sans-serif;
  font-style: normal;
  font-weight: 300;
  letter-spacing: 0em;
}
.wrapper-jumbo--mini {
  width: 16%;
  font-size: 20px;
  line-height: 18px;
  margin: 0 0 40px;
}
@media (max-width: 767.98px) {
  .wrapper-jumbo--mini {
    margin: 0;
    font-size: 12px;
    line-height: 15px;
    width: 100%;
    font-weight: 500;
    max-width: 165px;
  }
}
.wrapper-jumbo--mini:first-of-type {
  text-align: right;
}
@media (max-width: 767.98px) {
  .wrapper-jumbo--mini:first-of-type {
    order: 1;
    text-align: left;
  }
}
.wrapper-jumbo--mini:last-of-type {
  text-align: left;
}
@media (max-width: 767.98px) {
  .wrapper-jumbo--mini:last-of-type {
    order: 2;
    text-align: right;
  }
}
.wrapper-jumbo--big {
  width: 55%;
  text-align: center;
  font-size: 74px;
  line-height: 1;
  margin: 0 15px 40px;
}
@media (max-width: 767.98px) {
  .wrapper-jumbo--big {
    font-size: 56px;
    line-height: 76px;
    width: 100%;
    order: 3;
  }
}
.wrapper-jumbo--pretitle {
  margin: 0 auto;
  width: 100%;
  max-width: 775px;
  font-family: Montserrat, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 300;
  line-height: 33px;
  letter-spacing: 0em;
  text-align: center;
}
@media (max-width: 767.98px) {
  .wrapper-jumbo--pretitle {
    max-width: 100%;
    font-size: 16px;
    line-height: 26px;
  }
}
.wrapper-jumbo--title {
  width: 100%;
  margin: 30px auto;
  font-family: Nunito, sans-serif;
  font-size: 40px;
  font-style: normal;
  font-weight: 300;
  line-height: 55px;
  letter-spacing: 0em;
  text-align: center;
}
@media (max-width: 767.98px) {
  .wrapper-jumbo--title {
    order: 4;
    font-size: 24px;
    line-height: 28px;
  }
  .wrapper-jumbo--title.more-padding {
    padding: 20px 0;
  }
}
.wrapper-jumbo--subtitle {
  order: 5;
  margin: 0 auto;
  width: 100%;
  max-width: 775px;
  font-family: Montserrat, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: center;
}
@media (max-width: 767.98px) {
  .wrapper-jumbo--subtitle {
    max-width: 100%;
    font-size: 13px;
    line-height: 16px;
  }
}
.wrapper-jumbo--desc {
  order: 6;
  margin: 0 auto 25px;
  max-width: 775px;
  font-family: Nunito, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 1.4em;
  letter-spacing: 0em;
  text-align: center;
}
@media (max-width: 767.98px) {
  .wrapper-jumbo--desc {
    max-width: none;
    font-size: 14px;
    line-height: 1.4em;
  }
}
.wrapper-jumbo--btns {
  justify-content: space-around;
  margin: 0;
  padding: 0;
  display: flex;
  max-width: 100%;
  width: 100%;
}
@media (max-width: 767.98px) {
  .wrapper-jumbo--btns {
    flex-direction: column;
    align-items: center;
    order: 5;
  }
  .wrapper-jumbo--btns * {
    max-width: 325px;
    width: 100%;
    margin: 0 0 10px !important;
  }
}
@media (min-width: 768px) {
  .wrapper-jumbo--btns * {
    width: 45%;
  }
}
.wrapper-jumbo__box {
  display: block;
  max-width: 560px;
  width: 100%;
  margin: 0;
  padding: 10px 30px;
}
@media (max-width: 767.98px) {
  .wrapper-jumbo__box {
    padding: 0px;
    order: 5;
    max-width: none;
    text-align: center;
  }
}
@media (min-width: 768px) {
  .wrapper-jumbo__box {
    width: 45% !important;
  }
}
.wrapper-jumbo__box--title {
  margin: 0 0 15px;
  font-family: Nunito, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 33px;
  letter-spacing: 0em;
}
@media (max-width: 767.98px) {
  .wrapper-jumbo__box--title {
    font-size: 24px;
    line-height: 28px;
  }
}
.wrapper-jumbo__box--desc {
  font-family: Nunito, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  letter-spacing: 0em;
  padding-left: 28px;
  list-style-image: url(../img/Vector1.png);
}
@media (max-width: 767.98px) {
  .wrapper-jumbo__box--desc {
    font-size: 14px;
    line-height: 1.5em;
  }
}
@media (min-width: 768px) {
  .wrapper-jumbo__box--desc {
    list-style: none;
    padding-left: 0;
  }
}
@media (min-width: 768px) {
  .wrapper-jumbo__box:first-of-type {
    text-align: right;
    border-right: 1px solid #000;
  }
}

.wrapper-acc-top {
  display: flex;
  max-width: 100%;
  width: 100%;
  margin: 0 0 20px;
  padding: 0 !important;
  border-bottom: 1px solid #BDBDBD;
}
@media (max-width: 768px) {
  .wrapper-acc-top {
    min-height: 425px;
  }
}
@media (min-width: 768px) {
  .wrapper-acc-top {
    min-height: 425px;
  }
}
.wrapper-acc-top--title {
  font-family: Nunito, sans-serif;
  font-size: 40px;
  font-style: normal;
  font-weight: 300;
  line-height: 55px;
  letter-spacing: 0em;
  text-align: center;
  width: 90%;
  margin: 0 auto;
}
@media (max-width: 767.98px) {
  .wrapper-acc-top--title {
    text-align: left;
    top: 30px;
    left: 45px;
    max-width: 200px;
    color: #fff;
    position: absolute;
    font-size: 20px;
    line-height: 27px;
  }
}
.wrapper-acc-top--desc {
  font-family: Nunito, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: center;
  max-width: 940px;
  padding-left: 20px;
  padding-right: 20px;
}
@media (max-width: 767.98px) {
  .wrapper-acc-top--desc {
    text-align: left;
    font-size: 14px;
    line-height: 18px;
    margin: 50px auto 40px;
    order: 2;
  }
}
@media (min-width: 768px) and (max-width: 1599.98px) {
  .wrapper-acc-top--desc {
    margin: 20px auto 100px;
  }
}
@media (min-width: 1600px) {
  .wrapper-acc-top--desc {
    margin: 20px auto 250px;
  }
}
.wrapper-acc-top__boxes {
  display: flex;
  max-width: 57%;
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-around;
}
@media (max-width: 767.98px) {
  .wrapper-acc-top__boxes {
    padding-left: 25px;
    padding-right: 25px;
    max-width: 100%;
    order: 4;
  }
}
@media (min-width: 768px) {
  .wrapper-acc-top__boxes {
    margin: -50px 0 0;
  }
}
.wrapper-acc-top__boxes--box {
  width: 40%;
  margin-bottom: 30px;
  margin-top: 0;
}
@media (max-width: 767.98px) {
  .wrapper-acc-top__boxes--box {
    width: 100%;
  }
}
.wrapper-acc-top__boxes--box span {
  display: block;
}
.wrapper-acc-top__boxes--box span:nth-of-type(1) {
  max-width: 246px;
  font-family: Nunito, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 1.4em;
  letter-spacing: 0em;
  text-align: left;
}
@media (max-width: 767.98px) {
  .wrapper-acc-top__boxes--box span:nth-of-type(1) {
    max-width: 380px;
    font-size: 18px;
    line-height: 1.4em;
  }
  .wrapper-acc-top__boxes--box span:nth-of-type(1) br {
    display: none;
  }
}
.wrapper-acc-top__boxes--box span:nth-of-type(2) {
  margin-top: 1em;
  max-width: 321px;
  font-family: Nunito, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  line-height: 1.5em;
  letter-spacing: 0em;
  text-align: left;
}
@media (max-width: 767.98px) {
  .wrapper-acc-top__boxes--box span:nth-of-type(2) {
    max-width: 380px;
    font-size: 14px;
    line-height: 1.5em;
  }
}
.wrapper-acc-top__header {
  padding-bottom: 30px;
}
@media (max-width: 767.98px) {
  .wrapper-acc-top__header {
    display: flex;
    max-width: 100%;
    width: 100%;
    flex-direction: column;
    align-items: center;
  }
}
@media (min-width: 768px) {
  .wrapper-acc-top__header {
    margin-left: 5vw;
    display: block;
    max-width: 45%;
    width: 100%;
  }
}
.wrapper-acc-top__header--title {
  width: 100%;
  margin: 20px auto;
  font-family: Nunito, sans-serif;
  font-size: 40px;
  font-style: normal;
  font-weight: 300;
  line-height: 55px;
  letter-spacing: 0em;
  color: #000;
}
@media (max-width: 767.98px) {
  .wrapper-acc-top__header--title {
    height: 36vw;
    position: relative;
    margin: 0;
    width: 100%;
    padding: 35px;
    font-size: 20px;
    line-height: 27px;
    color: #fff;
  }
  .wrapper-acc-top__header--title span {
    max-width: 70%;
    display: block;
  }
  .wrapper-acc-top__header--title:before {
    content: "";
    position: absolute;
    height: 100%;
  }
  .wrapper-acc-top__header--title:before {
    left: 0;
    right: 0;
    top: 0;
    width: 100%;
    z-index: -1;
    background: radial-gradient(54.26% 161.68% at 0% 16.18%, #00CF9E 0%, #019A9A 100%) no-repeat center/cover;
  }
  .wrapper-acc-top__header--title .title--img {
    width: 50%;
    position: absolute;
    right: 0;
    bottom: 0;
  }
}
@media (min-width: 768px) {
  .wrapper-acc-top__header--title .title--img {
    display: none;
  }
}
.wrapper-acc-top__header--desc {
  font-family: Nunito, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 26px;
  letter-spacing: 0em;
  color: #000;
}
@media (max-width: 767.98px) {
  .wrapper-acc-top__header--desc {
    padding: 25px;
    margin: 0;
    max-width: none;
    font-size: 14px;
    line-height: 1.5;
  }
}
.wrapper-acc-top__header--btns {
  justify-content: space-between;
  margin: 0;
  padding: 0;
}
@media (max-width: 767.98px) {
  .wrapper-acc-top__header--btns {
    display: flex;
    max-width: 100%;
    width: 100%;
    flex-direction: column;
    align-items: center;
    padding-left: 25px;
    padding-right: 25px;
  }
  .wrapper-acc-top__header--btns * {
    width: 100%;
    margin: 0 0 10px !important;
  }
}
@media (min-width: 768px) {
  .wrapper-acc-top__header--btns * {
    width: 45%;
  }
}
.wrapper-acc-top__header .form__main {
  display: flex;
  max-width: 425px;
  width: 100%;
  margin: 0 !important;
  padding: 0;
  flex-direction: column;
  position: relative;
}
.wrapper-acc-top__header .form__main input[type=password],
.wrapper-acc-top__header .form__main .main__accept,
.wrapper-acc-top__header .form__main .form__main--check {
  display: none;
}
.wrapper-acc-top__header .form__main input[type=email] {
  border: none;
}
.wrapper-acc-top__header .form__main input[type=submit] {
  position: absolute;
  top: -10px;
  left: 85%;
  max-width: 55%;
  border: none;
}
.wrapper-acc-top__header .form__main--input {
  width: 100%;
  box-shadow: 7px 7px 30px -5px #0000007c;
  font-family: Nunito, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
  background: #FFFFFF;
  height: 55px;
  padding: 0 20px;
  margin: 10px auto 0;
  border-radius: 70px;
  color: #000;
  border: 3px solid #fff;
}
@media (max-width: 767.98px) {
  .wrapper-acc-top__header .form__main--input {
    max-width: 330px;
  }
}
.wrapper-acc-top__header .form__main--input:focus-visible, .wrapper-acc-top__header .form__main--input:focus {
  border: 3px solid #019A9A !important;
  outline: none;
}
.wrapper-acc-top__header .form__main--input.error {
  border-color: red;
}
.wrapper-acc-top__header .form__main--check {
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: #000;
  margin: 10px 0;
  text-decoration: underline;
  cursor: pointer;
}
.wrapper-acc-top__header .form__main .main__accept {
  max-width: 350px;
  position: relative;
  width: 100%;
  margin: 5px 0;
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: #000;
  padding: 0 0 0 35px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
@media (max-width: 767.98px) {
  .wrapper-acc-top__header .form__main .main__accept {
    font-size: 12px;
    line-height: 15px;
  }
}
.wrapper-acc-top__header .form__main .main__accept a, .wrapper-acc-top__header .form__main .main__accept span.label--more {
  cursor: pointer;
  font-weight: 500;
  color: #000;
}
.wrapper-acc-top__header .form__main .main__accept--checkbox {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
  margin: 0;
}
.wrapper-acc-top__header .form__main .main__accept input:checked ~ span.checkmark::after {
  display: block;
}
.wrapper-acc-top__header .form__main .main__accept .checkmark {
  background-color: #FFFFFF;
  position: absolute;
  border: 1px solid #000;
  height: 24px;
  width: 24px;
  left: 0;
  top: 5px;
  border-radius: 16px;
}
.wrapper-acc-top__header .form__main .main__accept .checkmark::after {
  content: "";
  display: none;
  position: absolute;
  width: 60%;
  height: 60%;
  margin: auto;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: url(../img/check.svg) no-repeat center/cover;
}
.wrapper-acc-top__header .form__main .main__accept .more--text {
  display: none;
}
.wrapper-acc-top__header .form__main--submit {
  background: radial-gradient(91.66% 306.32% at 0% 16.18%, #01CC9D 0%, #019A9A 100%);
  border: 2px solid #FFFFFF;
  box-sizing: border-box;
  border-radius: 70px;
  font-family: Nunito, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 800;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: center;
  color: #FFFFFF;
  height: 55px;
  width: 100%;
  margin: 20px 0;
  cursor: pointer;
}
.wrapper-acc-top__header .form__main-with-modal {
  display: flex;
  max-width: 425px;
  width: 100%;
  margin: 0 !important;
  padding: 0;
  flex-direction: column;
  position: relative;
}
.wrapper-acc-top__header .form__main-with-modal input[type=email] {
  border: none;
}
.wrapper-acc-top__header .form__main-with-modal--input {
  width: 100%;
  box-shadow: 7px 7px 30px -5px #00000026;
  font-family: Nunito, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
  background: #FFFFFF;
  height: 55px;
  padding: 0 20px;
  margin: 20px auto 0;
  border-radius: 70px;
  color: #000;
  border: 3px solid #fff;
}
@media (max-width: 767.98px) {
  .wrapper-acc-top__header .form__main-with-modal--input {
    max-width: 330px;
  }
}
.wrapper-acc-top__header .form__main-with-modal--input:focus-visible, .wrapper-acc-top__header .form__main-with-modal--input:focus {
  border: 3px solid #019A9A !important;
  outline: none;
}
.wrapper-acc-top__header .form__main-with-modal--input.error {
  border-color: red;
}
.wrapper-acc-top__header .form__main-with-modal--check {
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: #000;
  margin: 10px 0;
  text-decoration: underline;
  cursor: pointer;
}
.wrapper-acc-top__header .form__main-with-modal .main__accept {
  position: relative;
  width: 100%;
  margin: 5px 0;
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: #000;
  padding: 0 0 0 35px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
@media (max-width: 767.98px) {
  .wrapper-acc-top__header .form__main-with-modal .main__accept {
    font-size: 12px;
    line-height: 15px;
  }
}
.wrapper-acc-top__header .form__main-with-modal .main__accept a, .wrapper-acc-top__header .form__main-with-modal .main__accept span.label--more {
  cursor: pointer;
  font-weight: 500;
  color: #000;
}
.wrapper-acc-top__header .form__main-with-modal .main__accept--checkbox {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
  margin: 0;
}
.wrapper-acc-top__header .form__main-with-modal .main__accept input:checked ~ span.checkmark::after {
  display: block;
}
.wrapper-acc-top__header .form__main-with-modal .main__accept .checkmark {
  background-color: #FFFFFF;
  position: absolute;
  border: 1px solid #019A9A;
  height: 20px;
  width: 20px;
  left: 0;
  top: 1px;
  border-radius: 16px;
}
.wrapper-acc-top__header .form__main-with-modal .main__accept .checkmark::after {
  content: "";
  display: none;
  position: absolute;
  width: 60%;
  height: 60%;
  margin: auto;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: url(../img/check.svg) no-repeat center/cover;
}
.wrapper-acc-top__header .form__main-with-modal .main__accept .more--text {
  display: none;
}
.wrapper-acc-top__header .form__main-with-modal--submit {
  background: radial-gradient(91.66% 306.32% at 0% 16.18%, #01CC9D 0%, #019A9A 100%);
  border: 2px solid #FFFFFF;
  box-sizing: border-box;
  border-radius: 70px;
  font-family: Nunito, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 800;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: center;
  color: #FFFFFF;
  height: 55px;
  width: 100%;
  margin: 20px 0;
  cursor: pointer;
}
@media (max-width: 767.98px) {
  .wrapper-acc-top__hero {
    display: none;
    order: 1;
  }
}
@media (min-width: 768px) and (max-width: 1599.98px) {
  .wrapper-acc-top__hero {
    right: 0;
    margin: 0 0 0 30px;
    max-width: 45%;
  }
}
@media (min-width: 1600px) {
  .wrapper-acc-top__hero {
    top: 60px;
    width: 53%;
    right: -141px;
  }
}
@media (min-width: 768px) {
  .wrapper-acc-top__hero {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: absolute;
    margin: 0;
    max-height: 410px;
    background: radial-gradient(54.26% 161.68% at 0% 16.18%, #00CF9E 0%, #0F8763 100%);
    border-radius: 30px;
  }
  .wrapper-acc-top__hero--img {
    max-width: 100%;
    border-radius: 30px;
  }
}

.wrapper-acc-top2 {
  display: flex;
  max-width: 100%;
  width: 100%;
  margin: 0 0 20px;
  padding: 0 !important;
  border-bottom: 1px solid #BDBDBD;
}
@media (max-width: 768px) {
  .wrapper-acc-top2 {
    min-height: 425px;
  }
}
@media (min-width: 768px) {
  .wrapper-acc-top2 {
    min-height: 425px;
  }
}
.wrapper-acc-top2--title {
  font-family: Nunito, sans-serif;
  font-size: 40px;
  font-style: normal;
  font-weight: 300;
  line-height: 55px;
  letter-spacing: 0em;
  text-align: center;
  width: 90%;
  margin: 0 auto;
}
@media (max-width: 767.98px) {
  .wrapper-acc-top2--title {
    text-align: left;
    top: 30px;
    left: 45px;
    max-width: 200px;
    color: #fff;
    position: absolute;
    font-size: 20px;
    line-height: 27px;
  }
}
.wrapper-acc-top2--desc {
  font-family: Nunito, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: center;
  max-width: 940px;
  padding-left: 20px;
  padding-right: 20px;
}
@media (max-width: 767.98px) {
  .wrapper-acc-top2--desc {
    text-align: left;
    font-size: 14px;
    line-height: 18px;
    margin: 50px auto 40px;
    order: 2;
  }
}
@media (min-width: 768px) and (max-width: 1599.98px) {
  .wrapper-acc-top2--desc {
    margin: 20px auto 9%;
  }
}
@media (min-width: 1600px) {
  .wrapper-acc-top2--desc {
    margin: 20px auto 5%;
  }
}
.wrapper-acc-top2__boxes {
  display: flex;
  max-width: 100%;
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-around;
}
@media (max-width: 1000px) {
  .wrapper-acc-top2__boxes {
    padding-left: 25px;
    padding-right: 25px;
    max-width: 100%;
    order: 4;
  }
}
@media (min-width: 1001px) {
  .wrapper-acc-top2__boxes {
    margin: 0px 0 0;
    max-width: 53%;
  }
}
.wrapper-acc-top2__boxes--box {
  width: 40%;
  margin-bottom: 30px;
  margin-top: 0;
}
@media (max-width: 767.98px) {
  .wrapper-acc-top2__boxes--box {
    width: 100%;
  }
}
.wrapper-acc-top2__boxes--box span {
  display: block;
}
.wrapper-acc-top2__boxes--box span:nth-of-type(1) {
  max-width: 246px;
  font-family: Nunito, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 1.1;
  letter-spacing: 0em;
  text-align: left;
}
@media (max-width: 767.98px) {
  .wrapper-acc-top2__boxes--box span:nth-of-type(1) {
    max-width: 380px;
    font-size: 18px;
    line-height: 1.4em;
  }
  .wrapper-acc-top2__boxes--box span:nth-of-type(1) br {
    display: none;
  }
}
.wrapper-acc-top2__boxes--box span:nth-of-type(2) {
  margin-top: 1em;
  max-width: 321px;
  font-family: Nunito, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  line-height: 1.5em;
  letter-spacing: 0em;
  text-align: left;
}
@media (max-width: 767.98px) {
  .wrapper-acc-top2__boxes--box span:nth-of-type(2) {
    max-width: 380px;
    font-size: 14px;
    line-height: 1.5em;
  }
}
.wrapper-acc-top2__header {
  padding-bottom: 30px;
}
@media (max-width: 767.98px) {
  .wrapper-acc-top2__header {
    display: flex;
    max-width: 100%;
    width: 100%;
    flex-direction: column;
    align-items: center;
  }
}
@media (min-width: 768px) {
  .wrapper-acc-top2__header {
    margin-left: 5vw;
    display: block;
    max-width: 45%;
    width: 100%;
  }
}
.wrapper-acc-top2__header--title {
  width: 100%;
  margin: 20px auto;
  font-family: Nunito, sans-serif;
  font-size: 40px;
  font-style: normal;
  font-weight: 300;
  line-height: 55px;
  letter-spacing: 0em;
  color: #000;
}
@media (max-width: 767.98px) {
  .wrapper-acc-top2__header--title {
    height: 36vw;
    position: relative;
    margin: 0;
    width: 100%;
    padding: 35px;
    font-size: 20px;
    line-height: 27px;
    color: #fff;
  }
  .wrapper-acc-top2__header--title span {
    max-width: 70%;
    display: block;
  }
  .wrapper-acc-top2__header--title:before {
    content: "";
    position: absolute;
    height: 100%;
  }
  .wrapper-acc-top2__header--title:before {
    left: 0;
    right: 0;
    top: 0;
    width: 100%;
    z-index: -1;
    background: radial-gradient(54.26% 161.68% at 0% 16.18%, #00CF9E 0%, #019A9A 100%) no-repeat center/cover;
  }
  .wrapper-acc-top2__header--title .title--img {
    width: 50%;
    position: absolute;
    right: 0;
    bottom: 0;
  }
}
@media (min-width: 768px) {
  .wrapper-acc-top2__header--title .title--img {
    display: none;
  }
}
.wrapper-acc-top2__header--desc {
  font-family: Nunito, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 26px;
  letter-spacing: 0em;
  color: #000;
}
@media (max-width: 767.98px) {
  .wrapper-acc-top2__header--desc {
    padding: 25px;
    margin: 0;
    max-width: none;
    font-size: 14px;
    line-height: 1.5;
  }
}
.wrapper-acc-top2__header--btns {
  justify-content: space-between;
  margin: 0;
  padding: 0;
}
@media (max-width: 767.98px) {
  .wrapper-acc-top2__header--btns {
    display: flex;
    max-width: 100%;
    width: 100%;
    flex-direction: column;
    align-items: center;
    padding-left: 25px;
    padding-right: 25px;
  }
  .wrapper-acc-top2__header--btns * {
    width: 100%;
    margin: 0 0 10px !important;
  }
}
@media (min-width: 768px) {
  .wrapper-acc-top2__header--btns * {
    width: 45%;
  }
}
.wrapper-acc-top2__header .form__main {
  display: flex;
  max-width: 425px;
  width: 100%;
  margin: 0 !important;
  padding: 0;
  flex-direction: column;
  position: relative;
}
.wrapper-acc-top2__header .form__main input[type=password],
.wrapper-acc-top2__header .form__main .main__accept,
.wrapper-acc-top2__header .form__main .form__main--check {
  display: none;
}
.wrapper-acc-top2__header .form__main input[type=email] {
  border: none;
}
.wrapper-acc-top2__header .form__main input[type=submit] {
  position: absolute;
  top: -10px;
  left: 85%;
  max-width: 55%;
  border: none;
}
.wrapper-acc-top2__header .form__main--input {
  width: 100%;
  box-shadow: 7px 7px 30px -5px #0000007c;
  font-family: Nunito, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
  background: #FFFFFF;
  height: 55px;
  padding: 0 20px;
  margin: 10px auto 0;
  border-radius: 70px;
  color: #000;
  border: 3px solid #fff;
}
@media (max-width: 767.98px) {
  .wrapper-acc-top2__header .form__main--input {
    max-width: 330px;
  }
}
.wrapper-acc-top2__header .form__main--input:focus-visible, .wrapper-acc-top2__header .form__main--input:focus {
  border: 3px solid #019A9A !important;
  outline: none;
}
.wrapper-acc-top2__header .form__main--check {
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: #000;
  margin: 10px 0;
  text-decoration: underline;
  cursor: pointer;
}
.wrapper-acc-top2__header .form__main .main__accept {
  max-width: 350px;
  position: relative;
  width: 100%;
  margin: 5px 0;
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: #000;
  padding: 0 0 0 35px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
@media (max-width: 767.98px) {
  .wrapper-acc-top2__header .form__main .main__accept {
    font-size: 12px;
    line-height: 15px;
  }
}
.wrapper-acc-top2__header .form__main .main__accept a, .wrapper-acc-top2__header .form__main .main__accept span.label--more {
  cursor: pointer;
  font-weight: 500;
  color: #000;
}
.wrapper-acc-top2__header .form__main .main__accept--checkbox {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
  margin: 0;
}
.wrapper-acc-top2__header .form__main .main__accept input:checked ~ span.checkmark::after {
  display: block;
}
.wrapper-acc-top2__header .form__main .main__accept .checkmark {
  background-color: #FFFFFF;
  position: absolute;
  border: 1px solid #000;
  height: 24px;
  width: 24px;
  left: 0;
  top: 5px;
  border-radius: 16px;
}
.wrapper-acc-top2__header .form__main .main__accept .checkmark::after {
  content: "";
  display: none;
  position: absolute;
  width: 60%;
  height: 60%;
  margin: auto;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: url(../img/check.svg) no-repeat center/cover;
}
.wrapper-acc-top2__header .form__main .main__accept .more--text {
  display: none;
}
.wrapper-acc-top2__header .form__main--submit {
  background: radial-gradient(91.66% 306.32% at 0% 16.18%, #01CC9D 0%, #019A9A 100%);
  border: 2px solid #FFFFFF;
  box-sizing: border-box;
  border-radius: 70px;
  font-family: Nunito, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 800;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: center;
  color: #FFFFFF;
  height: 55px;
  width: 100%;
  margin: 20px 0;
  cursor: pointer;
}
.wrapper-acc-top2__header .form__main-with-modal {
  display: flex;
  max-width: 425px;
  width: 100%;
  margin: 0 !important;
  padding: 0;
  flex-direction: column;
  position: relative;
}
.wrapper-acc-top2__header .form__main-with-modal input[type=password],
.wrapper-acc-top2__header .form__main-with-modal input[type=text],
.wrapper-acc-top2__header .form__main-with-modal .btn-open-modal-form,
.wrapper-acc-top2__header .form__main-with-modal .main__accept,
.wrapper-acc-top2__header .form__main-with-modal .form__main-with-modal--check {
  display: none;
}
.wrapper-acc-top2__header .form__main-with-modal input[type=email] {
  border: none;
}
.wrapper-acc-top2__header .form__main-with-modal input[type=submit] {
  position: absolute;
  top: -10px;
  left: 85%;
  max-width: 55%;
  border: none;
}
.wrapper-acc-top2__header .form__main-with-modal--input {
  width: 100%;
  box-shadow: 7px 7px 30px -5px #00000026;
  font-family: Nunito, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
  background: #FFFFFF;
  height: 55px;
  padding: 0 20px;
  margin: 20px auto 0;
  border-radius: 70px;
  color: #000;
  border: 3px solid #fff;
}
@media (max-width: 767.98px) {
  .wrapper-acc-top2__header .form__main-with-modal--input {
    max-width: 330px;
  }
}
.wrapper-acc-top2__header .form__main-with-modal--input:focus-visible, .wrapper-acc-top2__header .form__main-with-modal--input:focus {
  border: 3px solid #019A9A !important;
  outline: none;
}
.wrapper-acc-top2__header .form__main-with-modal--check {
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: #000;
  margin: 10px 0;
  text-decoration: underline;
  cursor: pointer;
}
.wrapper-acc-top2__header .form__main-with-modal .main__accept {
  position: relative;
  width: 100%;
  margin: 5px 0;
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: #000;
  padding: 0 0 0 35px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
@media (max-width: 767.98px) {
  .wrapper-acc-top2__header .form__main-with-modal .main__accept {
    font-size: 12px;
    line-height: 15px;
  }
}
.wrapper-acc-top2__header .form__main-with-modal .main__accept a, .wrapper-acc-top2__header .form__main-with-modal .main__accept span.label--more {
  cursor: pointer;
  font-weight: 500;
  color: #000;
}
.wrapper-acc-top2__header .form__main-with-modal .main__accept--checkbox {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
  margin: 0;
}
.wrapper-acc-top2__header .form__main-with-modal .main__accept input:checked ~ span.checkmark::after {
  display: block;
}
.wrapper-acc-top2__header .form__main-with-modal .main__accept .checkmark {
  background-color: #FFFFFF;
  position: absolute;
  border: 1px solid #000;
  height: 24px;
  width: 24px;
  left: 0;
  top: 5px;
  border-radius: 16px;
}
.wrapper-acc-top2__header .form__main-with-modal .main__accept .checkmark::after {
  content: "";
  display: none;
  position: absolute;
  width: 60%;
  height: 60%;
  margin: auto;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: url(../img/check.svg) no-repeat center/cover;
}
.wrapper-acc-top2__header .form__main-with-modal .main__accept .more--text {
  display: none;
}
.wrapper-acc-top2__header .form__main-with-modal--submit {
  background: radial-gradient(91.66% 306.32% at 0% 16.18%, #01CC9D 0%, #019A9A 100%);
  border: 2px solid #FFFFFF;
  box-sizing: border-box;
  border-radius: 70px;
  font-family: Nunito, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 800;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: center;
  color: #FFFFFF;
  height: 55px;
  width: 100%;
  margin: 20px 0;
  cursor: pointer;
}
.wrapper-acc-top2__hero {
  background: radial-gradient(43.63% 128.84% at 10.62% 27.13%, #019A9A 0%, #0083CC 100%) !important;
}
@media (max-width: 1000px) {
  .wrapper-acc-top2__hero {
    display: none;
    order: 1;
  }
}
@media (min-width: 768px) and (max-width: 1599.98px) {
  .wrapper-acc-top2__hero {
    right: 0;
    margin: 0 0 0 30px;
    max-width: 43%;
  }
}
@media (min-width: 1600px) {
  .wrapper-acc-top2__hero {
    width: 53%;
    right: -141px;
  }
}
@media (min-width: 1001px) {
  .wrapper-acc-top2__hero {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: absolute;
    bottom: 2%;
    margin: 0;
    max-height: 410px;
    background: radial-gradient(54.26% 161.68% at 0% 16.18%, #00CF9E 0%, #0F8763 100%);
    border-radius: 30px;
  }
  .wrapper-acc-top2__hero--img {
    max-width: 100%;
    border-radius: 30px;
  }
}

.wrapper-sec-top {
  display: flex;
  max-width: 100%;
  width: 100%;
  align-items: center;
  margin: 0 0 20px;
  padding: 0 !important;
}
@media (max-width: 767.98px) {
  .wrapper-sec-top {
    overflow: hidden;
  }
}
@media (min-width: 768px) {
  .wrapper-sec-top {
    min-height: 535px;
    border-bottom: 1px solid #BDBDBD;
  }
}
@media (min-width: 768px) and (max-width: 1599.98px) {
  .wrapper-sec-top {
    margin: 2.5vw 0 0;
    align-items: stretch;
  }
}
@media (max-width: 767.98px) {
  .wrapper-sec-top__header {
    display: flex;
    max-width: 100%;
    width: 100%;
    flex-direction: column;
    align-items: center;
  }
}
@media (min-width: 768px) {
  .wrapper-sec-top__header {
    margin: 40px 4vw 0;
    display: flex;
    max-width: 46%;
    width: 100%;
    flex-direction: column;
    justify-content: center;
  }
}
.wrapper-sec-top__header--title {
  width: 100%;
  margin: 20px auto;
  font-family: Nunito, sans-serif;
  font-size: 40px;
  font-style: normal;
  font-weight: 300;
  line-height: 55px;
  letter-spacing: 0em;
  color: #000;
}
@media (max-width: 767.98px) {
  .wrapper-sec-top__header--title {
    height: 36vw;
    position: relative;
    margin: 0;
    width: 100%;
    padding: 25px 35px;
    font-size: 20px;
    line-height: 27px;
    color: #fff;
  }
  .wrapper-sec-top__header--title:before {
    content: "";
    position: absolute;
    height: 100%;
  }
  .wrapper-sec-top__header--title:before {
    left: 0;
    right: 0;
    top: 0;
    width: 100%;
    z-index: -1;
    background: radial-gradient(54.26% 161.68% at 0% 16.18%, #00CF9E 0%, #019A9A 100%) no-repeat center/cover;
  }
  .wrapper-sec-top__header--title span {
    max-width: 70%;
    display: block;
  }
  .wrapper-sec-top__header--title .title--img {
    width: 42%;
    position: absolute;
    right: -40px;
    top: -10px;
  }
}
@media (min-width: 768px) {
  .wrapper-sec-top__header--title .title--img {
    display: none;
  }
}
.wrapper-sec-top__header--desc {
  font-family: Nunito, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 26px;
  letter-spacing: 0em;
  color: #000;
}
@media (max-width: 767.98px) {
  .wrapper-sec-top__header--desc {
    padding: 35px 0;
    margin: 0 20px;
    max-width: 325px;
    font-size: 12px;
    line-height: 16px;
  }
}
.wrapper-sec-top__header--btns {
  justify-content: space-between;
  margin: 0;
  padding: 0;
}
@media (max-width: 767.98px) {
  .wrapper-sec-top__header--btns {
    display: flex;
    max-width: 100%;
    width: 100%;
    flex-direction: column;
    align-items: center;
  }
  .wrapper-sec-top__header--btns * {
    max-width: 325px;
    width: 100%;
    margin: 0 0 10px !important;
  }
}
@media (min-width: 768px) {
  .wrapper-sec-top__header--btns * {
    width: 45%;
  }
}
.wrapper-sec-top__header .form__main {
  display: flex;
  max-width: 425px;
  width: 100%;
  margin: 0 !important;
  padding: 0;
  flex-direction: column;
  position: relative;
}
@media (max-width: 767.98px) {
  .wrapper-sec-top__header .form__main {
    margin: 80px 0 40px !important;
    display: flex;
    max-width: 330px;
    width: 100%;
  }
}
.wrapper-sec-top__header .form__main input[type=password],
.wrapper-sec-top__header .form__main .main__accept,
.wrapper-sec-top__header .form__main .form__main--check {
  display: none;
}
.wrapper-sec-top__header .form__main input[type=email] {
  border: none;
}
.wrapper-sec-top__header .form__main input[type=submit] {
  position: absolute;
  top: -10px;
  border: none;
}
@media (max-width: 767.98px) {
  .wrapper-sec-top__header .form__main input[type=submit] {
    max-width: 150px;
    font-size: 12px;
    left: 56%;
  }
}
@media (min-width: 768px) {
  .wrapper-sec-top__header .form__main input[type=submit] {
    max-width: 55%;
  }
}
@media (min-width: 768px) and (max-width: 1599.98px) {
  .wrapper-sec-top__header .form__main input[type=submit] {
    left: 20vw;
  }
}
@media (min-width: 1600px) {
  .wrapper-sec-top__header .form__main input[type=submit] {
    left: 85%;
  }
}
.wrapper-sec-top__header .form__main--input {
  width: 100%;
  box-shadow: 0px 0px 30px 0px #00000026;
  font-family: Nunito, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
  background: #FFFFFF;
  height: 55px;
  padding: 0 20px;
  margin: 10px auto 0;
  border-radius: 70px;
  color: #000;
  border: 3px solid #fff;
}
@media (max-width: 767.98px) {
  .wrapper-sec-top__header .form__main--input {
    max-width: 330px;
  }
}
.wrapper-sec-top__header .form__main--input:focus {
  border: 3px solid #019A9A !important;
}
.wrapper-sec-top__header .form__main--check {
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: #000;
  margin: 10px 0;
  text-decoration: underline;
  cursor: pointer;
}
.wrapper-sec-top__header .form__main .main__accept {
  max-width: 350px;
  position: relative;
  width: 100%;
  margin: 5px 0;
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: #000;
  padding: 0 0 0 35px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
@media (max-width: 767.98px) {
  .wrapper-sec-top__header .form__main .main__accept {
    font-size: 12px;
    line-height: 15px;
  }
}
.wrapper-sec-top__header .form__main .main__accept a, .wrapper-sec-top__header .form__main .main__accept span.label--more {
  cursor: pointer;
  font-weight: 500;
  color: #000;
}
.wrapper-sec-top__header .form__main .main__accept--checkbox {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
  margin: 0;
}
.wrapper-sec-top__header .form__main .main__accept input:checked ~ span.checkmark::after {
  display: block;
}
.wrapper-sec-top__header .form__main .main__accept .checkmark {
  background-color: #FFFFFF;
  position: absolute;
  border: 1px solid #000;
  height: 24px;
  width: 24px;
  left: 0;
  top: 5px;
  border-radius: 16px;
}
.wrapper-sec-top__header .form__main .main__accept .checkmark::after {
  content: "";
  display: none;
  position: absolute;
  width: 60%;
  height: 60%;
  margin: auto;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: url(../img/check.svg) no-repeat center/cover;
}
.wrapper-sec-top__header .form__main .main__accept .more--text {
  display: none;
}
.wrapper-sec-top__header .form__main--submit {
  background: radial-gradient(91.66% 306.32% at 0% 16.18%, #01CC9D 0%, #019A9A 100%);
  border: 2px solid #FFFFFF;
  box-sizing: border-box;
  border-radius: 70px;
  font-family: Nunito, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 800;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: center;
  color: #FFFFFF;
  height: 55px;
  width: 100%;
  margin: 20px 0;
  cursor: pointer;
}
.wrapper-sec-top__header .form__main-with-modal {
  display: flex;
  max-width: 425px;
  width: 100%;
  margin: 0 !important;
  padding: 0;
  flex-direction: column;
  position: relative;
}
@media (max-width: 767.98px) {
  .wrapper-sec-top__header .form__main-with-modal {
    margin: 80px 0 40px !important;
    display: flex;
    max-width: 330px;
    width: 100%;
  }
}
.wrapper-sec-top__header .form__main-with-modal input[type=password],
.wrapper-sec-top__header .form__main-with-modal input[type=text],
.wrapper-sec-top__header .form__main-with-modal .btn-open-modal-form,
.wrapper-sec-top__header .form__main-with-modal .main__accept,
.wrapper-sec-top__header .form__main-with-modal .form__main-with-modal--check {
  display: none;
}
.wrapper-sec-top__header .form__main-with-modal input[type=email] {
  border: none;
}
.wrapper-sec-top__header .form__main-with-modal input[type=submit] {
  position: absolute;
  top: 0;
  border: none;
}
@media (max-width: 767.98px) {
  .wrapper-sec-top__header .form__main-with-modal input[type=submit] {
    max-width: 150px;
    font-size: 12px;
    left: 56%;
  }
}
@media (min-width: 768px) {
  .wrapper-sec-top__header .form__main-with-modal input[type=submit] {
    max-width: 55%;
  }
}
@media (min-width: 768px) and (max-width: 1599.98px) {
  .wrapper-sec-top__header .form__main-with-modal input[type=submit] {
    left: 20vw;
  }
}
@media (min-width: 1600px) {
  .wrapper-sec-top__header .form__main-with-modal input[type=submit] {
    left: 85%;
  }
}
.wrapper-sec-top__header .form__main-with-modal--input {
  width: 100%;
  box-shadow: 0px 0px 30px 0px #00000026;
  font-family: Nunito, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
  background: #FFFFFF;
  height: 55px;
  padding: 0 20px;
  margin: 20px auto 0;
  border-radius: 70px;
  color: #000;
  border: 3px solid #fff;
}
@media (max-width: 767.98px) {
  .wrapper-sec-top__header .form__main-with-modal--input {
    max-width: 330px;
  }
}
.wrapper-sec-top__header .form__main-with-modal--input:focus {
  border: 3px solid #019A9A !important;
}
.wrapper-sec-top__header .form__main-with-modal--check {
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: #000;
  margin: 10px 0;
  text-decoration: underline;
  cursor: pointer;
}
.wrapper-sec-top__header .form__main-with-modal .main__accept {
  position: relative;
  width: 100%;
  margin: 5px 0;
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: #000;
  padding: 0 0 0 35px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
@media (max-width: 767.98px) {
  .wrapper-sec-top__header .form__main-with-modal .main__accept {
    font-size: 12px;
    line-height: 15px;
  }
}
.wrapper-sec-top__header .form__main-with-modal .main__accept a, .wrapper-sec-top__header .form__main-with-modal .main__accept span.label--more {
  cursor: pointer;
  font-weight: 500;
  color: #000;
}
.wrapper-sec-top__header .form__main-with-modal .main__accept--checkbox {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
  margin: 0;
}
.wrapper-sec-top__header .form__main-with-modal .main__accept input:checked ~ span.checkmark::after {
  display: block;
}
.wrapper-sec-top__header .form__main-with-modal .main__accept .checkmark {
  background-color: #FFFFFF;
  position: absolute;
  border: 1px solid #000;
  height: 24px;
  width: 24px;
  left: 0;
  top: 5px;
  border-radius: 16px;
}
.wrapper-sec-top__header .form__main-with-modal .main__accept .checkmark::after {
  content: "";
  display: none;
  position: absolute;
  width: 60%;
  height: 60%;
  margin: auto;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: url(../img/check.svg) no-repeat center/cover;
}
.wrapper-sec-top__header .form__main-with-modal .main__accept .more--text {
  display: none;
}
.wrapper-sec-top__header .form__main-with-modal--submit {
  background: radial-gradient(91.66% 306.32% at 0% 16.18%, #01CC9D 0%, #019A9A 100%);
  border: 2px solid #FFFFFF;
  box-sizing: border-box;
  border-radius: 70px;
  font-family: Nunito, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 800;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: center;
  color: #FFFFFF;
  height: 55px;
  width: 100%;
  margin: 20px 0;
  cursor: pointer;
}
@media (max-width: 767.98px) {
  .wrapper-sec-top__hero {
    display: none;
  }
}
@media (min-width: 768px) and (max-width: 1599.98px) {
  .wrapper-sec-top__hero {
    right: 0;
    width: 48%;
  }
  .wrapper-sec-top__hero--img {
    max-width: 100%;
    margin-top: 9vw;
  }
}
@media (min-width: 1600px) {
  .wrapper-sec-top__hero {
    top: 60px;
    width: 53%;
    right: -141px;
  }
  .wrapper-sec-top__hero--img {
    max-width: 100%;
    margin-top: 22%;
  }
}
@media (min-width: 768px) {
  .wrapper-sec-top__hero {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: absolute;
    background: radial-gradient(54.26% 161.68% at 0% 16.18%, #00CF9E 0%, #019A9A 100%) no-repeat center/cover;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    margin: 0;
    max-height: 350px;
  }
}

.wrapper-ent-top {
  display: flex;
  max-width: 100%;
  width: 100%;
  margin: 0;
  padding: 0 !important;
  align-items: flex-end;
}
@media (max-width: 767.98px) {
  .wrapper-ent-top {
    overflow: hidden;
  }
}
@media (min-width: 768px) {
  .wrapper-ent-top {
    min-height: 445px;
  }
}
@media (min-width: 768px) and (max-width: 1299.98px) {
  .wrapper-ent-top {
    margin: 2.5vw 0 0;
    padding: 0 0 0px !important;
  }
}
@media (max-width: 767.98px) {
  .wrapper-ent-top__header {
    display: flex;
    max-width: 100%;
    width: 100%;
    flex-direction: column;
    align-items: center;
  }
}
@media (min-width: 768px) {
  .wrapper-ent-top__header {
    margin: 0px 4vw 40px;
    display: flex;
    max-width: 46%;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-self: center;
  }
}
.wrapper-ent-top__header-inner {
  width: 100%;
  position: relative;
  z-index: 10;
}
@media (max-width: 767.98px) {
  .wrapper-ent-top__header-inner {
    min-height: 36vw;
    padding-bottom: 10px;
  }
  .wrapper-ent-top__header-inner:before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    z-index: -1;
  }
}
.wrapper-ent-top__header-inner.bg-h-green .title--img {
  width: 67%;
  position: absolute;
  right: -40px;
}
.wrapper-ent-top__header-inner.bg-h-green .title--img.bottom-2 {
  bottom: -8vw;
}
.wrapper-ent-top__header-inner.bg-h-green .title--img.stuck-bottom {
  bottom: 0;
}
.wrapper-ent-top__header-inner.bg-h-green .title--imgBottom-3 {
  width: 80px;
  position: relative;
  top: -60px;
  left: 18px;
  display: none;
}
@media (max-width: 768px) {
  .wrapper-ent-top__header-inner.bg-h-green .title--imgBottom-3 {
    display: block;
  }
}
@media (min-width: 768px) {
  .wrapper-ent-top__header-inner.bg-h-green .title--img {
    display: none;
  }
}
@media (max-width: 767.98px) {
  .wrapper-ent-top__header-inner.bg-h-green:before {
    background: radial-gradient(54.26% 161.68% at 0% 16.18%, #00CF9E 0%, #019A9A 100%) no-repeat center/cover;
  }
}
.wrapper-ent-top__header-inner.bg-h-blue .title--img {
  width: 67%;
  position: absolute;
  right: -40px;
  bottom: -8vw;
}
@media (min-width: 768px) {
  .wrapper-ent-top__header-inner.bg-h-blue .title--img {
    display: none;
  }
}
@media (max-width: 767.98px) {
  .wrapper-ent-top__header-inner.bg-h-blue:before {
    background: radial-gradient(54.26% 161.68% at 0% 16.18%, #019A9A 0%, #0083CC 100%) no-repeat center/cover;
  }
}
.wrapper-ent-top__header--title {
  width: 100%;
  margin: 20px 0 10px;
  font-family: Nunito, sans-serif;
  font-size: 30px;
  font-style: normal;
  font-weight: normal;
  line-height: 40px;
  letter-spacing: 0em;
  color: #000;
}
@media (max-width: 767.98px) {
  .wrapper-ent-top__header--title {
    position: relative;
    margin: 0;
    width: 100%;
    padding: 25px 27px 10px;
    font-size: 20px;
    line-height: 27px;
    color: #fff;
  }
  .wrapper-ent-top__header--title:before {
    content: "";
    position: absolute;
    height: 100%;
  }
}
.wrapper-ent-top__header--title ul {
  font-family: Nunito, sans-serif;
  font-size: 16px;
  font-weight: 300;
  line-height: 1.4em;
  font-style: normal;
  margin: 5px 0 0;
  padding-left: 28px;
  list-style-image: url(../img/Vector1.png);
}
@media (max-width: 767.98px) {
  .wrapper-ent-top__header--title ul {
    font-size: 12px;
    line-height: 16px;
    filter: brightness(1.5);
  }
}
.wrapper-ent-top__header--desc {
  font-family: Nunito, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 26px;
  letter-spacing: 0em;
  color: #000;
  margin-top: 0;
}
@media (max-width: 767.98px) {
  .wrapper-ent-top__header--desc {
    padding: 10px 27px 70px;
    padding-right: 39%;
    margin: 0;
    max-width: none;
    font-size: 12px;
    line-height: 16px;
    color: #fff;
  }
}
@media (max-width: 367.98px) {
  .wrapper-ent-top__header--desc {
    padding-right: 15%;
  }
}
.wrapper-ent-top__header--btns {
  justify-content: space-between;
  margin: 0;
  padding: 0;
}
@media (max-width: 767.98px) {
  .wrapper-ent-top__header--btns {
    display: flex;
    max-width: 100%;
    width: 100%;
    flex-direction: column;
    align-items: center;
  }
  .wrapper-ent-top__header--btns * {
    max-width: 325px;
    width: 100%;
    margin: 0 0 10px !important;
  }
}
@media (min-width: 768px) {
  .wrapper-ent-top__header--btns * {
    width: 45%;
  }
}
.wrapper-ent-top__header .form__main {
  display: flex;
  max-width: 525px;
  width: 100%;
  margin: 0 !important;
  padding: 0;
  flex-direction: column;
  position: relative;
  z-index: 5;
}
@media (max-width: 767.98px) {
  .wrapper-ent-top__header .form__main {
    margin: 20px auto 40px !important;
    padding-left: 25px;
    padding-right: 25px;
  }
}
.wrapper-ent-top__header .form__main:not(.form-top--expanded) input[type=submit] {
  width: auto;
}
.wrapper-ent-top__header .form__main.form-top--expanded input[type=submit] {
  margin-top: 10px;
  position: static;
}
.wrapper-ent-top__header .form__main input[type=password],
.wrapper-ent-top__header .form__main .main__accept,
.wrapper-ent-top__header .form__main .form__main--check {
  display: none;
}
.wrapper-ent-top__header .form__main input[type=password],
.wrapper-ent-top__header .form__main input[type=email] {
  border: 1px solid #eee;
}
.wrapper-ent-top__header .form__main input[type=password].error,
.wrapper-ent-top__header .form__main input[type=email].error {
  border-color: red;
}
.wrapper-ent-top__header .form__main input[type=submit] {
  position: absolute;
  top: -10px;
  border: none;
  width: 100%;
  right: 25px;
  padding-right: 1em;
  padding-left: 1em;
}
@media (min-width: 768px) {
  .wrapper-ent-top__header .form__main input[type=submit] {
    right: -1px;
  }
}
.wrapper-ent-top__header .form__main--input {
  width: 100%;
  box-shadow: 7px 7px 35px -5px #00000063;
  font-family: Nunito, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
  background: #FFFFFF;
  height: 55px;
  padding: 0 20px;
  margin: 10px auto 0;
  border-radius: 70px;
  color: #000;
  border: 3px solid #fff;
}
.wrapper-ent-top__header .form__main--input:focus-visible, .wrapper-ent-top__header .form__main--input:focus {
  border: 3px solid #019A9A;
  outline: none;
}
.wrapper-ent-top__header .form__main--check {
  font-family: Nunito, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: #767676;
  margin: 0 0 10px;
  text-decoration: underline;
  cursor: pointer;
}
.wrapper-ent-top__header .form__main--check.error {
  border-color: red;
}
.wrapper-ent-top__header .form__main .main__accept {
  max-width: 350px;
  position: relative;
  width: 100%;
  margin: 5px 0;
  font-family: Nunito, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: #767676;
  padding: 0 0 0 35px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
@media (max-width: 767.98px) {
  .wrapper-ent-top__header .form__main .main__accept {
    font-size: 12px;
    line-height: 15px;
  }
}
.wrapper-ent-top__header .form__main .main__accept a, .wrapper-ent-top__header .form__main .main__accept span.label--more {
  cursor: pointer;
  font-weight: 500;
  color: #000;
}
.wrapper-ent-top__header .form__main .main__accept--checkbox {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
  margin: 0;
}
.wrapper-ent-top__header .form__main .main__accept input:checked ~ span.checkmark::after {
  display: block;
}
.wrapper-ent-top__header .form__main .main__accept input.error ~ span.checkmark {
  border-color: red;
}
.wrapper-ent-top__header .form__main .main__accept .checkmark {
  background-color: #FFFFFF;
  position: absolute;
  border: 1px solid #019A9A;
  height: 20px;
  width: 20px;
  left: 0;
  top: 1px;
  border-radius: 16px;
}
.wrapper-ent-top__header .form__main .main__accept .checkmark::after {
  content: "";
  display: none;
  position: absolute;
  width: 60%;
  height: 60%;
  margin: auto;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: url(../img/check.svg) no-repeat center/cover;
}
.wrapper-ent-top__header .form__main .main__accept .more--text {
  display: none;
}
.wrapper-ent-top__header .form__main--submit {
  border: 2px solid #FFFFFF;
  box-sizing: border-box;
  border-radius: 70px;
  font-family: Nunito, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: center;
  color: #FFFFFF;
  height: 55px;
  width: 100%;
  margin: 20px 0;
  cursor: pointer;
  transition: 333ms;
}
.wrapper-ent-top__header .form__main--submit:not(.btn-blue):not(.btn-green) {
  background: radial-gradient(91.66% 306.32% at 0% 16.18%, #01CC9D 0%, #019A9A 100%);
}
@media (max-width: 786px) {
  .wrapper-ent-top__header .form__main--submit {
    font-size: 14px;
  }
}
.wrapper-ent-top__header .form__main--submit:hover {
  filter: brightness(1.1);
}
.wrapper-ent-top__header .form__main-with-modal {
  display: flex;
  max-width: 525px;
  width: 100%;
  margin: 0 !important;
  padding: 0;
  flex-direction: column;
  position: relative;
  z-index: 5;
}
@media (max-width: 767.98px) {
  .wrapper-ent-top__header .form__main-with-modal {
    margin: 20px auto 40px !important;
    padding-left: 25px;
    padding-right: 25px;
  }
}
.wrapper-ent-top__header .form__main-with-modal:not(.form-top--expanded) input[type=submit] {
  width: auto !important;
  padding: 0 10%;
}
.wrapper-ent-top__header .form__main-with-modal.form-top--expanded input[type=submit] {
  margin-top: 20px;
  position: static;
}
.wrapper-ent-top__header .form__main-with-modal.form-top--expanded .btn-open-modal-form {
  display: block;
}
.wrapper-ent-top__header .form__main-with-modal input[type=password],
.wrapper-ent-top__header .form__main-with-modal input[type=text],
.wrapper-ent-top__header .form__main-with-modal .btn-open-modal-form,
.wrapper-ent-top__header .form__main-with-modal .main__accept,
.wrapper-ent-top__header .form__main-with-modal .form__main-with-modal--check {
  display: none;
}
.wrapper-ent-top__header .form__main-with-modal input[type=password],
.wrapper-ent-top__header .form__main-with-modal input[type=text],
.wrapper-ent-top__header .form__main-with-modal input[type=email] {
  border: 1px solid #eee;
}
.wrapper-ent-top__header .form__main-with-modal input[type=password].error,
.wrapper-ent-top__header .form__main-with-modal input[type=text].error,
.wrapper-ent-top__header .form__main-with-modal input[type=email].error {
  border-color: red;
}
.wrapper-ent-top__header .form__main-with-modal input[type=submit] {
  position: absolute;
  top: 0;
  border: none;
  width: 100%;
  right: 25px;
  padding-right: 1em;
  padding-left: 1em;
}
@media (min-width: 768px) {
  .wrapper-ent-top__header .form__main-with-modal input[type=submit] {
    right: -1px;
  }
}
.wrapper-ent-top__header .form__main-with-modal--input {
  width: 100%;
  box-shadow: 7px 7px 35px -5px #00000026;
  font-family: Nunito, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
  background: #FFFFFF;
  height: 55px;
  padding: 0 20px;
  margin: 20px auto 0;
  border-radius: 70px;
  color: #000;
  border: 3px solid #fff;
}
.wrapper-ent-top__header .form__main-with-modal--input:focus-visible, .wrapper-ent-top__header .form__main-with-modal--input:focus {
  border: 3px solid #019A9A;
  outline: none;
}
.wrapper-ent-top__header .form__main-with-modal--check {
  font-family: Nunito, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: #767676;
  margin: 0 0 10px;
  text-decoration: underline;
  cursor: pointer;
}
.wrapper-ent-top__header .form__main-with-modal--check.error {
  border-color: red;
}
.wrapper-ent-top__header .form__main-with-modal .main__accept {
  position: relative;
  width: 100%;
  margin: 5px 0;
  font-family: Nunito, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: #767676;
  padding: 0 0 0 35px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
@media (max-width: 767.98px) {
  .wrapper-ent-top__header .form__main-with-modal .main__accept {
    font-size: 12px;
    line-height: 15px;
  }
}
.wrapper-ent-top__header .form__main-with-modal .main__accept a, .wrapper-ent-top__header .form__main-with-modal .main__accept span.label--more {
  cursor: pointer;
  font-weight: 500;
  color: #000;
}
.wrapper-ent-top__header .form__main-with-modal .main__accept--checkbox {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
  margin: 0;
}
.wrapper-ent-top__header .form__main-with-modal .main__accept input:checked ~ span.checkmark::after {
  display: block;
}
.wrapper-ent-top__header .form__main-with-modal .main__accept input.error ~ span.checkmark {
  border-color: red;
}
.wrapper-ent-top__header .form__main-with-modal .main__accept .checkmark {
  background-color: #FFFFFF;
  position: absolute;
  border: 1px solid #019A9A;
  height: 20px;
  width: 20px;
  left: 0;
  top: 1px;
  border-radius: 16px;
}
.wrapper-ent-top__header .form__main-with-modal .main__accept .checkmark::after {
  content: "";
  display: none;
  position: absolute;
  width: 60%;
  height: 60%;
  margin: auto;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: url(../img/check.svg) no-repeat center/cover;
}
.wrapper-ent-top__header .form__main-with-modal .main__accept .more--text {
  display: none;
}
.wrapper-ent-top__header .form__main-with-modal--submit {
  border: 2px solid #FFFFFF;
  box-sizing: border-box;
  border-radius: 70px;
  font-family: Nunito, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: center;
  color: #FFFFFF;
  height: 55px;
  width: 100%;
  margin: 20px 0;
  cursor: pointer;
  transition: 333ms;
  font-size: 14px;
}
.wrapper-ent-top__header .form__main-with-modal--submit:not(.btn-blue):not(.btn-green) {
  background: radial-gradient(91.66% 306.32% at 0% 16.18%, #01CC9D 0%, #019A9A 100%);
}
.wrapper-ent-top__header .form__main-with-modal--submit:hover {
  filter: brightness(1.1);
}
.wrapper-ent-top__hero-inner .wrapper-ent-top__hero--img.radius {
  border-radius: 20px;
}
.wrapper-ent-top__hero-inner .wrapper-ent-top__hero--img.contain {
  width: 100%;
}
.wrapper-ent-top__hero-inner.bg-h-green::before {
  content: "";
  border-radius: 20px;
  mix-blend-mode: multiply;
  background: radial-gradient(54.26% 161.68% at 0% 16.18%, #00CF9E 0%, #019A9A 100%) no-repeat center/cover;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 1;
}
.wrapper-ent-top__hero {
  padding-bottom: 25%;
  align-items: flex-end;
  margin-bottom: 7.5%;
  flex-shrink: 0;
  transform: translateY(-19.1%);
}
.wrapper-ent-top__hero--mof {
  position: absolute;
  z-index: 100;
  left: -64px;
  top: 10px;
  width: 139px;
}
@media (max-width: 991px) {
  .wrapper-ent-top__hero--mof {
    transform: scale(0.8);
    left: -10px;
    top: -10px;
  }
}
.wrapper-ent-top__hero--img {
  position: absolute;
  bottom: -49.4%;
  right: 0;
  width: 120%;
}
.wrapper-ent-top__hero--img.bottom-1 {
  bottom: -19.5%;
}
.wrapper-ent-top__hero--img.stuck-bottom {
  bottom: 0px;
}
@media (max-width: 767.98px) {
  .wrapper-ent-top__hero {
    display: none;
  }
}
@media (min-width: 768px) and (max-width: 1599.98px) {
  .wrapper-ent-top__hero {
    width: 48%;
  }
}
@media (min-width: 1600px) {
  .wrapper-ent-top__hero {
    width: 48%;
  }
}
@media (min-width: 768px) {
  .wrapper-ent-top__hero {
    display: flex;
    justify-content: flex-end;
    position: relative;
    bottom: 0;
    right: 0;
    background: radial-gradient(54.26% 161.68% at 0% 16.18%, #00CF9E 0%, #019A9A 100%) no-repeat center/cover;
    border-radius: 20px;
    margin: 0;
  }
}

.wrapper-acc-price-top {
  display: flex;
  max-width: 100%;
  width: 100%;
  align-items: center;
  margin: 0 0 20px;
  padding: 0 !important;
  border-bottom: 1px solid #BDBDBD;
}
@media (min-width: 768px) {
  .wrapper-acc-price-top {
    min-height: 535px;
  }
}
@media (max-width: 767.98px) {
  .wrapper-acc-price-top__header {
    display: flex;
    max-width: 100%;
    width: 100%;
    flex-direction: column;
    align-items: center;
  }
}
@media (min-width: 768px) {
  .wrapper-acc-price-top__header {
    margin-left: 5%;
    display: block;
    max-width: 37%;
    width: 100%;
  }
}
.wrapper-acc-price-top__header--title {
  width: 100%;
  margin: 20px auto;
  font-family: Nunito, sans-serif;
  font-size: 40px;
  font-style: normal;
  font-weight: 300;
  line-height: 55px;
  letter-spacing: 0em;
  color: #000;
  display: block;
}
.wrapper-acc-price-top__header--title p {
  margin: 0;
}
@media (max-width: 767.98px) {
  .wrapper-acc-price-top__header--title {
    height: 46vw;
    position: relative;
    margin: 0;
    width: 100%;
    padding: 35px;
    font-size: 20px;
    line-height: 27px;
    color: #fff;
  }
  .wrapper-acc-price-top__header--title p {
    max-width: 65%;
  }
  .wrapper-acc-price-top__header--title:after, .wrapper-acc-price-top__header--title:before {
    content: "";
    position: absolute;
    height: 100%;
  }
  .wrapper-acc-price-top__header--title:before {
    left: 0;
    right: 0;
    top: 0;
    width: 100%;
    z-index: -1;
    background: radial-gradient(43.63% 128.84% at 10.62% 27.13%, #019A9A 0%, #0083CC 100%);
  }
  .wrapper-acc-price-top__header--title:after {
    right: 0;
    bottom: 0;
    width: 50%;
    background: url(../img/about.png) no-repeat right bottom/cover;
  }
}
.wrapper-acc-price-top__header--desc {
  max-width: 455px;
  font-family: Nunito, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 26px;
  letter-spacing: 0em;
  color: #000;
}
@media (max-width: 767.98px) {
  .wrapper-acc-price-top__header--desc {
    padding: 35px 0;
    margin: 0 20px;
    max-width: 325px;
    font-size: 12px;
    line-height: 16px;
  }
}
@media (max-width: 767.98px) {
  .wrapper-acc-price-top__hero {
    display: none;
  }
}
@media (min-width: 768px) and (max-width: 1600px) {
  .wrapper-acc-price-top__hero {
    right: 0;
    width: 50%;
  }
}
@media (min-width: 1600px) {
  .wrapper-acc-price-top__hero {
    top: 60px;
    width: 65%;
    right: -150px;
  }
}
@media (min-width: 768px) {
  .wrapper-acc-price-top__hero {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: absolute;
    background: radial-gradient(54.26% 161.68% at 0% 16.18%, #00CF9E 0%, #019A9A 100%) no-repeat center/cover;
    mix-blend-mode: multiply;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    margin: 0;
    max-height: 475px;
  }
  .wrapper-acc-price-top__hero:after {
    content: "";
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url(../img/about.jpg) no-repeat center/cover;
    mix-blend-mode: multiply;
    z-index: -1;
  }
  .wrapper-acc-price-top__hero--img {
    max-width: 100%;
  }
}

.wrapper-vid {
  margin: 0;
  padding: 0;
  width: 100%;
}
@media (max-width: 767.98px) {
  .wrapper-vid {
    display: none;
  }
}
.wrapper-vid video {
  width: 100%;
  display: block;
  margin: auto;
  background: #eee;
}

.wrapper-icons.wrapper-icons--light-bg .wrapper-icons__box .icons--dot {
  background-color: #000;
  color: #000;
}
.wrapper-icons.wrapper-icons--light-bg .wrapper-icons__box .icons--dot:before {
  background-color: #000;
}
.wrapper-icons.wrapper-icons--light-bg .wrapper-icons__box .icons--dot:after {
  color: #000;
}

.wrapper-icons {
  padding-top: 20px;
  display: flex;
  max-width: 1300px;
  width: 100%;
  justify-content: center;
  margin: 0 auto !important;
  flex-wrap: wrap;
  z-index: 1;
}
@media (max-width: 767.98px) {
  .wrapper-icons {
    flex-direction: column;
    align-items: center;
  }
}
.wrapper-icons--title {
  width: 100%;
  font-family: Nunito, sans-serif;
  font-size: 44px;
  font-style: normal;
  font-weight: 300;
  line-height: 49px;
  letter-spacing: 0em;
  margin: 40px 0 20px;
  text-align: center;
  color: #000;
}
@media (max-width: 767.98px) {
  .wrapper-icons--title {
    font-size: 24px;
    line-height: 1.4em;
    font-weight: 300;
    margin: 20px 0;
  }
}
.wrapper-icons--subtitle {
  width: 100%;
  font-family: Nunito, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 300;
  line-height: 49px;
  letter-spacing: 0em;
  margin: 0 0 40px;
  text-align: center;
  color: #000;
}
@media (max-width: 767.98px) {
  .wrapper-icons--subtitle {
    font-size: 16px;
    line-height: 25px;
    font-weight: 300;
    margin: 0 0 40px;
  }
}
.wrapper-icons--desc {
  font-family: Montserrat, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  margin: 0 0 40px;
  color: #000;
  width: 100%;
}
@media (max-width: 767.98px) {
  .wrapper-icons--desc {
    font-size: 12px;
  }
}
.wrapper-icons__box {
  margin: 0 0 20px;
  flex-direction: column;
  align-items: center;
  position: relative;
}
@media (max-width: 767.98px) {
  .wrapper-icons__box {
    display: flex;
    max-width: 380px;
    width: 100%;
  }
  .wrapper-icons__box:last-of-type .icons--dot {
    display: none;
  }
}
@media (min-width: 768px) {
  .wrapper-icons__box {
    display: flex;
    max-width: 25%;
    width: 100%;
  }
  .wrapper-icons__box:first-of-type .icons--dot:after {
    color: #fff;
    display: none;
  }
  .wrapper-icons__box:first-of-type .icons--dot:before {
    background-color: #fff;
    display: none;
  }
}
.wrapper-icons__box--ico {
  align-items: center;
  justify-content: center;
  align-content: center;
  height: 75px;
  margin: 0;
  color: #000;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 0 0 12px;
}
@media (max-width: 767.98px) {
  .wrapper-icons__box--ico {
    display: flex;
    max-width: 85px;
    width: 100%;
    order: 1;
  }
}
@media (min-width: 768px) {
  .wrapper-icons__box--ico {
    display: flex;
    max-width: 100%;
    width: 100%;
  }
}
.wrapper-icons__box--ico img {
  max-height: 100%;
  max-width: 100%;
}
.wrapper-icons__box .icons--dot {
  height: 11px;
  width: 11px;
  background-color: #fff;
  border-radius: 100%;
  margin: 0 auto;
  position: relative;
}
@media (max-width: 767.98px) {
  .wrapper-icons__box .icons--dot {
    order: 4;
    margin: 0 0 50px 0;
  }
  .wrapper-icons__box .icons--dot:before {
    content: "";
    width: 1px;
    height: 45px;
    background-color: #fff;
    position: absolute;
    right: 5px;
    top: 25px;
  }
  .wrapper-icons__box .icons--dot:after {
    position: absolute;
    content: "ᐯ";
    color: #fff;
    top: 60px;
    right: 1.5px;
    font-size: 10px;
  }
}
@media (min-width: 768px) {
  .wrapper-icons__box .icons--dot:before {
    content: "";
    max-width: 280px;
    width: 19vw;
    height: 1px;
    background-color: #fff;
    position: absolute;
    right: 28px;
    top: 5px;
  }
  .wrapper-icons__box .icons--dot:after {
    position: absolute;
    content: "ᐳ";
    top: -2px;
    right: 25px;
    font-size: 10px;
  }
}
.wrapper-icons__box .icons--last-dot {
  height: 11px;
  width: 11px;
  border-radius: 100%;
  margin: 0 auto;
  position: absolute;
  bottom: 0px;
}
@media (max-width: 767.98px) {
  .wrapper-icons__box .icons--last-dot {
    order: 4;
    margin: 0 0 50px 0;
  }
  .wrapper-icons__box .icons--last-dot:before {
    display: none;
  }
}
@media (min-width: 768px) {
  .wrapper-icons__box .icons--last-dot:before {
    content: "";
    max-width: 280px;
    width: 19vw;
    height: 1px;
    background-color: #a4a4a4;
    position: absolute;
    left: 23px;
    top: 5px;
  }
}
.wrapper-icons__box--title {
  font-family: Montserrat, sans-serif;
  font-size: 16px;
  font-style: normal;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  font-weight: 700;
  color: #000;
}
@media (max-width: 767.98px) {
  .wrapper-icons__box--title {
    font-size: 14px;
    order: 2;
  }
}
.wrapper-icons__box--desc {
  font-family: Montserrat, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  margin: 0 0 10px;
  color: #000;
}
@media (max-width: 767.98px) {
  .wrapper-icons__box--desc {
    font-size: 12px;
    order: 3;
  }
}
@media (min-width: 768px) {
  .wrapper-icons__box--desc {
    max-width: 80%;
  }
}
.wrapper-icons__box--desc strong {
  display: block;
  margin: 0 0 5px;
  font-weight: 700;
}
@media (max-width: 767.98px) {
  .wrapper-icons__box--desc strong {
    font-size: 14px;
  }
}
.wrapper-icons--btns {
  justify-content: space-between;
  margin: 0;
  padding: 0;
}
@media (max-width: 767.98px) {
  .wrapper-icons--btns {
    display: flex;
    max-width: 100%;
    width: 100%;
    flex-direction: column;
    align-items: center;
  }
  .wrapper-icons--btns * {
    max-width: 325px;
    width: 100%;
    margin: 0 0 10px !important;
  }
}

.wrapper-boxes {
  display: flex;
  max-width: 1200px;
  width: 100%;
  justify-content: center;
  margin: 0 auto !important;
  padding: 0 20px;
  flex-wrap: wrap;
}
.wrapper-boxes .boxes__head {
  display: block;
  max-width: 100%;
  width: 100%;
  margin: 0 auto !important;
  padding: 0;
}
.wrapper-boxes .boxes__head--title {
  font-family: Nunito, sans-serif;
  font-size: 36px;
  font-style: normal;
  font-weight: 300;
  line-height: 49px;
  letter-spacing: 0em;
  text-align: center;
  margin: 0 auto 40px;
  max-width: 890px;
}
@media (max-width: 767.98px) {
  .wrapper-boxes .boxes__head--title {
    max-width: 335px;
    font-size: 18px;
    line-height: 25px;
    font-weight: 300;
    margin: 20px auto 40px;
  }
}
.wrapper-boxes .boxes-main {
  display: flex;
  max-width: 100%;
  width: 100%;
  margin: 0 auto !important;
  padding: 0;
  flex-wrap: wrap;
}
@media (max-width: 767.98px) {
  .wrapper-boxes .boxes-main {
    flex-direction: column;
    align-items: center;
  }
}
@media (min-width: 768px) {
  .wrapper-boxes .boxes-main {
    justify-content: space-between;
  }
}
.wrapper-boxes .boxes-main__post {
  display: flex;
  max-width: 24%;
  width: 100%;
  margin: 0 0 20px;
}
@media (max-width: 767.98px) {
  .wrapper-boxes .boxes-main__post {
    display: flex;
    max-width: 380px;
    width: 100%;
    border-bottom: 2px solid #D8D8D8;
  }
}
@media (min-width: 768px) {
  .wrapper-boxes .boxes-main__post {
    flex-direction: column;
  }
}
.wrapper-boxes .boxes-main__post--ico {
  align-items: center;
  height: 75px;
  margin: 0 0 15px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 0 0 15px;
}
@media (max-width: 767.98px) {
  .wrapper-boxes .boxes-main__post--ico {
    display: flex;
    max-width: 85px;
    width: 100%;
  }
}
@media (min-width: 768px) {
  .wrapper-boxes .boxes-main__post--ico {
    display: flex;
    max-width: 100%;
    width: 100%;
    border-bottom: 2px solid #D8D8D8;
  }
}
.wrapper-boxes .boxes-main__post--ico img {
  max-height: 100%;
  max-width: 100%;
}
.wrapper-boxes .boxes-main__post--title {
  font-family: Montserrat, sans-serif;
  font-size: 16px;
  font-style: normal;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}
@media (max-width: 767.98px) {
  .wrapper-boxes .boxes-main__post--title {
    font-size: 14px;
  }
}
.wrapper-boxes .boxes-main__post--desc {
  font-family: Montserrat, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  margin: 0 0 20px;
}
@media (max-width: 767.98px) {
  .wrapper-boxes .boxes-main__post--desc {
    font-size: 12px;
  }
}
.wrapper-boxes .boxes-main__post--desc strong {
  display: block;
  margin: 0 0 5px;
  font-weight: 700;
}
@media (max-width: 767.98px) {
  .wrapper-boxes .boxes-main__post--desc strong {
    font-size: 14px;
  }
}

.wrapper-imgbox {
  display: flex;
  max-width: 100%;
  width: 100%;
  padding: 0 20px;
}
@media (min-width: 768px) {
  .wrapper-imgbox {
    margin: 0 0 20px;
    min-height: 440px;
  }
  .wrapper-imgbox.flex-end {
    justify-content: flex-end;
    padding: 0 !important;
  }
  .wrapper-imgbox.flex-end article {
    margin-right: 5vw !important;
  }
  .wrapper-imgbox.margin-auto-top {
    margin: -50px 0 !important;
  }
  .wrapper-imgbox.margin-auto-top figure {
    margin: auto 0;
  }
  .wrapper-imgbox.margin-auto-top article {
    margin: auto 0 auto 5vw;
  }
}
@media (max-width: 767.98px) {
  .wrapper-imgbox {
    flex-wrap: wrap;
    margin: 20px 0 20px;
  }
  .wrapper-imgbox.new-order figure {
    order: 1;
    height: 250px;
    top: 0;
  }
  .wrapper-imgbox.new-order article {
    order: 2;
  }
  .wrapper-imgbox.new-order article .wrapper-imgbox__header--title {
    margin: 10px 0px 10px;
  }
}
.wrapper-imgbox__header {
  margin: auto;
  height: 100%;
  position: relative;
  z-index: 3;
}
@media (max-width: 767.98px) {
  .wrapper-imgbox__header {
    display: flex;
    max-width: 100%;
    width: 100%;
    flex-direction: column;
    align-items: center;
    order: 2;
  }
}
@media (min-width: 768px) {
  .wrapper-imgbox__header {
    padding: 0 20px 0 20px;
    display: block;
    max-width: 45%;
    width: 100%;
  }
}
.wrapper-imgbox__header--title {
  width: 100%;
  margin: 20px auto;
  font-family: Nunito, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0em;
  color: #000;
}
@media (min-width: 768px) {
  .wrapper-imgbox__header--title.bigger {
    font-size: 40px;
    line-height: 55px;
  }
}
@media (max-width: 767.98px) {
  .wrapper-imgbox__header--title {
    padding: 0;
    margin: 0 0px 30px;
    max-width: 100%;
    font-size: 24px;
    line-height: 1.3em;
  }
}
.wrapper-imgbox__header--desc, .wrapper-imgbox__header--list {
  max-width: 455px;
  font-family: Nunito, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  letter-spacing: 0em;
  color: #000;
}
@media (max-width: 767.98px) {
  .wrapper-imgbox__header--desc, .wrapper-imgbox__header--list {
    padding: 0;
    margin: 0 0px 20px;
    max-width: 100%;
    width: 100%;
    font-size: 14px;
    line-height: 20px;
  }
}
.wrapper-imgbox__header--desc ul, .wrapper-imgbox__header--desc ol, .wrapper-imgbox__header--list ul, .wrapper-imgbox__header--list ol {
  text-align: left !important;
  padding: 0 0 0 20px;
  margin-top: 0;
}
.wrapper-imgbox__header--list {
  padding: 0 0 0 20px;
}
.wrapper-imgbox__header--btns {
  justify-content: space-between;
  margin: 0;
  padding: 0;
}
@media (min-width: 768px) {
  .wrapper-imgbox__header--btns {
    width: 120%;
  }
}
@media (max-width: 767.98px) {
  .wrapper-imgbox__header--btns {
    display: flex;
    max-width: 100%;
    width: 100%;
    flex-direction: column;
    align-items: center;
  }
  .wrapper-imgbox__header--btns * {
    max-width: none;
    width: 100%;
    margin: 0 0 10px !important;
  }
}
.wrapper-imgbox__hero {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: auto;
  overflow: visible;
  position: relative;
  height: 100%;
  width: 50%;
}
.wrapper-imgbox__hero--img {
  max-width: 100%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}
@media (max-width: 767.98px) {
  .wrapper-imgbox__hero {
    order: 1;
    width: 100%;
    max-width: 335px;
    top: 0px;
    position: relative;
    padding-bottom: 80%;
  }
}
@media (min-width: 1281.98px) {
  .wrapper-imgbox__hero.margin-left {
    margin: auto -40px;
  }
  .wrapper-imgbox__hero.margin-right {
    margin: auto -40px;
  }
}

.wrapper-occasion {
  display: flex;
  max-width: 960px;
  width: 100%;
  margin: 0 auto;
  padding: 30px 20px;
}
@media (max-width: 767.98px) {
  .wrapper-occasion {
    flex-direction: column;
    align-items: center;
  }
}
@media (min-width: 768px) {
  .wrapper-occasion {
    flex-wrap: wrap;
    justify-content: space-between;
  }
}
.wrapper-occasion--title {
  font-family: Nunito, sans-serif;
  font-size: 36px;
  font-style: normal;
  font-weight: 300;
  line-height: 49px;
  letter-spacing: 0em;
  text-align: center;
  margin: 0 auto 60px;
  max-width: 890px;
}
@media (max-width: 767.98px) {
  .wrapper-occasion--title {
    max-width: 100%;
    font-size: 24px;
    line-height: 1.4em;
    font-weight: 300;
    text-align: left;
    margin: 10px auto 20px;
  }
}
.wrapper-occasion-box {
  flex-wrap: wrap;
  margin: 0px 10px 30px;
  padding: 0;
  justify-content: space-between;
  align-items: flex-start;
}
@media (max-width: 767.98px) {
  .wrapper-occasion-box {
    display: flex;
    max-width: 100%;
    width: 100%;
  }
}
@media (min-width: 768px) {
  .wrapper-occasion-box {
    display: flex;
    max-width: 45%;
    width: 100%;
  }
}
.wrapper-occasion-box:last-child {
  margin-bottom: 10px;
}
.wrapper-occasion-box--title {
  width: 100%;
  font-family: Nunito, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 1.4em;
  letter-spacing: 0em;
  text-align: left;
  margin: 0 0 20px;
}
.wrapper-occasion-box__head {
  flex-wrap: wrap;
}
@media (max-width: 767.98px) {
  .wrapper-occasion-box__head {
    display: flex;
    max-width: 100%;
    width: 100%;
  }
}
@media (min-width: 768px) {
  .wrapper-occasion-box__head {
    display: flex;
    max-width: 62.5%;
    width: 100%;
  }
}
.wrapper-occasion-box__head--desc {
  font-family: Nunito, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 1.5em;
  letter-spacing: 0em;
  text-align: left;
  margin: 0;
}
.wrapper-occasion-box--img {
  max-width: 35%;
}
@media (max-width: 767.98px) {
  .wrapper-occasion-box--img {
    order: -1;
    margin-bottom: 5px;
  }
}

.wrapper-img-box {
  display: flex;
  max-width: 1300px;
  width: 100%;
  padding: 0 20px !important;
}
@media (min-width: 768px) {
  .wrapper-img-box {
    margin: 0 0 20px;
    min-height: 535px;
  }
  .wrapper-img-box.flex-end {
    justify-content: flex-end;
    padding: 0 !important;
  }
  .wrapper-img-box.flex-end article {
    margin-right: 5vw !important;
  }
  .wrapper-img-box.margin-auto-top {
    margin: -50px 0 !important;
  }
  .wrapper-img-box.margin-auto-top figure {
    margin: auto 0;
  }
  .wrapper-img-box.margin-auto-top article {
    margin: auto 0 auto 5vw;
  }
}
@media (max-width: 767.98px) {
  .wrapper-img-box {
    flex-wrap: wrap;
    margin: 40px 0 20px;
  }
  .wrapper-img-box.new-order figure {
    order: 1;
    height: 250px;
    top: 0;
  }
  .wrapper-img-box.new-order article {
    order: 2;
  }
  .wrapper-img-box.new-order article .wrapper-imgbox__header--title {
    margin: 10px 20px 20px !important;
  }
}
.wrapper-img-box__header {
  margin: 0 auto;
  height: 100%;
}
@media (max-width: 767.98px) {
  .wrapper-img-box__header {
    display: flex;
    max-width: 100%;
    width: 100%;
    flex-direction: column;
    align-items: center;
    order: 2;
  }
}
@media (min-width: 768px) {
  .wrapper-img-box__header {
    padding: 0 20px 0 0;
    display: block;
    max-width: 45%;
    width: 100%;
  }
}
.wrapper-img-box__header--title {
  width: 100%;
  margin: 20px auto;
  font-family: Nunito, sans-serif;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 55px;
  letter-spacing: 0em;
  color: #000;
}
@media (max-width: 767.98px) {
  .wrapper-img-box__header--title {
    text-align: center;
    padding: 0;
    margin: 0 20px 30px;
    max-width: 325px;
    font-size: 24px;
    line-height: 32px;
  }
}
@media (max-width: 767.98px) {
  .wrapper-img-box__header--desc {
    text-align: center;
  }
}
.wrapper-img-box__header--desc, .wrapper-img-box__header--list {
  max-width: 455px;
  font-family: Nunito, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  letter-spacing: 0em;
  color: #000;
}
@media (max-width: 767.98px) {
  .wrapper-img-box__header--desc, .wrapper-img-box__header--list {
    padding: 0;
    margin: 0 20px 20px;
    max-width: 325px;
    font-size: 12px;
    line-height: 16px;
  }
}
.wrapper-img-box__header--list {
  padding: 0 0 0 20px;
}
.wrapper-img-box__header--btns {
  justify-content: space-between;
  margin: 0;
  padding: 0;
}
@media (max-width: 767.98px) {
  .wrapper-img-box__header--btns {
    display: flex;
    max-width: 100%;
    width: 100%;
    flex-direction: column;
    align-items: center;
  }
  .wrapper-img-box__header--btns * {
    max-width: 325px;
    width: 100%;
    margin: 0 0 10px !important;
  }
}
.wrapper-img-box__hero {
  display: flex;
  margin: auto;
  justify-content: flex-start;
  align-items: center;
  overflow: visible;
  position: relative;
  height: 100%;
  width: 50%;
}
@media (max-width: 767.98px) {
  .wrapper-img-box__hero {
    order: 1;
  }
}
@media (min-width: 1300px) {
  .wrapper-img-box__hero {
    margin: auto auto 15%;
  }
}
@media (min-width: 1600px) {
  .wrapper-img-box__hero.margin-left {
    margin: auto auto auto -100px;
  }
  .wrapper-img-box__hero.margin-right {
    margin: auto -100px auto auto;
  }
}
@media (max-width: 767.98px) {
  .wrapper-img-box__hero {
    width: 100%;
    max-width: 335px;
    top: 170px;
  }
}
.wrapper-img-box__hero--img {
  max-width: 100%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}

.form__main-with-modal input[type=email], #modal-dont-have-nip input[type=email], .form-dont-have-nip input[type=email] {
  border: none;
}
.form__main-with-modal--input, #modal-dont-have-nip--input, .form-dont-have-nip--input {
  width: 100%;
  box-shadow: 0px 0px 30px 0px #00000026;
  font-family: Nunito, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
  background: #FFFFFF;
  height: 55px;
  padding: 0 20px;
  margin: 20px auto 0;
  border-radius: 70px;
  color: #000;
  border: 3px solid #fff;
}
.form__main-with-modal--input:focus, #modal-dont-have-nip--input:focus, .form-dont-have-nip--input:focus {
  border: 3px solid #019A9A !important;
}
.form__main-with-modal--check, #modal-dont-have-nip--check, .form-dont-have-nip--check {
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: #000;
  margin: 10px 0;
  text-decoration: underline;
  cursor: pointer;
}
.form__main-with-modal .main__accept, #modal-dont-have-nip .main__accept, .form-dont-have-nip .main__accept {
  position: relative;
  width: 100%;
  margin: 5px 0;
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: #000;
  padding: 0 0 0 35px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
@media (max-width: 767.98px) {
  .form__main-with-modal .main__accept, #modal-dont-have-nip .main__accept, .form-dont-have-nip .main__accept {
    font-size: 12px;
    line-height: 15px;
  }
}
.form__main-with-modal .main__accept a, .form__main-with-modal .main__accept span.label--more, #modal-dont-have-nip .main__accept a, #modal-dont-have-nip .main__accept span.label--more, .form-dont-have-nip .main__accept a, .form-dont-have-nip .main__accept span.label--more {
  cursor: pointer;
  font-weight: 500;
  color: #000;
}
.form__main-with-modal .main__accept--checkbox, #modal-dont-have-nip .main__accept--checkbox, .form-dont-have-nip .main__accept--checkbox {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
  margin: 0;
}
.form__main-with-modal .main__accept input:checked ~ span.checkmark::after, #modal-dont-have-nip .main__accept input:checked ~ span.checkmark::after, .form-dont-have-nip .main__accept input:checked ~ span.checkmark::after {
  display: block;
}
.form__main-with-modal .main__accept .checkmark, #modal-dont-have-nip .main__accept .checkmark, .form-dont-have-nip .main__accept .checkmark {
  background-color: #FFFFFF;
  position: absolute;
  border: 1px solid #019A9A;
  height: 20px;
  width: 20px;
  left: 0;
  top: 1px;
  border-radius: 16px;
}
.form__main-with-modal .main__accept .checkmark::after, #modal-dont-have-nip .main__accept .checkmark::after, .form-dont-have-nip .main__accept .checkmark::after {
  content: "";
  display: none;
  position: absolute;
  width: 60%;
  height: 60%;
  margin: auto;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: url(../img/check.svg) no-repeat center/cover;
}
.form__main-with-modal .main__accept .more--text, #modal-dont-have-nip .main__accept .more--text, .form-dont-have-nip .main__accept .more--text {
  display: none;
}
.form__main-with-modal--submit, #modal-dont-have-nip--submit, .form-dont-have-nip--submit {
  background: radial-gradient(91.66% 306.32% at 0% 16.18%, #01CC9D 0%, #019A9A 100%);
  border: 2px solid #FFFFFF;
  box-sizing: border-box;
  border-radius: 70px;
  font-family: Nunito, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 800;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: center;
  color: #FFFFFF;
  height: 55px;
  width: 100%;
  margin: 20px 0;
  cursor: pointer;
}

.form__main-with-modal .w-100, #modal-dont-have-nip .w-100 {
  width: 100% !important;
}
.form__main-with-modal .mb-1, #modal-dont-have-nip .mb-1 {
  margin-bottom: 0.25rem !important;
}
.form__main-with-modal .mb-2, #modal-dont-have-nip .mb-2 {
  margin-bottom: 0.5rem !important;
}
.form__main-with-modal .mb-3, #modal-dont-have-nip .mb-3 {
  margin-bottom: 0.75rem !important;
}
.form__main-with-modal .mb-4, #modal-dont-have-nip .mb-4 {
  margin-bottom: 1rem !important;
}
.form__main-with-modal .mb-5, #modal-dont-have-nip .mb-5 {
  margin-bottom: 1.25rem !important;
}
.form__main-with-modal .mt-1, #modal-dont-have-nip .mt-1 {
  margin-top: 0.25rem !important;
}
.form__main-with-modal .mt-0, #modal-dont-have-nip .mt-0 {
  margin-top: 0 !important;
}
.form__main-with-modal .pt-0, #modal-dont-have-nip .pt-0 {
  padding-top: 0;
}
.form__main-with-modal .row, #modal-dont-have-nip .row {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  box-sizing: border-box;
  margin-right: -15px;
  margin-left: -15px;
}
.form__main-with-modal .row > *, #modal-dont-have-nip .row > * {
  width: auto;
  flex-grow: 0;
  flex-shrink: 0;
  box-sizing: border-box;
  margin: 0;
  padding-left: 15px;
  padding-right: 15px;
}
.form__main-with-modal .row .algin-center, #modal-dont-have-nip .row .algin-center {
  align-self: center;
}
.form__main-with-modal .row .col-sm-6, #modal-dont-have-nip .row .col-sm-6 {
  width: 50%;
  padding-right: 15px;
  padding-left: 15px;
}
.form__main-with-modal .row .col-sm-12, #modal-dont-have-nip .row .col-sm-12 {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}
@media (min-width: 600px) {
  .form__main-with-modal .row .col-md-3, #modal-dont-have-nip .row .col-md-3 {
    width: 25%;
    padding-right: 15px;
    padding-left: 15px;
  }
  .form__main-with-modal .row .col-md-4, #modal-dont-have-nip .row .col-md-4 {
    width: 33.3333333333%;
    padding-right: 15px;
    padding-left: 15px;
  }
  .form__main-with-modal .row .col-md-5, #modal-dont-have-nip .row .col-md-5 {
    width: 41.6666666667%;
    padding-right: 15px;
    padding-left: 15px;
  }
  .form__main-with-modal .row .col-md-6, #modal-dont-have-nip .row .col-md-6 {
    width: 50%;
    padding-right: 15px;
    padding-left: 15px;
  }
  .form__main-with-modal .row .col-md-7, #modal-dont-have-nip .row .col-md-7 {
    width: 58.3333333333%;
    padding-right: 15px;
    padding-left: 15px;
  }
  .form__main-with-modal .row .col-md-8, #modal-dont-have-nip .row .col-md-8 {
    width: 66.6666666667%;
    padding-right: 15px;
    padding-left: 15px;
  }
  .form__main-with-modal .row .col-md-12, #modal-dont-have-nip .row .col-md-12 {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
  }
}
@media (min-width: 1000px) {
  .form__main-with-modal .row .col-lg-4, #modal-dont-have-nip .row .col-lg-4 {
    width: 33.3333333333%;
    padding-right: 15px;
    padding-left: 15px;
  }
  .form__main-with-modal .row .col-lg-5, #modal-dont-have-nip .row .col-lg-5 {
    width: 41.6666666667%;
    padding-right: 15px;
    padding-left: 15px;
  }
  .form__main-with-modal .row .col-lg-6, #modal-dont-have-nip .row .col-lg-6 {
    width: 50%;
    padding-right: 15px;
    padding-left: 15px;
  }
  .form__main-with-modal .row .col-lg-7, #modal-dont-have-nip .row .col-lg-7 {
    width: 58.3333333333%;
    padding-right: 15px;
    padding-left: 15px;
  }
  .form__main-with-modal .row .col-lg-8, #modal-dont-have-nip .row .col-lg-8 {
    width: 66.6666666667%;
    padding-right: 15px;
    padding-left: 15px;
  }
  .form__main-with-modal .row .col-lg-12, #modal-dont-have-nip .row .col-lg-12 {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
  }
}
.form__main-with-modal .btn-outline, #modal-dont-have-nip .btn-outline {
  background: unset !important;
  color: #029494;
  border-color: #029494;
}
.form__main-with-modal .btn-form, #modal-dont-have-nip .btn-form {
  font-size: 16px;
  line-height: 26px;
  margin-top: 20px;
  margin-bottom: 0;
}
.form__main-with-modal .form-buttons, #modal-dont-have-nip .form-buttons {
  margin-bottom: 20px;
}

@keyframes bounce-7 {
  0% {
    transform: scale(1, 1) translateY(0);
  }
  10% {
    transform: scale(1.1, 0.9) translateY(0);
  }
  30% {
    transform: scale(0.9, 1.1) translateY(-20px);
  }
  50% {
    transform: scale(1.05, 0.95) translateY(0);
  }
  57% {
    transform: scale(1, 1) translateY(-7px);
  }
  64% {
    transform: scale(1, 1) translateY(0);
  }
  100% {
    transform: scale(1, 1) translateY(0);
  }
}
/* Generated with Bounce.js. Edit at http://bouncejs.com#%7Bs%3A%5B%7BT%3A%22c%22%2Ce%3A%22b%22%2Cd%3A1000%2CD%3A0%2Cf%3A%7Bx%3A0.9%2Cy%3A0.9%7D%2Ct%3A%7Bx%3A1%2Cy%3A0.9%7D%2Cs%3A1%2Cb%3A4%7D%2C%7BT%3A%22c%22%2Ce%3A%22b%22%2Cd%3A1000%2CD%3A0%2Cf%3A%7Bx%3A0.9%2Cy%3A0.9%7D%2Ct%3A%7Bx%3A0.9%2Cy%3A1%7D%2Cs%3A1%2Cb%3A6%7D%5D%7D */
@keyframes jelly {
  0% {
    transform: matrix3d(0.81, 0, 0, 0, 0, 0.81, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  3.4% {
    transform: matrix3d(0.838, 0, 0, 0, 0, 0.847, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  4.7% {
    transform: matrix3d(0.85, 0, 0, 0, 0, 0.864, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  6.81% {
    transform: matrix3d(0.869, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  9.41% {
    transform: matrix3d(0.89, 0, 0, 0, 0, 0.915, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  10.21% {
    transform: matrix3d(0.895, 0, 0, 0, 0, 0.92, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  13.61% {
    transform: matrix3d(0.911, 0, 0, 0, 0, 0.93, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  14.11% {
    transform: matrix3d(0.913, 0, 0, 0, 0, 0.93, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  17.52% {
    transform: matrix3d(0.919, 0, 0, 0, 0, 0.922, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  18.72% {
    transform: matrix3d(0.919, 0, 0, 0, 0, 0.917, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  21.32% {
    transform: matrix3d(0.918, 0, 0, 0, 0, 0.906, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  24.32% {
    transform: matrix3d(0.914, 0, 0, 0, 0, 0.896, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  25.23% {
    transform: matrix3d(0.912, 0, 0, 0, 0, 0.894, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  29.03% {
    transform: matrix3d(0.906, 0, 0, 0, 0, 0.891, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  29.93% {
    transform: matrix3d(0.904, 0, 0, 0, 0, 0.891, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  35.54% {
    transform: matrix3d(0.898, 0, 0, 0, 0, 0.897, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  36.74% {
    transform: matrix3d(0.897, 0, 0, 0, 0, 0.898, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  41.04% {
    transform: matrix3d(0.896, 0, 0, 0, 0, 0.902, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  44.44% {
    transform: matrix3d(0.897, 0, 0, 0, 0, 0.903, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  52.15% {
    transform: matrix3d(0.899, 0, 0, 0, 0, 0.901, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  59.86% {
    transform: matrix3d(0.901, 0, 0, 0, 0, 0.899, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  63.26% {
    transform: matrix3d(0.901, 0, 0, 0, 0, 0.899, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  75.28% {
    transform: matrix3d(0.9, 0, 0, 0, 0, 0.9, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  85.49% {
    transform: matrix3d(0.9, 0, 0, 0, 0, 0.9, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  90.69% {
    transform: matrix3d(0.9, 0, 0, 0, 0, 0.9, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  100% {
    transform: matrix3d(0.9, 0, 0, 0, 0, 0.9, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
}
.wrapper-tooltips {
  display: flex;
  max-width: 100%;
  width: 100%;
  padding: 0;
  margin: 50px 0 0;
  position: relative;
  justify-content: center;
  align-items: center;
  align-content: center;
}
@media (max-width: 767.98px) {
  .wrapper-tooltips {
    background: none;
    margin: 0;
    padding: 0 0 40px;
  }
}
.wrapper-tooltips--img {
  display: block;
  margin: auto;
  max-width: 100%;
}
.wrapper-tooltips__gfx {
  max-width: 1300px;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  height: 100%;
  width: 100%;
}
@media (max-width: 767.98px) {
  .wrapper-tooltips__gfx {
    display: none;
  }
}
.wrapper-tooltips__gfx img {
  position: absolute;
}
.wrapper-tooltips__gfx img:nth-of-type(1) {
  top: 10%;
  left: 5%;
}
.wrapper-tooltips__gfx img:nth-of-type(2) {
  top: -10%;
  right: 35%;
}
.wrapper-tooltips__gfx img:nth-of-type(3) {
  top: 20%;
  right: 10%;
}
.wrapper-tooltips__gfx img:nth-of-type(4) {
  left: 55%;
  bottom: 5%;
}
.wrapper-tooltips__gfx img:nth-of-type(5) {
  bottom: 0%;
  left: 5%;
}

.wrapper-numbers {
  display: flex;
  max-width: 900px;
  width: 100%;
  margin: 0 auto 80px !important;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 !important;
  margin: 0 auto 20px !important;
}
.wrapper-numbers-boxes {
  display: flex;
  max-width: 100%;
  width: 100%;
  margin: 0 auto !important;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0;
}
.wrapper-numbers-boxes-num {
  display: flex;
  max-width: 33%;
  width: 100%;
  margin: 0 auto !important;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0;
}
.wrapper-numbers-boxes-num__ico {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  height: 100px;
  width: 100px;
  border-radius: 120px;
}
@media (max-width: 767.98px) {
  .wrapper-numbers-boxes-num__ico {
    height: 65px;
    width: 65px;
  }
}
.wrapper-numbers-boxes-num__ico--svg {
  max-width: 70%;
}
.wrapper-numbers-boxes-num--title {
  font-family: PT Sans, sans-serif;
  font-size: 48px;
  font-style: normal;
  font-weight: 400;
  line-height: 62px;
  letter-spacing: 0em;
  text-align: center;
  margin: 0;
}
@media (max-width: 767.98px) {
  .wrapper-numbers-boxes-num--title {
    font-size: 18px;
    line-height: 23px;
  }
}
.wrapper-numbers-boxes-num--desc {
  font-family: Nunito, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: center;
}
@media (max-width: 767.98px) {
  .wrapper-numbers-boxes-num--desc {
    font-size: 12px;
    line-height: 16px;
  }
}
.wrapper-numbers--img {
  max-width: 70%;
}
.wrapper-numbers--box {
  font-family: Nunito, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 900;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: center;
  background: #FFFFFF;
  box-sizing: border-box;
  border-radius: 50px;
  letter-spacing: 0em;
  text-align: center;
  padding: 30px 50px;
  box-shadow: 4px 4px 60px 0px #00000026;
  position: relative;
}
@media (max-width: 767.98px) {
  .wrapper-numbers--box {
    font-size: 12px;
    line-height: 18px;
    padding: 12px 50px;
  }
}
@media (min-width: 768px) {
  .wrapper-numbers--box:after {
    content: "";
    width: 1600px;
    height: 1px;
    position: absolute;
    background-color: #BDBDBD;
    top: 50px;
    right: -160%;
    margin: auto;
    z-index: -1;
  }
}

.wrapper-boxes-slider {
  transition: all 0.25s;
  margin: 0 auto;
  padding: 80px !important;
  display: flex;
  max-width: 1300px;
  width: 100%;
  flex-direction: column;
}
@media (max-width: 1279.98px) {
  .wrapper-boxes-slider {
    padding: 0 !important;
  }
}
.wrapper-boxes-slider:after {
  position: absolute;
  bottom: 0;
  left: -150px;
  right: 0;
  margin: auto;
  width: 1600px;
  height: 1px;
  background: #BDBDBD;
  content: "";
}
.wrapper-boxes-slider .slick-track {
  display: flex !important;
}
.wrapper-boxes-slider .slick-list {
  overflow: visible;
}
@media (max-width: 1279.98px) {
  .wrapper-boxes-slider .slick-list {
    max-width: 330px;
  }
}
.wrapper-boxes-slider .slick-dots {
  list-style: none;
  display: flex;
  margin: 20px auto;
  padding: 0;
}
.wrapper-boxes-slider .slick-dots li.slick-active {
  background: #01AE9C;
  height: 16px;
  width: 27px;
}
.wrapper-boxes-slider .slick-dots li {
  border: 1px solid #01AE9C;
  height: 16px;
  width: 16px;
  border-radius: 40px;
  margin: 2px;
}
.wrapper-boxes-slider .slick-dots li button {
  visibility: hidden;
}
@media (min-width: 1280px) {
  .wrapper-boxes-slider .slick-dots {
    display: none;
  }
}
.wrapper-boxes-slider__box {
  display: flex;
  max-width: 315px;
  width: 100%;
  user-select: text;
  padding: 30px;
  margin: 0 3px;
  height: auto !important;
  border-radius: 10px;
  flex-direction: column;
}
@media (max-width: 1279.98px) {
  .wrapper-boxes-slider__box {
    margin: 0 auto;
  }
}
.wrapper-boxes-slider__box:nth-of-type(1) {
  background: radial-gradient(164.28% 117.44% at 11.35% 6.15%, #00CF9E 10.42%, #009A9A 100%);
}
.wrapper-boxes-slider__box:nth-of-type(2) {
  background: radial-gradient(174.74% 125.51% at 11.35% 6.15%, #10D59A 10.42%, #045940 100%);
}
.wrapper-boxes-slider__box:nth-of-type(3) {
  background: radial-gradient(152.77% 124.49% at 2.53% 0%, #52D2B4 0%, #11425E 100%);
}
.wrapper-boxes-slider__box:nth-of-type(4) {
  background: radial-gradient(149.39% 107.44% at 11.35% 6.15%, #0D8B8B 0%, #0B407E 100%);
}
.wrapper-boxes-slider__box--ico {
  margin: 0;
  padding: 0;
  height: 100%;
  max-height: 76px;
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.wrapper-boxes-slider__box--ico::before {
  left: 0;
  position: absolute;
  content: "";
  width: 150px;
  height: 3px;
  background: #fff;
}
@media (max-width: 767.98px) {
  .wrapper-boxes-slider__box--ico::before {
    width: 115px;
  }
}
.wrapper-boxes-slider__box--ico img {
  max-width: 100%;
  display: block;
}
.wrapper-boxes-slider__box--title {
  font-family: Nunito, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
  max-width: 214px;
  color: #fff;
}
@media (max-width: 767.98px) {
  .wrapper-boxes-slider__box--title {
    max-width: 150px;
    margin-top: -20px;
    font-size: 18px;
    line-height: 24px;
  }
}
.wrapper-boxes-slider__box--desc {
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #fff;
}
@media (max-width: 767.98px) {
  .wrapper-boxes-slider__box--desc {
    font-size: 12px;
    font-weight: 18px;
  }
}

.wrapper-help {
  display: flex;
  max-width: 1100px;
  width: 100%;
  margin: 0;
  flex-wrap: wrap;
}
@media (max-width: 767.98px) {
  .wrapper-help {
    justify-content: center;
    padding: 0 !important;
    align-items: center;
  }
}
@media (min-width: 768px) {
  .wrapper-help {
    padding: 0 20px 40px !important;
  }
}
.wrapper-help--title {
  width: 100%;
  font-family: Nunito, sans-serif;
  font-size: 40px;
  font-style: normal;
  font-weight: 300;
  line-height: 55px;
  letter-spacing: 0em;
  text-align: center;
  margin: 20px 0 -10px;
}
@media (max-width: 767.98px) {
  .wrapper-help--title {
    text-align: left;
    width: 74%;
    font-size: 24px;
    line-height: 33px;
    margin: 0 0 0px;
    padding: 0 0 0 30px;
  }
}
.wrapper-help__ico {
  width: 100%;
  margin: 0;
  padding: 0;
  height: 100%;
  max-height: 76px;
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
@media (max-width: 767.98px) {
  .wrapper-help__ico {
    max-width: 50px;
    margin: 0 40px 30px 0;
  }
}
.wrapper-help__ico--svg {
  max-width: 100%;
  display: none;
}
.wrapper-help__ico--svg.item-active {
  display: inline;
  animation: bounce-7 1s both;
}
@media (min-width: 768px) and (max-width: 1079.98px) {
  .wrapper-help__ico--svg {
    max-width: 10%;
  }
}
.wrapper-help__ico::before {
  left: 0;
  position: absolute;
  content: "";
  width: 85%;
  height: 2px;
  background: #fff;
}
@media (max-width: 767.98px) {
  .wrapper-help__ico::before {
    display: none;
  }
}
.wrapper-help-main {
  display: flex;
  max-width: 100%;
  width: 100%;
}
@media (max-width: 767.98px) {
  .wrapper-help-main {
    border-top: 0px solid #fff;
    flex-direction: column;
  }
}
.wrapper-help-main__menu {
  display: block;
  max-width: 360px;
  width: 100%;
  margin: 0;
  padding: 0 60px 0 0;
}
@media (min-width: 768px) {
  .wrapper-help-main__menu {
    border-right: 1px solid #fff;
  }
}
@media (max-width: 767.98px) {
  .wrapper-help-main__menu {
    width: 100%;
    max-width: auto;
    padding: 0 0 40px;
    margin: 20px 40px 0;
    border-bottom: 1px solid #fff;
  }
}
.wrapper-help-main__menu--item {
  font-family: Nunito, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  cursor: pointer;
  margin: 0 0 20px;
}
.wrapper-help-main__menu--item.item-active {
  text-decoration: underline;
  font-weight: 700;
}
@media (max-width: 767.98px) {
  .wrapper-help-main__menu--item {
    margin: 8px 0 0;
    font-size: 14px;
    line-height: 15px;
  }
}
.wrapper-help-main-content {
  display: block;
  max-width: 640px;
  width: 100%;
  padding: 0 0 0 40px;
}
@media (max-width: 767.98px) {
  .wrapper-help-main-content {
    padding: 0 40px 0;
    margin: 20px 0 0 0;
  }
}
.wrapper-help-main-content__info {
  display: none;
  margin: 0;
  padding: 0;
  flex-direction: column;
  height: 100%;
}
@media (min-width: 768px) {
  .wrapper-help-main-content__info {
    min-height: 360px;
  }
}
.wrapper-help-main-content__info.info-visible {
  display: flex;
  max-width: 100%;
  width: 100%;
}
.wrapper-help-main-content__info--title {
  font-family: Nunito, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 300;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: left;
  margin: 0 0 25px;
}
@media (max-width: 767.98px) {
  .wrapper-help-main-content__info--title {
    display: none;
  }
}
.wrapper-help-main-content__info--desc {
  margin: 0 0 20px;
  font-family: Nunito, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 1.5;
  letter-spacing: 0em;
  text-align: left;
}
.wrapper-help-main-content__info--desc.more {
  height: auto;
  -webkit-line-clamp: none;
}
.wrapper-help-main-content__info--desc a {
  font-weight: bold;
}
@media (max-width: 767.98px) {
  .wrapper-help-main-content__info--desc {
    font-size: 14px;
    line-height: 1.5;
  }
}
.wrapper-help-main-content__info .more {
  display: inline-block;
  margin: -1em 0 1em;
}
.wrapper-help-main-content__info .more i {
  font-size: 0.8em;
}
.wrapper-help-main-content__info .btn-black {
  font-size: 14px;
  line-height: 19px;
  margin: 5px auto 0 0;
}

.wrapper-priceboxes {
  display: flex;
  max-width: 1040px;
  width: 100%;
  justify-content: space-around;
  z-index: 1;
}
@media (max-width: 767.98px) {
  .wrapper-priceboxes {
    margin: 0 0 40px;
  }
}
@media (min-width: 768px) {
  .wrapper-priceboxes {
    margin: 20px 0 -20px;
  }
}
.wrapper-priceboxes .slick-track {
  display: flex !important;
  justify-content: space-around;
}
@media (min-width: 1280px) {
  .wrapper-priceboxes .slick-track {
    width: 100% !important;
  }
}
.wrapper-priceboxes .slick-list {
  overflow: visible;
  width: 100%;
}
@media (max-width: 1279.98px) {
  .wrapper-priceboxes .slick-list {
    max-width: 330px;
  }
}
.wrapper-priceboxes .slick-dots {
  list-style: none;
  display: flex;
  position: absolute;
  bottom: -70px;
  margin: 20px auto;
  padding: 0;
}
.wrapper-priceboxes .slick-dots li.slick-active {
  background: #01AE9C;
  height: 16px;
  width: 27px;
}
@media (min-width: 768px) {
  .wrapper-priceboxes .slick-dots li.slick-active {
    background: #fff;
  }
}
.wrapper-priceboxes .slick-dots li {
  border: 1px solid #01AE9C;
  height: 16px;
  width: 16px;
  border-radius: 40px;
  margin: 2px;
}
@media (min-width: 768px) {
  .wrapper-priceboxes .slick-dots li {
    border: 1px solid #fff;
  }
}
.wrapper-priceboxes .slick-dots li button {
  visibility: hidden;
}
@media (min-width: 1280px) {
  .wrapper-priceboxes .slick-dots {
    display: none !important;
  }
}
.wrapper-priceboxes-box {
  display: flex;
  max-width: 305px;
  width: 100%;
  display: flex !important;
  height: auto !important;
  flex-direction: column;
  margin: 0 auto;
  background-color: #fff;
  box-shadow: 0px 0px 15px 0px #00000026;
  user-select: text;
}
.wrapper-priceboxes-box:nth-of-type(1) .wrapper-priceboxes-box__head--title, .wrapper-priceboxes-box:nth-of-type(3) .wrapper-priceboxes-box__head--title {
  background: radial-gradient(100% 381.59% at 0% 6.83%, #00CF9E 0%, #009A9A 100%);
  padding: 15px 35px;
  margin: 0;
}
.wrapper-priceboxes-box:nth-of-type(1) .wrapper-priceboxes-box__head--subtitle, .wrapper-priceboxes-box:nth-of-type(3) .wrapper-priceboxes-box__head--subtitle {
  color: #019C9A;
}
.wrapper-priceboxes-box:nth-of-type(2) .wrapper-priceboxes-box__head {
  padding: 0 35px 5px;
  background: radial-gradient(100% 381.59% at 0% 6.83%, #00CF9E 0%, #009A9A 100%);
  margin: -15px -10px -10px;
}
.wrapper-priceboxes-box:nth-of-type(2) .wrapper-priceboxes-box__head--title {
  border-bottom: 1px solid #fff;
  padding: 16px 40px;
}
.wrapper-priceboxes-box:nth-of-type(2) .wrapper-priceboxes-box__head--subtitle {
  margin: 0 0 20px;
}
.wrapper-priceboxes-box:nth-of-type(2) .wrapper-priceboxes-box__head * {
  color: #fff;
}
.wrapper-priceboxes-box__head * {
  text-align: center;
}
.wrapper-priceboxes-box__head--title {
  text-align: center;
  margin: 0;
  font-family: Montserrat, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: center;
  color: #fff;
}
.wrapper-priceboxes-box__head--price {
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: center;
  padding: 25px 10px 35px;
  margin: 10px 0 0;
}
.wrapper-priceboxes-box__head--price span {
  font-family: Montserrat, sans-serif;
  font-size: 43px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: center;
}
.wrapper-priceboxes-box__head--subtitle {
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0em;
  text-transform: uppercase;
  margin: 0;
}
.wrapper-priceboxes-box--btns {
  display: flex;
  max-width: 100%;
  width: 100%;
  flex-direction: column;
  padding: 35px 35px 25px;
}
.wrapper-priceboxes-box--btns a {
  width: 100%;
  margin: 0 0 10px;
  font-size: 14px;
  line-height: 19px;
  font-weight: 400;
  height: 39px;
}
.wrapper-priceboxes-box__options {
  display: flex;
  max-width: 230px;
  width: 100%;
  flex-direction: column;
  padding: 20px 0;
  margin: 0 auto;
  border-top: 1px solid #E0E0E0;
  flex-grow: 1;
  height: auto;
}
.wrapper-priceboxes-box__options--general {
  border-bottom: 1px solid #E0E0E0;
  padding: 0 0 20px;
  height: auto;
  flex-grow: 1;
}
.wrapper-priceboxes-box__options--show {
  margin: 10px 0;
  text-align: center;
  font-family: Montserrat, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: center;
  text-decoration: underline;
  cursor: pointer;
}
.wrapper-priceboxes-box__options--hidden {
  display: none;
  padding: 0 0 80px;
}
.wrapper-priceboxes-box__options p {
  padding: 0;
  margin: 0;
}
.wrapper-priceboxes-box__options ul {
  margin: 0;
  padding: 0 0 0 20px;
}
.wrapper-priceboxes-box__options li {
  list-style-image: url(../img/check.png);
  font-family: Montserrat, sans-serif;
  font-size: 12px;
  font-style: normal;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;
}

.wrapper-cal {
  display: flex;
  max-width: 920px;
  width: 100%;
  justify-content: space-around;
  align-items: center;
  align-content: center;
}
@media (max-width: 767.98px) {
  .wrapper-cal {
    flex-direction: column;
    margin: 30px 0 0;
  }
}
@media (min-width: 768px) {
  .wrapper-cal {
    padding: 60px 0 0 !important;
  }
}
.wrapper-cal--img {
  max-width: 40%;
}
.wrapper-cal__head {
  padding: 40px;
  margin: 0;
}
@media (max-width: 767.98px) {
  .wrapper-cal__head {
    padding: 0 30px;
  }
}
.wrapper-cal__head--pretitle {
  font-family: Nunito, sans-serif;
  font-size: 28px;
  font-style: normal;
  font-weight: 300;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
  margin: 0;
}
@media (max-width: 767.98px) {
  .wrapper-cal__head--pretitle {
    display: none;
  }
}
.wrapper-cal__head--title {
  font-family: Nunito, sans-serif;
  font-size: 41px;
  font-style: normal;
  font-weight: 700;
  line-height: 49px;
  letter-spacing: 0em;
  text-align: left;
  margin: 20px 0;
  max-width: 340px;
}
@media (max-width: 767.98px) {
  .wrapper-cal__head--title {
    text-align: center;
    font-size: 24px;
    line-height: 28px;
    max-width: 200px;
    margin: 10px auto;
  }
}
.wrapper-cal__head--subtitle {
  margin: 0 0 35px;
  font-family: Nunito, sans-serif;
  font-size: 19px;
  font-style: normal;
  font-weight: 300;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
}
@media (max-width: 767.98px) {
  .wrapper-cal__head--subtitle {
    display: none;
  }
}

.wrapper-used-function {
  display: block;
  max-width: 1080px;
  width: 100%;
  margin: 0;
  padding: 0;
  position: relative;
}
.wrapper-used-function--title {
  font-family: Nunito, sans-serif;
  font-size: 40px;
  font-style: normal;
  font-weight: 300;
  line-height: 55px;
  letter-spacing: 0em;
  text-align: center;
  margin: 30px 0 50px;
}
@media (max-width: 767.98px) {
  .wrapper-used-function--title {
    font-size: 24px;
    line-height: 32px;
  }
}
.wrapper-used-function-main {
  display: flex;
  max-width: 100%;
  width: 100%;
  flex-wrap: wrap;
  margin-bottom: 40px;
  z-index: 3;
}
@media (min-width: 1080px) {
  .wrapper-used-function-main {
    justify-content: flex-start;
    padding-left: 21px;
    margin-bottom: 70px;
  }
}
.wrapper-used-function-main.slick-initialized .slick-slide {
  display: flex !important;
}
.wrapper-used-function-main .slick-track {
  display: flex !important;
  justify-content: space-around;
}
@media (min-width: 1080px) {
  .wrapper-used-function-main .slick-track {
    width: 100% !important;
  }
}
.wrapper-used-function-main .slick-list {
  overflow: visible;
  width: 100%;
}
@media (max-width: 1079.98px) {
  .wrapper-used-function-main .slick-list {
    max-width: 330px;
  }
}
.wrapper-used-function-main .slick-dots {
  list-style: none;
  display: flex;
  position: absolute;
  margin: 20px auto;
  padding: 0;
  right: 0;
  left: 0;
  justify-content: center;
  bottom: -30px;
}
@media (min-width: 1080px) {
  .wrapper-used-function-main .slick-dots {
    bottom: -70px;
  }
}
.wrapper-used-function-main .slick-dots li.slick-active {
  background: #01AE9C;
  height: 16px;
  width: 27px;
}
.wrapper-used-function-main .slick-dots li {
  border: 1px solid #01AE9C;
  height: 16px;
  width: 16px;
  border-radius: 40px;
  margin: 2px;
}
.wrapper-used-function-main .slick-dots li button {
  visibility: hidden;
}
.wrapper-used-function-main-box {
  display: flex;
  max-width: 100%;
  width: 100%;
  flex-direction: column;
  margin: 0 0 0px;
}
@media (max-width: 1079.98px) {
  .wrapper-used-function-main-box {
    position: sticky;
    top: 70px;
  }
}
@media (min-width: 1080px) {
  .wrapper-used-function-main-box {
    margin-bottom: 50px;
    display: flex;
    max-width: 317px;
    width: 100%;
  }
  .wrapper-used-function-main-box:nth-of-type(2), .wrapper-used-function-main-box:nth-of-type(5) {
    margin: 0 18px 50px;
  }
}
.wrapper-used-function-main-box.func-active .fx-image {
  margin: auto;
}
.wrapper-used-function-main-box.func-active .fx-image img {
  max-width: 100%;
}
.wrapper-used-function-main-box.func-active .fx-anim {
  padding-bottom: 0%;
}
.wrapper-used-function-main-box.func-active .fx-anim,
.wrapper-used-function-main-box.func-active .fx-image {
  width: 70%;
  display: flex;
  algin-items: center;
  justify-content: center;
}
@media (max-width: 1079.98px) {
  .wrapper-used-function-main-box.func-active {
    position: static;
  }
}
@media (max-width: 1079px) {
  .wrapper-used-function-main-box.func-active .wrapper-used-function-main-box__main--desc-small {
    display: none;
  }
  .wrapper-used-function-main-box.func-active .wrapper-used-function-main-box__main--teaser .wrapper-used-function-main-box__main--show {
    display: none;
  }
  .wrapper-used-function-main-box.func-active .wrapper-used-function-main-box__main {
    display: flex;
  }
}
@media (min-width: 1080px) {
  .wrapper-used-function-main-box.func-active .wrapper-used-function-main-box__head {
    z-index: 2;
  }
  .wrapper-used-function-main-box.func-active .wrapper-used-function-main-box__head:before {
    box-shadow: 0 0 20px 10px #0000001A;
    background-color: #fff;
    z-index: -1;
  }
  .wrapper-used-function-main-box.func-active .wrapper-used-function-main-box__head:before * {
    z-index: 2;
  }
  .wrapper-used-function-main-box.func-active .wrapper-used-function-main-box__main {
    display: flex;
    position: absolute;
    max-height: 430px;
    max-height: none;
    min-height: 400px;
    height: auto;
    padding: 55px 110px 35px;
    left: 0;
    margin-top: 120px;
    z-index: 6;
  }
  .wrapper-used-function-main-box.func-active .wrapper-used-function-main-box__main--desc {
    z-index: 3;
    max-width: 500px;
  }
  .wrapper-used-function-main-box.func-active .wrapper-used-function-main-box__main .fx-anim {
    position: absolute;
    top: 30px;
    right: 0;
    width: 35%;
    animation: jelly 1s linear both;
    padding-bottom: 0;
  }
  .wrapper-used-function-main-box.func-active .wrapper-used-function-main-box__main .fx-image {
    animation: jelly 1s linear both;
    position: absolute;
    top: 30px;
    right: 0;
    width: 35%;
    display: flex;
    algin-items: center;
    justify-content: center;
  }
  .wrapper-used-function-main-box.func-active .wrapper-used-function-main-box__main .fx-image img {
    max-width: none;
  }
  .wrapper-used-function-main-box.func-active .wrapper-used-function-main-box__main:before {
    box-shadow: 0 20px 20px 10px #0000001A;
    background-color: #fff;
  }
}
.wrapper-used-function-main-box__head {
  display: flex;
  max-width: 100%;
  width: 100%;
  align-items: center;
  align-content: center;
  flex-wrap: wrap;
  margin: 25px 0;
  cursor: pointer;
}
@media (min-width: 1080px) {
  .wrapper-used-function-main-box__head:before {
    transition: all 0.25s;
    position: absolute;
    height: 150px;
    width: 400px;
    margin: 0 0 0 -36px;
    content: "";
  }
}
.wrapper-used-function-main-box__head--top {
  width: 100%;
  height: 100vh;
  max-height: 13px;
  display: block;
  margin: 0 0 25px;
}
.wrapper-used-function-main-box__head--ico {
  margin: 0 10px 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  height: 55px;
  width: 55px;
}
.wrapper-used-function-main-box__head--ico img {
  max-width: 100%;
}
.wrapper-used-function-main-box__head--title {
  font-family: Montserrat, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  margin: 0;
  max-width: 245px;
  max-width: 205px;
}
.wrapper-used-function-main-box__main {
  display: flex;
  max-width: 100%;
  width: 100%;
  display: none;
  flex-direction: column;
  height: 100%;
}
@media (min-width: 1080px) {
  .wrapper-used-function-main-box__main:before {
    position: absolute;
    height: 105%;
    width: calc(100% + 40px);
    left: -15px;
    margin: auto;
    top: 0;
    content: "";
    z-index: -1;
  }
}
.wrapper-used-function-main-box__main--teaser, .wrapper-used-function-main-box__main--desc {
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  margin: 0 0 15px;
}
@media (max-width: 767.98px) {
  .wrapper-used-function-main-box__main--teaser, .wrapper-used-function-main-box__main--desc {
    font-size: 12px;
    line-height: 24px;
  }
  .wrapper-used-function-main-box__main--teaser .fx-anim, .wrapper-used-function-main-box__main--desc .fx-anim {
    margin-top: 20px;
  }
}
.wrapper-used-function-main-box__main--teaser {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-bottom: 0;
}
.wrapper-used-function-main-box__main .wrapper-used-function-main-box__main--show {
  margin-top: 1em;
}
.wrapper-used-function-main-box__main--show {
  font-family: Montserrat, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  text-decoration: underline;
  cursor: pointer;
  margin: auto auto 0 0;
  position: relative;
  z-index: 5;
}
@media (max-width: 767.98px) {
  .wrapper-used-function-main-box__main--show {
    font-size: 12px;
    line-height: 24px;
  }
}

.wrapper-acc-price {
  margin: 0 auto;
  padding: 55px 20px !important;
  display: flex;
  max-width: 100%;
  width: 100%;
  flex-direction: column;
  align-items: center;
  align-content: center;
}
.wrapper-acc-price--pretitle {
  margin: 0;
  padding: 0;
  font-family: Nunito, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 1.5em;
  letter-spacing: 0em;
  text-align: center;
}
.wrapper-acc-price--title {
  margin: 0;
  padding: 0;
  font-family: Nunito, sans-serif;
  font-size: 34px;
  font-style: normal;
  font-weight: 300;
  line-height: 1.2em;
  letter-spacing: 0em;
  text-align: center;
}
.wrapper-acc-price-box {
  display: flex;
  max-width: 600px;
  width: 100%;
  flex-direction: column;
  background: linear-gradient(261.41deg, #FFFFFF -6.58%, #D6D6D6 5.04%, #FFFFFF 23.61%);
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  align-items: center;
  padding: 25px;
}
.wrapper-acc-price-box--head {
  display: flex;
  max-width: 325px;
  width: 100%;
  flex-direction: column;
}
.wrapper-acc-price-box--head span {
  display: block;
}
.wrapper-acc-price-box--head span:nth-of-type(1) {
  background: radial-gradient(100% 381.59% at 0% 6.83%, #00CF9E 0%, #019A9A 0.01%, #0B70A9 100%);
  font-family: Montserrat, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 31px;
  letter-spacing: 0em;
  text-align: center;
  height: 31px;
  width: 133px;
  border-radius: 12px;
}
.wrapper-acc-price-box--head span:nth-of-type(2) {
  font-family: Montserrat, sans-serif;
  font-size: 60px;
  font-style: normal;
  font-weight: 700;
  line-height: 88px;
  letter-spacing: 0em;
  text-align: center;
  color: #000;
}
@media (min-width: 768px) {
  .wrapper-acc-price-box--head span:nth-of-type(2) {
    font-size: 80px;
  }
}
.wrapper-acc-price-box--head span:nth-of-type(3) {
  color: #000;
  font-family: Montserrat, sans-serif;
  font-size: 30px;
  font-style: normal;
  font-weight: 300;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: center;
}
.wrapper-acc-price-box--desc {
  margin: 5px 0 0;
  font-family: Nunito, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: center;
  color: #000 !important;
}
.wrapper-acc-price-box--btns {
  text-align: center;
  width: 100%;
  margin: 15px 0 0;
  padding: 0;
}
.wrapper-acc-price-box--btns .page-btn {
  height: 39px !important;
  max-width: 230px;
  font-size: 14px !important;
  margin: 5px;
  font-weight: 400 !important;
}

.wrapper-comparision {
  display: block;
  max-width: 1080px;
  width: 100%;
  margin: 0 auto;
  padding: 0;
}
.wrapper-comparision-main {
  display: flex;
  max-width: 100%;
  width: 100%;
  flex-direction: column;
  margin: 0;
}
@media (min-width: 819.98px) {
  .wrapper-comparision-main {
    margin: 135px 0;
  }
}
.wrapper-comparision-main-headrow {
  display: flex;
  max-width: 100%;
  width: 100%;
  margin: 0;
}
@media (max-width: 819.98px) {
  .wrapper-comparision-main-headrow {
    flex-direction: column;
    align-items: center;
  }
}
.wrapper-comparision-main-headrow--title {
  width: 100%;
  font-family: Nunito, sans-serif;
  font-size: 40px;
  font-style: normal;
  font-weight: 300;
  line-height: 55px;
  letter-spacing: 0em;
  text-align: left;
}
@media (max-width: 819.98px) {
  .wrapper-comparision-main-headrow--title {
    margin: 50px 0 20px;
    text-align: center;
    font-size: 18px;
    line-height: 25px;
  }
}
.wrapper-comparision-main-headrow-box {
  flex-direction: column;
  align-items: center;
}
@media (max-width: 819.98px) {
  .wrapper-comparision-main-headrow-box {
    display: flex;
    max-width: 340px;
    width: 100%;
  }
}
@media (min-width: 820px) {
  .wrapper-comparision-main-headrow-box {
    display: flex;
    max-width: 183px;
    width: 100%;
  }
  .wrapper-comparision-main-headrow-box:nth-of-type(1) .wrapper-comparision-main-headrow-box__head {
    border-right: 4px solid #fff;
  }
  .wrapper-comparision-main-headrow-box:nth-of-type(3) .wrapper-comparision-main-headrow-box__head {
    border-left: 4px solid #fff;
  }
  .wrapper-comparision-main-headrow-box:nth-of-type(odd) {
    border-left: 1px solid #ADDBD2;
    border-right: 1px solid #ADDBD2;
  }
}
.wrapper-comparision-main-headrow-box:nth-of-type(2) .wrapper-comparision-main-headrow-box__main {
  background: radial-gradient(100% 381.59% at 0% 6.83%, #00CF9E 0%, #009A9A 100%);
}
.wrapper-comparision-main-headrow-box:nth-of-type(2) .wrapper-comparision-main-headrow-box__main * {
  color: #fff;
}
.wrapper-comparision-main-headrow-box__head {
  display: flex;
  max-width: 100%;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  background: radial-gradient(100% 381.59% at 0% 6.83%, #00CF9E 0%, #009A9A 100%);
  height: 90px;
  border-bottom: 4px solid #fff;
}
@media (max-width: 819.98px) {
  .wrapper-comparision-main-headrow-box__head {
    height: 70px;
  }
}
.wrapper-comparision-main-headrow-box__head--title {
  font-family: Nunito, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: center;
  color: #fff;
}
.wrapper-comparision-main-headrow-box__main {
  display: flex;
  max-width: 100%;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  height: 140px;
  padding: 0 30px;
}
.wrapper-comparision-main-headrow-box__main--price {
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: center;
  color: #000;
  margin: 0;
}
.wrapper-comparision-main-headrow-box__main--price span:nth-of-type(1) {
  color: #000;
  font-family: Montserrat, sans-serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: center;
}
.wrapper-comparision-main-headrow-box__main .page-btn {
  height: 38px;
  width: 130px;
  border-radius: 70px;
  font-size: 10px;
  font-weight: 800;
  line-height: 14px;
  padding: 0;
  margin: 15px 0 0 0;
}
.wrapper-comparision-main-headrow-box__main .page-btn.btn-black {
  color: #000;
}
.wrapper-comparision-main-headrow-box__foot {
  display: flex;
  max-width: 100%;
  width: 100%;
  flex-direction: column;
  align-items: center;
  align-content: center;
  border-top: 1px solid #ADDBD2;
  padding: 5px 10px 0 30px;
  max-height: 460px;
  overflow: hidden;
}
@media (min-width: 820px) {
  .wrapper-comparision-main-headrow-box__foot {
    display: none;
  }
}
.wrapper-comparision-main-headrow-box__foot--item {
  width: 100%;
  font-family: Montserrat, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;
  position: relative;
  margin: 0;
}
.wrapper-comparision-main-headrow-box__foot--item:before {
  position: absolute;
  left: -25px;
  content: url(../../img/check2.png);
}
.wrapper-comparision-main-headrow .show--more {
  display: flex;
  max-width: 100%;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  height: 60px;
  cursor: pointer;
}
@media (min-width: 820px) {
  .wrapper-comparision-main-headrow .show--more {
    display: none;
  }
}
.wrapper-comparision-main-bodyrow {
  display: flex;
  max-width: 100%;
  width: 100%;
  margin: 0;
  position: relative;
}
@media (max-width: 819.98px) {
  .wrapper-comparision-main-bodyrow {
    display: none;
  }
}
.wrapper-comparision-main-bodyrow:last-of-type .wrapper-comparision-main-bodyrow--title,
.wrapper-comparision-main-bodyrow:last-of-type .wrapper-comparision-main-bodyrow-box {
  padding: 0 0 30px 0;
}
.wrapper-comparision-main-bodyrow * {
  margin: 0;
  padding: 0 0 5px 0;
}
.wrapper-comparision-main-bodyrow--title {
  width: 100%;
  font-family: Montserrat, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;
}
.wrapper-comparision-main-bodyrow-box {
  display: flex;
  max-width: 183px;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-content: center;
}
.wrapper-comparision-main-bodyrow-box:nth-of-type(odd) {
  border-left: 1px solid #ADDBD2;
  border-right: 1px solid #ADDBD2;
}
.wrapper-comparision-main-bodyrow-box__main {
  display: flex;
  max-width: 100%;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  padding: 0 12px;
}
.wrapper-comparision-main-bodyrow-box__main * {
  max-width: 100%;
}

.thank-you-page {
  display: flex;
  flex-direction: column;
}
@media (min-width: 768px) {
  .thank-you-page {
    flex-direction: row;
  }
}
.thank-you-page h1, .thank-you-page h1 {
  font-family: Nunito;
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 1.1;
  margin-bottom: 1em;
  /* or 133% */
  letter-spacing: -0.02em;
  color: #000000;
}
@media (max-width: 1099px) {
  .thank-you-page h1, .thank-you-page h1 {
    font-size: 24px;
  }
}
.thank-you-page__aside, .thank-you-page__text {
  flex-shrink: 0;
  flex-grow: 0;
}
.thank-you-page__aside-bg {
  position: absolute;
  bottom: 0;
  right: 0;
  object-fit: contain;
  object-position: right bottom;
  opacity: 0.3;
  width: 50%;
}
@media (min-width: 1100px) {
  .thank-you-page__aside-bg {
    top: 0;
    right: 0;
    width: auto;
    opacity: 1;
    height: 100%;
  }
}
.thank-you-page__aside {
  padding-bottom: 100%;
  background: radial-gradient(88.44% 99.66% at 91.99% 96.44%, #A3FAE5 0%, #009A9A 100%);
  color: #fff;
  position: relative;
  overflow: hidden;
}
@media (min-width: 768px) {
  .thank-you-page__aside {
    padding-bottom: 0;
    flex-grow: 0;
    width: 50%;
  }
}
.thank-you-page__text {
  padding-right: 25px;
}
@media (max-width: 767px) {
  .thank-you-page__text {
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 30px;
    padding-bottom: 30px;
  }
}
@media (min-width: 768px) {
  .thank-you-page__text {
    flex-grow: 0;
    width: 50%;
    padding-top: 7%;
    padding-bottom: 7%;
    padding-left: max(20px, calc((100vw - 1220px) / 2));
  }
}

.thank-you-page__aside-steps {
  position: absolute;
  top: 10%;
  bottom: 10%;
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  left: 15px;
}
@media (min-width: 768px) {
  .thank-you-page__aside-steps {
    left: 60px;
    right: 30%;
  }
}
.thank-you-page__aside-steps li {
  display: flex;
  align-items: center;
  flex-grow: 1;
  position: relative;
}
.thank-you-page__aside-steps li:before, .thank-you-page__aside-steps li:after {
  content: "";
  background: #ffffff86;
  position: absolute;
  left: calc(39px / 2);
  height: calc(50% - 30px);
  width: 1px;
}
.thank-you-page__aside-steps li:before {
  bottom: calc(50% + 30px);
}
.thank-you-page__aside-steps li:after {
  top: calc(50% + 30px);
}
.thank-you-page__aside-steps li:first-of-type:before {
  height: 100%;
}
.thank-you-page__aside-steps li:last-of-type:after {
  height: 100%;
}
.thank-you-page__aside-steps li.current .s-label {
  font-weight: 900;
  font-size: 36px;
}
@media (max-width: 1099px) {
  .thank-you-page__aside-steps li.current .s-label {
    font-size: 26px;
  }
}
.thank-you-page__aside-steps li.current .s-icon {
  background: #fff;
  color: #01A99B;
}
.thank-you-page__aside-steps li.next .s-label {
  opacity: 0.5;
}
.thank-you-page__aside-steps li .s-icon {
  width: 39px;
  height: 39px;
  background: rgba(255, 255, 255, 0.3);
  border: 1px solid #FFFFFF;
  box-sizing: border-box;
  margin-right: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  font-family: Nunito;
  font-style: normal;
  font-weight: 900;
  font-size: 16px;
  line-height: 150%;
  flex-grow: 0;
  flex-shrink: 0;
  /* identical to box height, or 24px */
  text-align: center;
}
.thank-you-page__aside-steps li .s-label {
  font-family: Nunito;
  font-weight: 300;
  font-size: 24px;
  line-height: 1.1;
  /* identical to box height, or 36px */
  color: #FFFFFF;
}
@media (max-width: 1099px) {
  .thank-you-page__aside-steps li .s-label {
    font-size: 18px;
  }
}
@media (max-width: 1099px) {
  .thank-you-page__aside-steps li .s-label {
    font-size: 18px;
  }
}
.thank-you-page__aside-steps li .s-label .small {
  font-weight: 300;
  font-size: 12px;
  line-height: 1;
  margin-top: -1em;
  display: block;
}

.gotowi-na-ksef {
  --gutter: 25px;
}
.gotowi-na-ksef .bg-green2 {
  background: radial-gradient(99.17% 375.69% at 98.26% 92.93%, #00CF9E 0%, #009A9A 100%);
}
.gotowi-na-ksef .bg-green2 h2 {
  color: #fff;
}
.gotowi-na-ksef img {
  max-width: 100%;
}
.gotowi-na-ksef .row {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  box-sizing: border-box;
  margin-left: calc(var(--gutter) * -1);
  margin-right: calc(var(--gutter) * -1);
}
.gotowi-na-ksef .row > * {
  width: auto;
  flex-grow: 0;
  flex-shrink: 0;
  box-sizing: border-box;
  margin: 0;
  padding-left: var(--gutter);
  padding-right: var(--gutter);
}
.gotowi-na-ksef .row .algin-center {
  align-self: center;
}
.gotowi-na-ksef .row .col-sm-6 {
  width: 50%;
}
.gotowi-na-ksef .row .col-sm-12 {
  width: 100%;
}
@media (min-width: 600px) {
  .gotowi-na-ksef .row .col-md-6 {
    width: 50%;
  }
  .gotowi-na-ksef .row .col-md-12 {
    width: 100%;
  }
}
@media (min-width: 1000px) {
  .gotowi-na-ksef .row .col-lg-4 {
    width: 33.3333333333%;
  }
  .gotowi-na-ksef .row .col-lg-5 {
    width: 41.6666666667%;
  }
  .gotowi-na-ksef .row .col-lg-6 {
    width: 50%;
  }
  .gotowi-na-ksef .row .col-lg-7 {
    width: 58.3333333333%;
  }
  .gotowi-na-ksef .row .col-lg-8 {
    width: 66.6666666667%;
  }
  .gotowi-na-ksef .row .col-lg-12 {
    width: 100%;
  }
}
.gotowi-na-ksef p.thick {
  font-weight: bold;
  background-image: url(../img/thick.svg);
  background-repeat: no-repeat;
  background-position: 1px 6px;
  background-size: 21px 10px;
  font-weight: bold;
  padding-left: 30px;
}
@media (min-width: 600px) {
  .gotowi-na-ksef p.bigger {
    font-size: 22px;
  }
}
@media (min-width: 600px) {
  .gotowi-na-ksef .text-font-bigger {
    font-size: 20px;
  }
}
.gotowi-na-ksef h1 {
  font-size: 25px;
  font-weight: 300;
}
@media (min-width: 600px) {
  .gotowi-na-ksef h1 {
    font-size: 40px;
  }
}
.gotowi-na-ksef h2 {
  font-size: 22px;
  font-weight: 300;
}
@media (min-width: 600px) {
  .gotowi-na-ksef h2 {
    font-size: 44px;
  }
}
@media (min-width: 600px) {
  .gotowi-na-ksef .subtitle {
    font-size: 22px;
  }
}
.gotowi-na-ksef .v-paddings {
  padding-top: var(--gutter);
  padding-bottom: var(--gutter);
}
.gotowi-na-ksef .v-paddings-2 {
  padding-top: calc(var(--gutter) * 2);
  padding-bottom: calc(var(--gutter) * 2);
}
.gotowi-na-ksef .content {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  padding: var(--gutter);
}
.gotowi-na-ksef .text-left {
  text-align: left;
}
.gotowi-na-ksef .text-center {
  text-align: center;
}
@media (min-width: 600px) {
  .gotowi-na-ksef .text-md-center {
    text-align: center;
  }
}
.gotowi-na-ksef .light {
  font-weight: 300;
}
.gotowi-na-ksef .bold {
  font-weight: bold;
}
.gotowi-na-ksef p.list-title {
  font-weight: 600;
}
@media (min-width: 600px) {
  .gotowi-na-ksef p.list-title {
    font-size: 20px;
  }
}
.gotowi-na-ksef ul.list {
  list-style: decimal;
  margin-left: 0;
  padding-left: 0.7em;
  line-height: 1.4;
}
@media (min-width: 600px) {
  .gotowi-na-ksef ul.list {
    font-size: 20px;
  }
}
.gotowi-na-ksef ul.list li {
  margin-bottom: 0.3em;
  font-weight: 600;
}
.gotowi-na-ksef ul.list li a {
  text-decoration: none;
}
.gotowi-na-ksef ul.list li a:hover {
  text-decoration: underline;
}
.gotowi-na-ksef .ksef-steps.steps-4 {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  margin-left: calc(var(--gutter) * -1);
  margin-right: calc(var(--gutter) * -1);
}
.gotowi-na-ksef .ksef-steps.steps-4 > article {
  width: 50%;
  padding: var(--gutter);
}
@media (min-width: 1000px) {
  .gotowi-na-ksef .ksef-steps.steps-4 > article {
    width: 25%;
  }
}
.gotowi-na-ksef .ksef-steps.steps-4 .ksef-steps__post--ico {
  width: 50%;
  margin: auto;
}
.gotowi-na-ksef .ksef-steps.steps-4 .ksef-steps__post--ico img {
  width: 100%;
}
.gotowi-na-ksef .ksef-steps.steps-4 .ksef-steps__post--desc {
  border-top: 1px solid #ccc;
  padding-top: 0.5em;
}
.gotowi-na-ksef .ksef-steps2 {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  padding: 0;
}
.gotowi-na-ksef .ksef-steps2 > article {
  width: 100%;
  padding: calc(var(--gutter)/2);
}
@media (min-width: 450px) {
  .gotowi-na-ksef .ksef-steps2 > article {
    width: 33%;
  }
}
@media (min-width: 1000px) {
  .gotowi-na-ksef .ksef-steps2 > article {
    width: 20%;
  }
}
.gotowi-na-ksef .ksef-steps2 .ksef-steps2__post--desc {
  border-top: 1px solid #fff;
  padding-top: 1em;
  margin-top: 0.5em;
  line-height: 1.2;
}
.gotowi-na-ksef .ksef-steps2 .ksef-steps2__post--ico {
  width: 30%;
  margin: 0;
}
.gotowi-na-ksef .ksef-steps2 .ksef-steps2__post--ico img {
  width: 100%;
}
.gotowi-na-ksef .wrapper-icons .middle__text {
  position: absolute;
  top: -1em;
  right: 60%;
  width: 80%;
  text-align: center;
  padding: var(--gutter);
}
@media (max-width: 999px) {
  .gotowi-na-ksef .wrapper-icons .middle__text {
    display: none;
  }
}
.gotowi-na-ksef .w-100 {
  width: 100%;
}
.gotowi-na-ksef .mb-1 {
  margin-bottom: calc(var(--gutter) * 1);
}
.gotowi-na-ksef .mb-2 {
  margin-bottom: calc(var(--gutter) * 2);
}
.gotowi-na-ksef .mt-1 {
  margin-top: calc(var(--gutter) * 1);
}
.gotowi-na-ksef .mt-0 {
  margin-top: 0 !important;
}
.gotowi-na-ksef .pt-0 {
  padding-top: 0;
}

.otworz-dzialalnosc-gospodarcza {
  --gutter: 25px;
}
.otworz-dzialalnosc-gospodarcza .zakladanie-firmy {
  background-image: url("../otworz-dzialalnosc-gospodarcza/img/main-header.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.otworz-dzialalnosc-gospodarcza .bg-logo {
  background-image: url("../otworz-dzialalnosc-gospodarcza/img/logo-bg.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  min-height: 640px;
}
.otworz-dzialalnosc-gospodarcza .wrapper-ent-top {
  max-width: 1040px;
}
@media (min-width: 768px) {
  .otworz-dzialalnosc-gospodarcza .wrapper-ent-top {
    min-height: 458px;
  }
}
.otworz-dzialalnosc-gospodarcza .wrapper-ent-top__header {
  margin: 80px 4vw 40px;
  align-self: flex-start;
}
.otworz-dzialalnosc-gospodarcza .wrapper-ent-top__header--desc {
  margin-top: 20%;
}
@media (max-width: 1300px) {
  .otworz-dzialalnosc-gospodarcza .wrapper-ent-top__header--desc {
    margin-top: 16%;
  }
}
.otworz-dzialalnosc-gospodarcza .wrapper-ent-top__hero--img {
  height: 100%;
  width: auto;
  top: 0;
  right: 18%;
}
@media (max-width: 1256px) {
  .otworz-dzialalnosc-gospodarcza .wrapper-ent-top__hero--img {
    right: 4%;
  }
}
@media (max-width: 767.98px) {
  .otworz-dzialalnosc-gospodarcza .wrapper-ent-top__hero--img {
    display: none;
  }
}
.otworz-dzialalnosc-gospodarcza .main-bubble {
  /* Auto layout */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 38px 36px;
  gap: 10px;
  position: absolute;
  right: 5%;
  top: 30%;
  background: #F5F5F5;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 0px 20px 20px 20px;
}
.otworz-dzialalnosc-gospodarcza .main-bubble span {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 100%;
  color: #019A9A;
  white-space: nowrap;
}
@media (max-width: 1256px) {
  .otworz-dzialalnosc-gospodarcza .main-bubble {
    display: none;
    padding: 15px;
    right: 3%;
  }
}
.otworz-dzialalnosc-gospodarcza--submit {
  border: 2px solid #FFFFFF;
  box-sizing: border-box;
  border-radius: 70px;
  font-family: Nunito, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: center;
  color: #FFFFFF;
  height: 55px;
  width: 100%;
  margin: 20px 0;
  cursor: pointer;
  transition: 333ms;
}
.otworz-dzialalnosc-gospodarcza--submit:not(.btn-blue):not(.btn-green) {
  background: radial-gradient(91.66% 306.32% at 0% 16.18%, #01CC9D 0%, #019A9A 100%);
}
@media (max-width: 786px) {
  .otworz-dzialalnosc-gospodarcza--submit {
    font-size: 14px;
  }
}
.otworz-dzialalnosc-gospodarcza--submit:hover {
  filter: brightness(1.1);
}
.otworz-dzialalnosc-gospodarcza .bg-green2 {
  background: radial-gradient(99.17% 375.69% at 98.26% 92.93%, #00CF9E 0%, #009A9A 100%);
}
.otworz-dzialalnosc-gospodarcza .bg-green2 h2 {
  color: #fff;
}
.otworz-dzialalnosc-gospodarcza .bg-green-solid {
  background: #019A9A;
  color: #fff;
}
.otworz-dzialalnosc-gospodarcza .bg-green-solid p {
  color: #fff;
}
.otworz-dzialalnosc-gospodarcza .bg-gray {
  background: #f9f9f9;
}
.otworz-dzialalnosc-gospodarcza .form-dont-have-nip {
  max-width: 724px;
  width: 100%;
}
.otworz-dzialalnosc-gospodarcza .form-dont-have-nip .flex-form {
  display: flex;
  flex-direction: row;
  gap: 25px;
}
.otworz-dzialalnosc-gospodarcza .form-dont-have-nip--input {
  background: #FFFFFF;
  box-shadow: 5px 5px 42px rgba(0, 207, 158, 0.24);
  border-radius: 4px;
}
.otworz-dzialalnosc-gospodarcza .form-dont-have-nip--submit {
  width: 80%;
}
.otworz-dzialalnosc-gospodarcza .wrapper-icons__box--title {
  font-size: 22px;
  font-weight: 600;
}
.otworz-dzialalnosc-gospodarcza .wrapper-icons__box--desc {
  font-family: "Nunito";
  font-weight: 300;
  font-size: 18px;
  line-height: 25px;
  text-align: center;
}
.otworz-dzialalnosc-gospodarcza img {
  max-width: 100%;
}
.otworz-dzialalnosc-gospodarcza .row {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  box-sizing: border-box;
  margin-left: calc(var(--gutter) * -1);
  margin-right: calc(var(--gutter) * -1);
}
@media (max-width: 767.98px) {
  .otworz-dzialalnosc-gospodarcza .row {
    padding-left: 10px;
    padding-right: 10px;
  }
}
.otworz-dzialalnosc-gospodarcza .row > * {
  width: auto;
  flex-grow: 0;
  flex-shrink: 0;
  box-sizing: border-box;
  margin: 0;
  padding-left: var(--gutter);
  padding-right: var(--gutter);
}
.otworz-dzialalnosc-gospodarcza .row .algin-center {
  align-self: center;
}
.otworz-dzialalnosc-gospodarcza .row .col-sm-6 {
  width: 50%;
}
.otworz-dzialalnosc-gospodarcza .row .col-sm-12 {
  width: 100%;
}
@media (min-width: 600px) {
  .otworz-dzialalnosc-gospodarcza .row .col-md-6 {
    width: 50%;
  }
  .otworz-dzialalnosc-gospodarcza .row .col-md-12 {
    width: 100%;
  }
}
@media (min-width: 1000px) {
  .otworz-dzialalnosc-gospodarcza .row .col-lg-4 {
    width: 33.3333333333%;
  }
  .otworz-dzialalnosc-gospodarcza .row .col-lg-5 {
    width: 41.6666666667%;
  }
  .otworz-dzialalnosc-gospodarcza .row .col-lg-6 {
    width: 50%;
  }
  .otworz-dzialalnosc-gospodarcza .row .col-lg-7 {
    width: 58.3333333333%;
  }
  .otworz-dzialalnosc-gospodarcza .row .col-lg-8 {
    width: 66.6666666667%;
  }
  .otworz-dzialalnosc-gospodarcza .row .col-lg-12 {
    width: 100%;
  }
}
.otworz-dzialalnosc-gospodarcza p.thick {
  font-weight: bold;
  background-image: url(../img/thick.svg);
  background-repeat: no-repeat;
  background-position: 1px 6px;
  background-size: 21px 10px;
  font-weight: bold;
  padding-left: 30px;
}
@media (min-width: 600px) {
  .otworz-dzialalnosc-gospodarcza p.bigger {
    font-size: 22px;
  }
}
@media (min-width: 600px) {
  .otworz-dzialalnosc-gospodarcza .text-font-bigger {
    font-size: 20px;
  }
}
.otworz-dzialalnosc-gospodarcza h1 {
  font-size: 25px;
  font-weight: 300;
}
@media (min-width: 600px) {
  .otworz-dzialalnosc-gospodarcza h1 {
    font-size: 40px;
  }
}
.otworz-dzialalnosc-gospodarcza h2 {
  font-size: 22px;
  font-weight: 300;
}
@media (min-width: 600px) {
  .otworz-dzialalnosc-gospodarcza h2 {
    font-size: 40px;
  }
  .otworz-dzialalnosc-gospodarcza h2 .smaller {
    font-size: 26px;
  }
}
@media (min-width: 600px) {
  .otworz-dzialalnosc-gospodarcza .subtitle {
    font-size: 22px;
  }
}
.otworz-dzialalnosc-gospodarcza .v-paddings {
  padding-top: var(--gutter);
  padding-bottom: var(--gutter);
}
.otworz-dzialalnosc-gospodarcza .v-paddings-2 {
  padding-top: calc(var(--gutter) * 2);
  padding-bottom: calc(var(--gutter) * 2);
}
.otworz-dzialalnosc-gospodarcza .content {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  padding: var(--gutter);
}
.otworz-dzialalnosc-gospodarcza .text-left {
  text-align: left;
}
.otworz-dzialalnosc-gospodarcza .text-center {
  text-align: center;
}
@media (min-width: 600px) {
  .otworz-dzialalnosc-gospodarcza .text-md-center {
    text-align: center;
  }
}
.otworz-dzialalnosc-gospodarcza .light {
  font-weight: 300;
}
.otworz-dzialalnosc-gospodarcza .bold {
  font-weight: bold;
}
.otworz-dzialalnosc-gospodarcza p.list-title {
  font-weight: 600;
}
@media (min-width: 600px) {
  .otworz-dzialalnosc-gospodarcza p.list-title {
    font-size: 20px;
  }
}
.otworz-dzialalnosc-gospodarcza ul.list {
  list-style: decimal;
  margin-left: 0;
  padding-left: 0.7em;
  line-height: 1.4;
}
@media (min-width: 600px) {
  .otworz-dzialalnosc-gospodarcza ul.list {
    font-size: 20px;
  }
}
.otworz-dzialalnosc-gospodarcza ul.list li {
  margin-bottom: 0.3em;
  font-weight: 600;
}
.otworz-dzialalnosc-gospodarcza ul.list li a {
  text-decoration: none;
}
.otworz-dzialalnosc-gospodarcza ul.list li a:hover {
  text-decoration: underline;
}
.otworz-dzialalnosc-gospodarcza .steps.steps-4 {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  margin-left: calc(var(--gutter) * -1);
  margin-right: calc(var(--gutter) * -1);
}
.otworz-dzialalnosc-gospodarcza .steps.steps-4 > article {
  width: 50%;
  padding: var(--gutter);
}
@media (min-width: 1000px) {
  .otworz-dzialalnosc-gospodarcza .steps.steps-4 > article {
    width: 25%;
  }
}
.otworz-dzialalnosc-gospodarcza .steps.steps-4 .steps3__post--ico {
  width: 50%;
  margin: auto;
}
.otworz-dzialalnosc-gospodarcza .steps.steps-4 .steps3__post--ico img {
  width: 100%;
}
.otworz-dzialalnosc-gospodarcza .steps.steps-4 .steps3__post--desc {
  border-top: 1px solid #000;
  padding-top: 0.5em;
}
.otworz-dzialalnosc-gospodarcza .steps {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  gap: 42px;
  padding: 0;
}
@media (min-width: 1050px) {
  .otworz-dzialalnosc-gospodarcza .steps {
    flex-wrap: nowrap;
  }
}
.otworz-dzialalnosc-gospodarcza .steps > article {
  width: 100%;
}
@media (min-width: 1050px) {
  .otworz-dzialalnosc-gospodarcza .steps > article {
    width: 33%;
  }
}
.otworz-dzialalnosc-gospodarcza .steps .steps__post--desc {
  border-top: 1px solid #000;
  padding-top: 1em;
  margin-top: 0.5em;
  line-height: 1.2;
}
.otworz-dzialalnosc-gospodarcza .steps .steps__post--ico {
  width: 30%;
  margin: 0;
  align-items: center;
  display: flex;
  height: 105px;
}
.otworz-dzialalnosc-gospodarcza .steps .steps__post--ico img {
  width: 100%;
}
.otworz-dzialalnosc-gospodarcza .steps .steps__post--title {
  font-size: 22px;
}
.otworz-dzialalnosc-gospodarcza .wrapper-icons .middle__text {
  position: absolute;
  top: -1em;
  right: 60%;
  width: 80%;
  text-align: center;
  padding: var(--gutter);
}
@media (max-width: 999px) {
  .otworz-dzialalnosc-gospodarcza .wrapper-icons .middle__text {
    display: none;
  }
}
.otworz-dzialalnosc-gospodarcza .wrapper-start {
  max-width: 1050px;
}
.otworz-dzialalnosc-gospodarcza .wrapper-start h2 {
  max-width: 790px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}
.otworz-dzialalnosc-gospodarcza .wrapper-start .content-with-image {
  display: flex;
  width: 100%;
  flex-direction: row;
}
.otworz-dzialalnosc-gospodarcza .list-wrapper {
  padding: 35px 50px 35px;
  background: #FFFFFF;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
  border-radius: 0px 20px 20px 20px;
}
.otworz-dzialalnosc-gospodarcza .list-wrapper .list-header {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}
.otworz-dzialalnosc-gospodarcza .list-wrapper .list-header .text-green {
  color: #019A9A;
}
.otworz-dzialalnosc-gospodarcza .list-wrapper .list-content {
  padding-left: 0;
}
.otworz-dzialalnosc-gospodarcza .list-wrapper .list-content li {
  list-style-type: none;
  margin-bottom: 0.5rem;
  line-height: 205.23%;
}
.otworz-dzialalnosc-gospodarcza .list-wrapper .list-content li::before {
  content: "";
  background-image: url(../img/check-circle.svg);
  background-repeat: no-repeat;
  background-position: 0px 4px;
  background-size: 16px 16px;
  font-weight: bold;
  padding-left: 28px;
}
.otworz-dzialalnosc-gospodarcza .list-wrapper .steps-wrapper {
  display: flex;
  flex-direction: row;
}
.otworz-dzialalnosc-gospodarcza .list-wrapper .steps-wrapper .steps-content {
  padding-left: 50px;
}
.otworz-dzialalnosc-gospodarcza .list-wrapper .steps-wrapper .steps-content .title {
  font-weight: 600;
  font-size: 20px;
  line-height: 151.4%;
}
.otworz-dzialalnosc-gospodarcza .opinions__post {
  margin-bottom: 3rem;
  max-width: 1050px;
  display: flex;
  gap: 42px;
  flex-direction: row;
  align-items: center;
}
@media (max-width: 767.98px) {
  .otworz-dzialalnosc-gospodarcza .opinions__post {
    flex-direction: column !important;
  }
}
.otworz-dzialalnosc-gospodarcza .opinions__post--image {
  margin: 0;
  position: relative;
  width: 100%;
}
.otworz-dzialalnosc-gospodarcza .opinions__post__content {
  width: 100%;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 300;
  font-size: 24px;
  line-height: 150%;
}
@media (max-width: 767.98px) {
  .otworz-dzialalnosc-gospodarcza .opinions__post__content {
    font-size: 18px;
  }
}
.otworz-dzialalnosc-gospodarcza .opinions__post__content--sign {
  font-weight: bold;
}
.otworz-dzialalnosc-gospodarcza .opinions__post:nth-of-type(even) {
  flex-direction: row-reverse;
}
.otworz-dzialalnosc-gospodarcza .opinions__post:nth-of-type(even) .opinions__bubble {
  right: -25%;
  left: unset;
}
.otworz-dzialalnosc-gospodarcza .opinions__bubble {
  background: #F5F5F5;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px 0px 20px 20px;
  position: absolute;
  padding: 38px;
  bottom: 20%;
  left: -25%;
  right: unset;
  color: #019A9A;
  font-size: 24px;
}
@media (max-width: 767.98px) {
  .otworz-dzialalnosc-gospodarcza .opinions__bubble {
    font-size: 16px;
    padding: 15px;
    margin-left: auto;
    margin-right: auto;
    left: 0 !important;
    right: 0 !important;
    bottom: -3%;
    text-align: center;
  }
}
.otworz-dzialalnosc-gospodarcza .table__worth {
  text-align: center;
  border-collapse: collapse;
}
.otworz-dzialalnosc-gospodarcza .table__worth thead th {
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 30px;
  text-align: center;
  padding: var(--gutter);
}
.otworz-dzialalnosc-gospodarcza .table__worth thead th img {
  max-width: 30px;
}
.otworz-dzialalnosc-gospodarcza .table__worth tbody td {
  border-bottom: 1px solid #000;
  line-height: 370.23%;
}
.otworz-dzialalnosc-gospodarcza .table__worth tbody td:first-child {
  text-align: left;
}
.otworz-dzialalnosc-gospodarcza .table__worth tbody tr:last-child td {
  border-bottom: unset;
}
.otworz-dzialalnosc-gospodarcza .w-100 {
  width: 100%;
}
.otworz-dzialalnosc-gospodarcza .mb-1 {
  margin-bottom: calc(var(--gutter) * 1);
}
.otworz-dzialalnosc-gospodarcza .mb-2 {
  margin-bottom: calc(var(--gutter) * 2);
}
.otworz-dzialalnosc-gospodarcza .mt-1 {
  margin-top: calc(var(--gutter) * 1);
}
.otworz-dzialalnosc-gospodarcza .mt-0 {
  margin-top: 0 !important;
}
.otworz-dzialalnosc-gospodarcza .mb-0 {
  margin-bottom: 0 !important;
}
.otworz-dzialalnosc-gospodarcza .pt-0 {
  padding-top: 0 !important;
}
.otworz-dzialalnosc-gospodarcza .pt-1 {
  padding-top: 1em !important;
}
.otworz-dzialalnosc-gospodarcza .pt-2 {
  padding-top: 2em !important;
}
.otworz-dzialalnosc-gospodarcza .pt-3 {
  padding-top: 3em !important;
}
.otworz-dzialalnosc-gospodarcza .pt-4 {
  padding-top: 4em !important;
}
.otworz-dzialalnosc-gospodarcza .pt-5 {
  padding-top: 5em !important;
}
.otworz-dzialalnosc-gospodarcza .pb-0 {
  padding-bottom: 0 !important;
}
.otworz-dzialalnosc-gospodarcza .pb-1 {
  padding-bottom: 1em !important;
}
.otworz-dzialalnosc-gospodarcza .pb-2 {
  padding-bottom: 2em !important;
}
.otworz-dzialalnosc-gospodarcza .pb-3 {
  padding-bottom: 3em !important;
}
.otworz-dzialalnosc-gospodarcza .pb-4 {
  padding-bottom: 4em !important;
}
.otworz-dzialalnosc-gospodarcza .pb-5 {
  padding-bottom: 5em !important;
}
.otworz-dzialalnosc-gospodarcza .accordion {
  cursor: pointer;
  padding: 18px;
  width: 100%;
  border: none;
  text-align: left;
  outline: none;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 151.4%;
  transition: 0.4s;
  border-top: 1px solid #000;
}
.otworz-dzialalnosc-gospodarcza .accordion:after {
  content: "";
  width: 12px;
  height: 12px;
  border-left: 2px solid #019A9A;
  border-bottom: 2px solid #019A9A;
  transform: rotate(315deg);
  float: right;
  margin-bottom: 5px;
}
.otworz-dzialalnosc-gospodarcza .active:after {
  transform: rotate(135deg);
  margin-top: 5px;
}
.otworz-dzialalnosc-gospodarcza .panel {
  padding: 0 18px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
}
.otworz-dzialalnosc-gospodarcza .faq {
  margin-top: 2rem;
  max-width: 1080px;
  border-bottom: 1px solid #000;
  padding: 0;
}

.swal2-container.swal2-center > .swal2-popup {
  font-family: inherit;
  font-size: 14px;
}
.swal2-container.swal2-center > .swal2-popup .swal2-html-container {
  margin: 0;
  padding: 0;
}
.swal2-container.swal2-center > .swal2-popup .swal2-html-container ul {
  text-align: left;
  max-width: 300px;
  margin: 10px auto 5px;
}
.swal2-container.swal2-center > .swal2-popup .swal2-confirm {
  min-width: 40%;
}
.swal2-container.swal2-center > .swal2-popup .swal2-actions {
  margin: 0;
  padding: 0;
}

.modal-open {
  overflow: hidden;
}
.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}

.modal.fade .modal-dialog {
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
  -webkit-transform: translate(0, -50px);
  transform: translate(0, -50px);
}
.modal.show .modal-dialog {
  -webkit-transform: none;
  transform: none;
}

@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal-dialog-scrollable {
  display: -ms-flexbox;
  display: flex;
  max-height: calc(100% - 1rem);
}
.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1rem);
  overflow: hidden;
}
.modal-dialog-scrollable .modal-header, .modal-dialog-scrollable .modal-footer {
  -ms-flex-negative: 0;
  flex-shrink: 0;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  min-height: calc(100% - 1rem);
}
.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - 1rem);
  content: "";
}
.modal-dialog-centered.modal-dialog-scrollable {
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: center;
  justify-content: center;
  height: 100%;
}
.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}
.modal-dialog-centered.modal-dialog-scrollable::before {
  content: none;
}

.modal-content {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: start;
  align-items: flex-start;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}
.modal-header .close {
  cursor: pointer;
  padding: 1rem 1rem;
  margin: -1rem -1rem -1rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: end;
  justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }

  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.5rem);
  }

  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
  .modal-dialog-centered::before {
    height: calc(100vh - 3.5rem);
  }

  .modal-sm {
    max-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg, .modal-xl {
    max-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}
#modal-dont-have-nip {
  background: #000000cc;
  border-radius: unset !important;
}
#modal-dont-have-nip .close {
  font-size: 1.8rem;
  font-weight: 300;
  color: #019A9A;
}
#modal-dont-have-nip form label {
  margin-top: 22px;
  margin-bottom: 32px;
}
#modal-dont-have-nip .modal-dialog {
  max-width: 814px;
}
#modal-dont-have-nip .modal-header {
  margin: 10px 20px 0;
  padding: unset;
  border-bottom: unset;
}
#modal-dont-have-nip .modal-content .modal-body {
  padding: 20px 70px;
  margin-bottom: 40px;
}
#modal-dont-have-nip .main__accept {
  display: block;
  margin-bottom: 22px;
  margin-top: 22px;
}
#modal-dont-have-nip p {
  /*font-family: 'Nunito Sans'; */
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 160%;
  margin: 0;
  margin-bottom: 1rem;
}
#modal-dont-have-nip h3 {
  font-size: 30px;
  font-weight: 200;
}
#modal-dont-have-nip h4 {
  font-size: 24px;
  font-weight: 200x;
}
#modal-dont-have-nip .font-weight-bold {
  font-weight: bold;
}
#modal-dont-have-nip .boxes .box .box-content {
  font-style: normal;
  font-size: 12px;
  line-height: 160%;
}
#modal-dont-have-nip .boxes .box img {
  margin-bottom: 10px;
}

@media (max-width: 814px) {
  #modal-dont-have-nip .modal-content .modal-body {
    padding: 20px 2em;
  }
}
.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: 0.9;
}
.tooltip .arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}
.tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top, .bs-tooltip-auto[x-placement^=top] {
  padding: 0.4rem 0;
}

.bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^=top] .arrow {
  bottom: 0;
}

.bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^=top] .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000;
}

.bs-tooltip-right, .bs-tooltip-auto[x-placement^=right] {
  padding: 0 0.4rem;
}

.bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^=right] .arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}

.bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^=right] .arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000;
}

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^=bottom] {
  padding: 0.4rem 0;
}

.bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^=bottom] .arrow {
  top: 0;
}

.bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^=bottom] .arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000;
}

.bs-tooltip-left, .bs-tooltip-auto[x-placement^=left] {
  padding: 0 0.4rem;
}

.bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^=left] .arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}

.bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^=left] .arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem;
}

.no-break {
  white-space: nowrap;
}

.d-none {
  display: none;
}

.m-auto {
  margin: auto;
}

.line-clamp-10 {
  display: -webkit-box;
  -webkit-line-clamp: 10;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media (max-width: 767.98px) {
  .no-padding {
    padding: 0 !important;
  }
}

.pt-0 {
  padding-top: 0;
}

.pt-20 {
  padding-top: 20px;
}

.pb-0 {
  padding-bottom: 0;
}

.mb-0 {
  margin-bottom: 0;
}

.pb-30 {
  padding-bottom: 30px;
}

.pb-20 {
  padding-bottom: 20px;
}

.bb-0 {
  border-bottom: 0 !important;
}

.bb-1 {
  border-bottom: 1px solid #BDBDBD;
}

.relative {
  position: relative;
}

.z-index-20 {
  z-index: 20;
}

@media (max-width: 1279.98px) {
  .d-tablet-none {
    display: none;
  }

  .d-tablet-block {
    display: block;
  }
}
@media (max-width: 767.98px) {
  .d-phone-none {
    display: none;
  }

  .d-phone-block {
    display: block;
  }

  .phone-text-left {
    text-align: left;
  }

  .sm-pt-20 {
    padding-top: 20px !important;
  }

  .sm-pt-30 {
    padding-top: 30px !important;
  }

  .sm-pb-20 {
    padding-bottom: 20px !important;
  }

  .sm-pb-30 {
    padding-bottom: 30px !important;
  }

  .sm-pt-0 {
    padding-top: 0px !important;
  }

  .sm-pb-0 {
    padding-bottom: 0px !important;
  }

  .sm-mb-0 {
    margin-bottom: 0px !important;
  }
}
@media (min-width: 767.99px) {
  .md-pt-20 {
    padding-top: 20px !important;
  }

  .md-pb-20 {
    padding-bottom: 20px !important;
  }

  .md-pb-30 {
    padding-bottom: 30px !important;
  }

  .md-pt-30 {
    padding-top: 30px !important;
  }

  .md-mw-1280 {
    max-width: 1280px;
  }
}