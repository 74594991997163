html, body
	font-family: Nunito, sans-serif
	
input, textarea
	&:focus,
	&:focus-visible
		outline: none
	
.wrapper-jumbo
	@include centerFlex(900px)
	margin: 0 auto
	flex-wrap: wrap
	justify-content: center
	align-items: flex-end
	padding: 0
	z-index: 1
	&.jumbo-fluid
		max-width: 1600px

	&--mini,
	&--big
		display: block
		font-family: Nunito, sans-serif
		font-style: normal
		font-weight: 300
		letter-spacing: 0em

	&--mini
		width: 16%
		font-size: 20px
		line-height: 18px
		margin: 0 0 40px
		@media (max-width: 767.98px)
			margin: 0
			font-size: 12px
			line-height: 15px
			width: 100%
			font-weight: 500
			max-width: 165px

		&:first-of-type
			text-align: right
			@media (max-width: 767.98px)
				order: 1
				text-align: left
		&:last-of-type
			text-align: left
			@media (max-width: 767.98px)
				order: 2
				text-align: right

	&--big
		width: 55%
		text-align: center
		font-size: 74px
		line-height: 1
		margin: 0 15px 40px
		@media (max-width: 767.98px)
			font-size: 56px
			line-height: 76px
			width: 100%
			order: 3


	&--pretitle
		margin: 0 auto
		width: 100%
		max-width: 775px
		font-family: Montserrat, sans-serif
		font-size: 24px
		font-style: normal
		font-weight: 300
		line-height: 33px
		letter-spacing: 0em
		text-align: center
		@media (max-width: 767.98px)
			max-width: 100%
			font-size: 16px
			line-height: 26px

	&--title
		width: 100%
		margin: 30px auto
		font-family: Nunito, sans-serif
		font-size: 40px
		font-style: normal
		font-weight: 300
		line-height: 55px
		letter-spacing: 0em
		text-align: center
		@media (max-width: 767.98px)
			order: 4
			font-size: 24px
			line-height: 28px
			&.more-padding
				padding: 20px 0

	&--subtitle
		order: 5
		margin: 0 auto
		width: 100%
		max-width: 775px
		font-family: Montserrat, sans-serif
		font-size: 16px
		font-style: normal
		font-weight: 600
		line-height: 26px
		letter-spacing: 0em
		text-align: center
		@media (max-width: 767.98px)
			max-width: 100%
			font-size: 13px
			line-height: 16px

	&--desc
		order: 6
		margin: 0 auto 25px
		max-width: 775px
		font-family: Nunito, sans-serif
		font-size: 16px
		font-style: normal
		font-weight: 300
		line-height: 1.4em
		letter-spacing: 0em
		text-align: center
		@media (max-width: 767.98px)
			max-width: none
			font-size: 14px
			line-height: 1.4em

	&--btns
		justify-content: space-around
		margin: 0
		padding: 0
		@include centerFlex(100%)
		@media (max-width: 767.98px)
			flex-direction: column
			align-items: center
			order: 5
			*
				max-width: 325px
				width: 100%
				margin: 0 0 10px !important
		@media (min-width: 768px)
			*
				width: 45%

	&__box
		@include centerBlock(560px)
		margin: 0
		padding: 10px 30px
		@media (max-width: 767.98px)
			padding: 0px
			order: 5
			max-width: none
			text-align: center

		@media (min-width: 768px)
			width: 45% !important

		&--title
			margin: 0 0 15px
			font-family: Nunito, sans-serif
			font-size: 24px
			font-style: normal
			font-weight: 700
			line-height: 33px
			letter-spacing: 0em
			@media (max-width: 767.98px)
				font-size: 24px
				line-height: 28px

		&--desc
			font-family: Nunito, sans-serif
			font-size: 16px
			font-style: normal
			font-weight: 300
			line-height: 24px
			letter-spacing: 0em
			@media (max-width: 767.98px)
				font-size: 14px
				line-height: 1.5em


			padding-left: 28px
			list-style-image: url(../img/Vector1.png)	
			@media (min-width: 768px)
				list-style: none
				padding-left: 0	
		
		@media (min-width: 768px)
			&:first-of-type
				text-align: right
				// padding-right: 20px
				border-right: 1px solid #000

.wrapper-acc-top
	@include centerFlex(100%)
	// align-items: center
	margin: 0 0 20px
	padding: 0 !important
	border-bottom: 1px solid #BDBDBD
	@media (max-width: 768px)
		min-height: 425px
	@media (min-width: 768px)
		min-height: 425px

	&--title
		font-family: Nunito, sans-serif
		font-size: 40px
		font-style: normal
		font-weight: 300
		line-height: 55px
		letter-spacing: 0em
		text-align: center
		width: 90%
		margin: 0 auto
		@media (max-width: 767.98px)
			text-align: left
			top: 30px
			left: 45px
			max-width: 200px
			color: #fff
			position: absolute
			font-size: 20px
			line-height: 27px

	&--desc
		font-family: Nunito, sans-serif
		font-size: 18px
		font-style: normal
		font-weight: 300
		line-height: 30px
		letter-spacing: 0em
		text-align: center
		max-width: 940px
		padding-left: 20px
		padding-right: 20px
		@media (max-width: 767.98px)
			// max-width: 330px
			text-align: left
			font-size: 14px
			line-height: 18px
			margin: 50px auto 40px
			order: 2
		@media (min-width: 768px) and (max-width: 1599.98px)
			margin: 20px auto 100px
		@media (min-width: 1600px)
			margin: 20px auto 250px


	&__boxes
		@include centerFlex(57%)
		flex-wrap: wrap
		justify-content: space-around
		@media (max-width: 767.98px)
			padding-left: $gutter
			padding-right: $gutter
			max-width: 100%
			order: 4
		@media (min-width: 768px)
			margin: -50px 0 0

		&--box
			width: 40%
			margin-bottom: 30px
			margin-top: 0
			@media (max-width: 767.98px)
				width: 100%
			span
				display: block
				&:nth-of-type(1)
					max-width: 246px
					font-family: Nunito, sans-serif
					font-size: 24px
					font-style: normal
					font-weight: 700
					line-height: 1.4em
					letter-spacing: 0em
					text-align: left
					@media (max-width: 767.98px)
						max-width: 380px
						font-size: 18px
						line-height: 1.4em
						br
							display: none

				&:nth-of-type(2)
					margin-top: 1em
					max-width: 321px
					font-family: Nunito, sans-serif
					font-size: 14px
					font-style: normal
					font-weight: normal
					line-height: 1.5em
					letter-spacing: 0em
					text-align: left
					@media (max-width: 767.98px)
						max-width: 380px
						font-size: 14px
						line-height: 1.5em
						



	&__header
		padding-bottom: 30px
		@media (max-width: 767.98px)
			@include centerFlex(100%)
			flex-direction: column
			align-items: center
		@media (min-width: 768px)
			margin-left: 5vw
			@include centerBlock(45%)

		&--title
			width: 100%
			margin: 20px auto
			font-family: Nunito, sans-serif
			font-size: 40px
			font-style: normal
			font-weight: 300
			line-height: 55px
			letter-spacing: 0em
			color: #000
			@media (max-width: 767.98px)
				height: 36vw
				position: relative
				margin: 0
				width: 100%
				padding: 35px
				font-size: 20px
				line-height: 27px
				color: #fff
				span
					max-width: 70%
					display: block

				&:before
					content: ""
					position: absolute
					height: 100%
				&:before
					left: 0
					right: 0
					top: 0
					width: 100%
					z-index: -1
					background: radial-gradient(54.26% 161.68% at 0% 16.18%, #00CF9E 0%, #019A9A 100%) no-repeat center / cover
				.title--img
					width: 50%
					position: absolute
					right: 0
					bottom: 0
			@media (min-width: 768px)
				.title--img
					display: none

		&--desc
			font-family: Nunito, sans-serif
			font-size: 16px
			font-style: normal
			font-weight: 300
			line-height: 26px
			letter-spacing: 0em
			color: #000
			@media (max-width: 767.98px)
				padding: $gutter 
				margin: 0 
				max-width: none
				// width: 100%
				font-size: 14px
				line-height: 1.5

		&--btns
			justify-content: space-between
			margin: 0
			padding: 0
			@media (max-width: 767.98px)
				@include centerFlex(100%)
				flex-direction: column
				align-items: center
				padding-left: $gutter
				padding-right: $gutter
				*
					// max-width: 325px
					width: 100%
					margin: 0 0 10px !important
			@media (min-width: 768px)
				*
					width: 45%

		.form__main
			@include centerFlex(425px)
			margin: 0 !important
			padding: 0
			flex-direction: column
			position: relative

			// &.hidden

			input[type="password"],
			.main__accept,
			.form__main--check
				display: none

			input[type="email"]
				border: none

			input[type="submit"]
				position: absolute
				top: -10px
				left: 85%
				max-width: 55%
				border: none


			&--input
				width: 100%
				box-shadow: 7px 7px 30px -5px #0000007c
				font-family: Nunito, sans-serif
				font-size: 16px
				font-style: normal
				font-weight: 300
				line-height: 26px
				letter-spacing: 0em
				text-align: left
				background: #FFFFFF
				height: 55px
				padding: 0 20px
				margin: 10px auto 0
				border-radius: 70px
				color: #000
				border: 3px solid #fff
				@media (max-width: 767.98px)
					max-width: 330px
				&:focus-visible,
				&:focus
					border: 3px solid #019A9A !important
					outline: none
				&.error
					border-color: red


			&--check
				font-family: Montserrat, sans-serif
				font-size: 14px
				font-style: normal
				font-weight: 500
				line-height: 17px
				letter-spacing: 0em
				text-align: left
				color: #000
				margin: 10px 0
				text-decoration: underline
				cursor: pointer

			.main__accept
				max-width: 350px
				position: relative
				width: 100%
				margin: 5px 0
				font-family: Montserrat, sans-serif
				font-size: 14px
				font-style: normal
				font-weight: 300
				line-height: 17px
				letter-spacing: 0em
				text-align: left
				color: #000
				padding: 0 0 0 35px
				-webkit-user-select: none
				-moz-user-select: none
				-ms-user-select: none
				user-select: none
				@media (max-width: 767.98px)
					font-size: 12px
					line-height: 15px

				a, span.label--more
					cursor: pointer
					font-weight: 500
					color: #000

				&--checkbox
					position: absolute
					opacity: 0
					width: 0
					height: 0
					margin: 0

				input:checked ~ span.checkmark::after
					display: block

				.checkmark
					background-color: #FFFFFF
					position: absolute
					border: 1px solid #000
					height: 24px
					width: 24px
					left: 0
					top: 5px
					border-radius: 16px

					&::after
						content: ""
						display: none
						position: absolute
						width: 60%
						height: 60%
						margin: auto
						top: 0
						right: 0
						bottom: 0
						left: 0
						background: url(../img/check.svg) no-repeat center / cover

				.more--text
					display: none

			&--submit
				background: radial-gradient(91.66% 306.32% at 0% 16.18%, #01CC9D 0%, #019A9A 100%)
				border: 2px solid #FFFFFF
				box-sizing: border-box
				border-radius: 70px
				font-family: Nunito, sans-serif
				font-size: 18px
				font-style: normal
				font-weight: 800
				line-height: 25px
				letter-spacing: 0em
				text-align: center
				color: #FFFFFF
				height: 55px
				width: 100%
				margin: 20px 0
				cursor: pointer

		.form__main-with-modal
			@include centerFlex(425px)
			margin: 0 !important
			padding: 0
			flex-direction: column
			position: relative

			// &.hidden


			input[type="email"]
				border: none


			&--input
				width: 100%
				box-shadow: 7px 7px 30px -5px #00000026
				font-family: Nunito, sans-serif
				font-size: 16px
				font-style: normal
				font-weight: 300
				line-height: 26px
				letter-spacing: 0em
				text-align: left
				background: #FFFFFF
				height: 55px
				padding: 0 20px
				margin: 20px auto 0
				border-radius: 70px
				color: #000
				border: 3px solid #fff
				@media (max-width: 767.98px)
					max-width: 330px
				&:focus-visible,
				&:focus
					border: 3px solid #019A9A !important
					outline: none
				&.error
					border-color: red


			&--check
				font-family: Montserrat, sans-serif
				font-size: 14px
				font-style: normal
				font-weight: 500
				line-height: 17px
				letter-spacing: 0em
				text-align: left
				color: #000
				margin: 10px 0
				text-decoration: underline
				cursor: pointer

			.main__accept
				position: relative
				width: 100%
				margin: 5px 0
				font-family: Montserrat, sans-serif
				font-size: 14px
				font-style: normal
				font-weight: 300
				line-height: 17px
				letter-spacing: 0em
				text-align: left
				color: #000
				padding: 0 0 0 35px
				-webkit-user-select: none
				-moz-user-select: none
				-ms-user-select: none
				user-select: none
				@media (max-width: 767.98px)
					font-size: 12px
					line-height: 15px

				a, span.label--more
					cursor: pointer
					font-weight: 500
					color: #000

				&--checkbox
					position: absolute
					opacity: 0
					width: 0
					height: 0
					margin: 0

				input:checked ~ span.checkmark::after
					display: block

				.checkmark
					background-color: #FFFFFF
					position: absolute
					border: 1px solid #019A9A
					height: 20px
					width: 20px
					left: 0
					top: 1px
					border-radius: 16px

					&::after
						content: ""
						display: none
						position: absolute
						width: 60%
						height: 60%
						margin: auto
						top: 0
						right: 0
						bottom: 0
						left: 0
						background: url(../img/check.svg) no-repeat center / cover

				.more--text
					display: none

			&--submit
				background: radial-gradient(91.66% 306.32% at 0% 16.18%, #01CC9D 0%, #019A9A 100%)
				border: 2px solid #FFFFFF
				box-sizing: border-box
				border-radius: 70px
				font-family: Nunito, sans-serif
				font-size: 18px
				font-style: normal
				font-weight: 800
				line-height: 25px
				letter-spacing: 0em
				text-align: center
				color: #FFFFFF
				height: 55px
				width: 100%
				margin: 20px 0
				cursor: pointer

	&__hero
		@media (max-width: 767.98px)
			display: none
			order: 1
		@media (min-width: 768px) and (max-width: 1599.98px)
			right: 0
			margin: 0 0 0 30px
			max-width: 45%
		@media (min-width: 1600px)
			top: 60px
			width: 53%
			right: -141px
		@media (min-width: 768px)
			display: flex
			justify-content: flex-end
			align-items: center
			position: absolute
		
			margin: 0
			max-height: 410px
			background: radial-gradient(54.26% 161.68% at 0% 16.18%, #00CF9E 0%, #0F8763 100%)
			border-radius: 30px

			&--img
				max-width: 100%
				border-radius: 30px


	

.wrapper-acc-top2
	@include centerFlex(100%)
	// align-items: center
	margin: 0 0 20px
	padding: 0 !important
	border-bottom: 1px solid #BDBDBD
	@media (max-width: 768px)
		min-height: 425px
	@media (min-width: 768px)
		min-height: 425px

	&--title
		font-family: Nunito, sans-serif
		font-size: 40px
		font-style: normal
		font-weight: 300
		line-height: 55px
		letter-spacing: 0em
		text-align: center
		width: 90%
		margin: 0 auto
		@media (max-width: 767.98px)
			text-align: left
			top: 30px
			left: 45px
			max-width: 200px
			color: #fff
			position: absolute
			font-size: 20px
			line-height: 27px

	&--desc
		font-family: Nunito, sans-serif
		font-size: 18px
		font-style: normal
		font-weight: 300
		line-height: 30px
		letter-spacing: 0em
		text-align: center
		max-width: 940px
		padding-left: 20px
		padding-right: 20px
		@media (max-width: 767.98px)
			// max-width: 330px
			text-align: left
			font-size: 14px
			line-height: 18px
			margin: 50px auto 40px
			order: 2
		@media (min-width: 768px) and (max-width: 1599.98px)
			margin: 20px auto 9%
		@media (min-width: 1600px)
			margin: 20px auto 5%


	&__boxes
		@include centerFlex(100%)
		flex-wrap: wrap
		justify-content: space-around
		@media (max-width: 1000px)
			padding-left: $gutter
			padding-right: $gutter
			max-width: 100%
			order: 4
		@media (min-width: 1001px)
			margin: 0px 0 0
			max-width: 53%

		&--box
			width: 40%
			margin-bottom: 30px
			margin-top: 0
			@media (max-width: 767.98px)
				width: 100%
			span
				display: block
				&:nth-of-type(1)
					max-width: 246px
					font-family: Nunito, sans-serif
					font-size: 24px
					font-style: normal
					font-weight: 700
					line-height: 1.1
					letter-spacing: 0em
					text-align: left
					@media (max-width: 767.98px)
						max-width: 380px
						font-size: 18px
						line-height: 1.4em
						br
							display: none

				&:nth-of-type(2)
					margin-top: 1em
					max-width: 321px
					font-family: Nunito, sans-serif
					font-size: 14px
					font-style: normal
					font-weight: normal
					line-height: 1.5em
					letter-spacing: 0em
					text-align: left
					@media (max-width: 767.98px)
						max-width: 380px
						font-size: 14px
						line-height: 1.5em
						



	&__header
		padding-bottom: 30px
		@media (max-width: 767.98px)
			@include centerFlex(100%)
			flex-direction: column
			align-items: center
		@media (min-width: 768px)
			margin-left: 5vw
			@include centerBlock(45%)

		&--title
			width: 100%
			margin: 20px auto
			font-family: Nunito, sans-serif
			font-size: 40px
			font-style: normal
			font-weight: 300
			line-height: 55px
			letter-spacing: 0em
			color: #000
			@media (max-width: 767.98px)
				height: 36vw
				position: relative
				margin: 0
				width: 100%
				padding: 35px
				font-size: 20px
				line-height: 27px
				color: #fff
				span
					max-width: 70%
					display: block

				&:before
					content: ""
					position: absolute
					height: 100%
				&:before
					left: 0
					right: 0
					top: 0
					width: 100%
					z-index: -1
					background: radial-gradient(54.26% 161.68% at 0% 16.18%, #00CF9E 0%, #019A9A 100%) no-repeat center / cover
				.title--img
					width: 50%
					position: absolute
					right: 0
					bottom: 0
			@media (min-width: 768px)
				.title--img
					display: none

		&--desc
			font-family: Nunito, sans-serif
			font-size: 16px
			font-style: normal
			font-weight: 300
			line-height: 26px
			letter-spacing: 0em
			color: #000
			@media (max-width: 767.98px)
				padding: $gutter 
				margin: 0 
				max-width: none
				// width: 100%
				font-size: 14px
				line-height: 1.5

		&--btns
			justify-content: space-between
			margin: 0
			padding: 0
			@media (max-width: 767.98px)
				@include centerFlex(100%)
				flex-direction: column
				align-items: center
				padding-left: $gutter
				padding-right: $gutter
				*
					// max-width: 325px
					width: 100%
					margin: 0 0 10px !important
			@media (min-width: 768px)
				*
					width: 45%

		.form__main
			@include centerFlex(425px)
			margin: 0 !important
			padding: 0
			flex-direction: column
			position: relative

			// &.hidden

			input[type="password"],
			.main__accept,
			.form__main--check
				display: none

			input[type="email"]
				border: none

			input[type="submit"]
				position: absolute
				top: -10px
				left: 85%
				max-width: 55%
				border: none


			&--input
				width: 100%
				box-shadow: 7px 7px 30px -5px #0000007c
				font-family: Nunito, sans-serif
				font-size: 16px
				font-style: normal
				font-weight: 300
				line-height: 26px
				letter-spacing: 0em
				text-align: left
				background: #FFFFFF
				height: 55px
				padding: 0 20px
				margin: 10px auto 0
				border-radius: 70px
				color: #000
				border: 3px solid #fff
				@media (max-width: 767.98px)
					max-width: 330px
				&:focus-visible,
				&:focus
					border: 3px solid #019A9A !important
					outline: none


			&--check
				font-family: Montserrat, sans-serif
				font-size: 14px
				font-style: normal
				font-weight: 500
				line-height: 17px
				letter-spacing: 0em
				text-align: left
				color: #000
				margin: 10px 0
				text-decoration: underline
				cursor: pointer

			.main__accept
				max-width: 350px
				position: relative
				width: 100%
				margin: 5px 0
				font-family: Montserrat, sans-serif
				font-size: 14px
				font-style: normal
				font-weight: 300
				line-height: 17px
				letter-spacing: 0em
				text-align: left
				color: #000
				padding: 0 0 0 35px
				-webkit-user-select: none
				-moz-user-select: none
				-ms-user-select: none
				user-select: none
				@media (max-width: 767.98px)
					font-size: 12px
					line-height: 15px

				a, span.label--more
					cursor: pointer
					font-weight: 500
					color: #000

				&--checkbox
					position: absolute
					opacity: 0
					width: 0
					height: 0
					margin: 0

				input:checked ~ span.checkmark::after
					display: block

				.checkmark
					background-color: #FFFFFF
					position: absolute
					border: 1px solid #000
					height: 24px
					width: 24px
					left: 0
					top: 5px
					border-radius: 16px

					&::after
						content: ""
						display: none
						position: absolute
						width: 60%
						height: 60%
						margin: auto
						top: 0
						right: 0
						bottom: 0
						left: 0
						background: url(../img/check.svg) no-repeat center / cover

				.more--text
					display: none

			&--submit
				background: radial-gradient(91.66% 306.32% at 0% 16.18%, #01CC9D 0%, #019A9A 100%)
				border: 2px solid #FFFFFF
				box-sizing: border-box
				border-radius: 70px
				font-family: Nunito, sans-serif
				font-size: 18px
				font-style: normal
				font-weight: 800
				line-height: 25px
				letter-spacing: 0em
				text-align: center
				color: #FFFFFF
				height: 55px
				width: 100%
				margin: 20px 0
				cursor: pointer


		.form__main-with-modal
			@include centerFlex(425px)
			margin: 0 !important
			padding: 0
			flex-direction: column
			position: relative

			// &.hidden

			input[type="password"],
			input[type="text"],
			.btn-open-modal-form,
			.main__accept,
			.form__main-with-modal--check
				display: none

			input[type="email"]
				border: none

			input[type="submit"]
				position: absolute
				top: -10px
				left: 85%
				max-width: 55%
				border: none


			&--input
				width: 100%
				box-shadow: 7px 7px 30px -5px #00000026
				font-family: Nunito, sans-serif
				font-size: 16px
				font-style: normal
				font-weight: 300
				line-height: 26px
				letter-spacing: 0em
				text-align: left
				background: #FFFFFF
				height: 55px
				padding: 0 20px
				margin: 20px auto 0
				border-radius: 70px
				color: #000
				border: 3px solid #fff
				@media (max-width: 767.98px)
					max-width: 330px
				&:focus-visible,
				&:focus
					border: 3px solid #019A9A !important
					outline: none


			&--check
				font-family: Montserrat, sans-serif
				font-size: 14px
				font-style: normal
				font-weight: 500
				line-height: 17px
				letter-spacing: 0em
				text-align: left
				color: #000
				margin: 10px 0
				text-decoration: underline
				cursor: pointer

			.main__accept
				position: relative
				width: 100%
				margin: 5px 0
				font-family: Montserrat, sans-serif
				font-size: 14px
				font-style: normal
				font-weight: 300
				line-height: 17px
				letter-spacing: 0em
				text-align: left
				color: #000
				padding: 0 0 0 35px
				-webkit-user-select: none
				-moz-user-select: none
				-ms-user-select: none
				user-select: none
				@media (max-width: 767.98px)
					font-size: 12px
					line-height: 15px

				a, span.label--more
					cursor: pointer
					font-weight: 500
					color: #000

				&--checkbox
					position: absolute
					opacity: 0
					width: 0
					height: 0
					margin: 0

				input:checked ~ span.checkmark::after
					display: block

				.checkmark
					background-color: #FFFFFF
					position: absolute
					border: 1px solid #000
					height: 24px
					width: 24px
					left: 0
					top: 5px
					border-radius: 16px

					&::after
						content: ""
						display: none
						position: absolute
						width: 60%
						height: 60%
						margin: auto
						top: 0
						right: 0
						bottom: 0
						left: 0
						background: url(../img/check.svg) no-repeat center / cover

				.more--text
					display: none

			&--submit
				background: radial-gradient(91.66% 306.32% at 0% 16.18%, #01CC9D 0%, #019A9A 100%)
				border: 2px solid #FFFFFF
				box-sizing: border-box
				border-radius: 70px
				font-family: Nunito, sans-serif
				font-size: 18px
				font-style: normal
				font-weight: 800
				line-height: 25px
				letter-spacing: 0em
				text-align: center
				color: #FFFFFF
				height: 55px
				width: 100%
				margin: 20px 0
				cursor: pointer

	&__hero
		background: radial-gradient(43.63% 128.84% at 10.62% 27.13%, #019A9A 0%, #0083CC 100%) !important
		@media (max-width: 1000px)
			display: none
			order: 1
		@media (min-width: 768px) and (max-width: 1599.98px)
			right: 0
			margin: 0 0 0 30px
			max-width: 43%
		@media (min-width: 1600px)
			// top: 60px
			width: 53%
			right: -141px
		@media (min-width: 1001px)
			display: flex
			justify-content: flex-end
			align-items: center
			position: absolute
			bottom: 2%
		
			margin: 0
			max-height: 410px
			background: radial-gradient(54.26% 161.68% at 0% 16.18%, #00CF9E 0%, #0F8763 100%)
			border-radius: 30px

			&--img
				max-width: 100%
				border-radius: 30px



.wrapper-sec-top
	@include centerFlex(100%)
	align-items: center
	margin: 0 0 20px
	padding: 0 !important
	@media (max-width: 767.98px)
		overflow: hidden
	@media (min-width: 768px)
		min-height: 535px
		border-bottom: 1px solid #BDBDBD
	@media (min-width: 768px) and (max-width: 1599.98px)
		margin: 2.5vw 0 0
		align-items: stretch
		// padding: 0 0 5vw !important

	&__header
		@media (max-width: 767.98px)
			@include centerFlex(100%)
			flex-direction: column
			align-items: center
		@media (min-width: 768px)
			margin: 40px 4vw 0
			@include centerFlex(46%)
			flex-direction: column
			justify-content: center

		&--title
			width: 100%
			margin: 20px auto
			font-family: Nunito, sans-serif
			font-size: 40px
			font-style: normal
			font-weight: 300
			line-height: 55px
			letter-spacing: 0em
			color: #000
			@media (max-width: 767.98px)
				height: 36vw
				position: relative
				margin: 0
				width: 100%
				padding: 25px 35px
				font-size: 20px
				line-height: 27px
				color: #fff

				&:before
					content: ""
					position: absolute
					height: 100%
				&:before
					left: 0
					right: 0
					top: 0
					width: 100%
					z-index: -1
					background: radial-gradient(54.26% 161.68% at 0% 16.18%, #00CF9E 0%, #019A9A 100%) no-repeat center / cover
				span
					max-width: 70%
					display: block
				.title--img
					width: 42%
					position: absolute
					right: -40px
					top: -10px
			@media (min-width: 768px)
				.title--img
					display: none

		&--desc
			font-family: Nunito, sans-serif
			font-size: 16px
			font-style: normal
			font-weight: 300
			line-height: 26px
			letter-spacing: 0em
			color: #000
			@media (max-width: 767.98px)
				padding: 35px 0
				margin: 0 20px
				max-width: 325px
				font-size: 12px
				line-height: 16px

		&--btns
			justify-content: space-between
			margin: 0
			padding: 0
			@media (max-width: 767.98px)
				@include centerFlex(100%)
				flex-direction: column
				align-items: center
				*
					max-width: 325px
					width: 100%
					margin: 0 0 10px !important
			@media (min-width: 768px)
				*
					width: 45%

		.form__main
			@include centerFlex(425px)
			margin: 0 !important
			padding: 0
			flex-direction: column
			position: relative
			@media (max-width: 767.98px)
				margin: 80px 0 40px !important
				@include centerFlex(330px)

			input[type="password"],
			.main__accept,
			.form__main--check
				display: none

			input[type="email"]
				border: none

			input[type="submit"]
				position: absolute
				top: -10px
				border: none
				@media (max-width: 767.98px)
					max-width: 150px
					font-size: 12px
					left: 56%
				@media (min-width: 768px)
					max-width: 55%
				@media (min-width: 768px) and (max-width: 1599.98px)
					left: 20vw
				@media (min-width: 1600px)
					left: 85%


			&--input
				width: 100%
				box-shadow: 0px 0px 30px 0px #00000026
				font-family: Nunito, sans-serif
				font-size: 16px
				font-style: normal
				font-weight: 300
				line-height: 26px
				letter-spacing: 0em
				text-align: left
				background: #FFFFFF
				height: 55px
				padding: 0 20px
				margin: 10px auto 0
				border-radius: 70px
				color: #000
				border: 3px solid #fff
				@media (max-width: 767.98px)
					max-width: 330px
				&:focus
					border: 3px solid #019A9A !important


			&--check
				font-family: Montserrat, sans-serif
				font-size: 14px
				font-style: normal
				font-weight: 500
				line-height: 17px
				letter-spacing: 0em
				text-align: left
				color: #000
				margin: 10px 0
				text-decoration: underline
				cursor: pointer

			.main__accept
				max-width: 350px
				position: relative
				width: 100%
				margin: 5px 0
				font-family: Montserrat, sans-serif
				font-size: 14px
				font-style: normal
				font-weight: 300
				line-height: 17px
				letter-spacing: 0em
				text-align: left
				color: #000
				padding: 0 0 0 35px
				-webkit-user-select: none
				-moz-user-select: none
				-ms-user-select: none
				user-select: none
				@media (max-width: 767.98px)
					font-size: 12px
					line-height: 15px

				a, span.label--more
					cursor: pointer
					font-weight: 500
					color: #000

				&--checkbox
					position: absolute
					opacity: 0
					width: 0
					height: 0
					margin: 0

				input:checked ~ span.checkmark::after
					display: block

				.checkmark
					background-color: #FFFFFF
					position: absolute
					border: 1px solid #000
					height: 24px
					width: 24px
					left: 0
					top: 5px
					border-radius: 16px

					&::after
						content: ""
						display: none
						position: absolute
						width: 60%
						height: 60%
						margin: auto
						top: 0
						right: 0
						bottom: 0
						left: 0
						background: url(../img/check.svg) no-repeat center / cover

				.more--text
					display: none

			&--submit
				background: radial-gradient(91.66% 306.32% at 0% 16.18%, #01CC9D 0%, #019A9A 100%)
				border: 2px solid #FFFFFF
				box-sizing: border-box
				border-radius: 70px
				font-family: Nunito, sans-serif
				font-size: 18px
				font-style: normal
				font-weight: 800
				line-height: 25px
				letter-spacing: 0em
				text-align: center
				color: #FFFFFF
				height: 55px
				width: 100%
				margin: 20px 0
				cursor: pointer

		.form__main-with-modal
			@include centerFlex(425px)
			margin: 0 !important
			padding: 0
			flex-direction: column
			position: relative
			@media (max-width: 767.98px)
				margin: 80px 0 40px !important
				@include centerFlex(330px)

			input[type="password"],
			input[type="text"],
			.btn-open-modal-form,
			.main__accept,
			.form__main-with-modal--check
				display: none

			input[type="email"]
				border: none

			input[type="submit"]
				position: absolute
				top: 0
				border: none
				@media (max-width: 767.98px)
					max-width: 150px
					font-size: 12px
					left: 56%
				@media (min-width: 768px)
					max-width: 55%
				@media (min-width: 768px) and (max-width: 1599.98px)
					left: 20vw
				@media (min-width: 1600px)
					left: 85%


			&--input
				width: 100%
				box-shadow: 0px 0px 30px 0px #00000026
				font-family: Nunito, sans-serif
				font-size: 16px
				font-style: normal
				font-weight: 300
				line-height: 26px
				letter-spacing: 0em
				text-align: left
				background: #FFFFFF
				height: 55px
				padding: 0 20px
				margin: 20px auto 0
				border-radius: 70px
				color: #000
				border: 3px solid #fff
				@media (max-width: 767.98px)
					max-width: 330px
				&:focus
					border: 3px solid #019A9A !important


			&--check
				font-family: Montserrat, sans-serif
				font-size: 14px
				font-style: normal
				font-weight: 500
				line-height: 17px
				letter-spacing: 0em
				text-align: left
				color: #000
				margin: 10px 0
				text-decoration: underline
				cursor: pointer

			.main__accept
				position: relative
				width: 100%
				margin: 5px 0
				font-family: Montserrat, sans-serif
				font-size: 14px
				font-style: normal
				font-weight: 300
				line-height: 17px
				letter-spacing: 0em
				text-align: left
				color: #000
				padding: 0 0 0 35px
				-webkit-user-select: none
				-moz-user-select: none
				-ms-user-select: none
				user-select: none
				@media (max-width: 767.98px)
					font-size: 12px
					line-height: 15px

				a, span.label--more
					cursor: pointer
					font-weight: 500
					color: #000

				&--checkbox
					position: absolute
					opacity: 0
					width: 0
					height: 0
					margin: 0

				input:checked ~ span.checkmark::after
					display: block

				.checkmark
					background-color: #FFFFFF
					position: absolute
					border: 1px solid #000
					height: 24px
					width: 24px
					left: 0
					top: 5px
					border-radius: 16px

					&::after
						content: ""
						display: none
						position: absolute
						width: 60%
						height: 60%
						margin: auto
						top: 0
						right: 0
						bottom: 0
						left: 0
						background: url(../img/check.svg) no-repeat center / cover

				.more--text
					display: none

			&--submit
				background: radial-gradient(91.66% 306.32% at 0% 16.18%, #01CC9D 0%, #019A9A 100%)
				border: 2px solid #FFFFFF
				box-sizing: border-box
				border-radius: 70px
				font-family: Nunito, sans-serif
				font-size: 18px
				font-style: normal
				font-weight: 800
				line-height: 25px
				letter-spacing: 0em
				text-align: center
				color: #FFFFFF
				height: 55px
				width: 100%
				margin: 20px 0
				cursor: pointer

	&__hero
		@media (max-width: 767.98px)
			display: none
		@media (min-width: 768px) and (max-width: 1599.98px)
			right: 0
			width: 48%

			&--img
				max-width: 100%
				margin-top: 9vw

		@media (min-width: 1600px)
			top: 60px
			width: 53%
			right: -141px

			&--img
				max-width: 100%
				margin-top: 22%

		@media (min-width: 768px)
			display: flex
			justify-content: flex-end
			align-items: center
			position: absolute
			background: radial-gradient(54.26% 161.68% at 0% 16.18%, #00CF9E 0%, #019A9A 100%) no-repeat center / cover
			border-top-left-radius: 20px
			border-bottom-left-radius: 20px
			margin: 0
			max-height: 350px

.wrapper-ent-top
	@include centerFlex(100%)
	margin: 0
	padding: 0 !important
	align-items: flex-end
	@media (max-width: 767.98px)
		overflow: hidden
	@media (min-width: 768px)
		min-height: 445px
		// border-bottom: 1px solid #BDBDBD
	@media (min-width: 768px) and (max-width: 1299.98px)
		margin: 2.5vw 0 0
		// align-items: stretch
		padding: 0 0 0px !important
	@media (min-width: 1300px) and (max-width: 1599.98px)
		// align-items: stretch

	&__header
		
		@media (max-width: 767.98px)
			@include centerFlex(100%)
			flex-direction: column
			align-items: center
		@media (min-width: 768px)
			margin: 0px 4vw 40px
			@include centerFlex(46%)
			flex-direction: column
			justify-content: center
			align-self: center

		&-inner
			width: 100%
			position: relative
			z-index: 10
			@media (max-width: 767.98px)
				min-height: 36vw
				padding-bottom: 10px
				&:before
					content: ''
					position: absolute
					left: 0
					right: 0
					top: 0
					bottom: 0
					width: 100%
					z-index: -1
		
		&-inner.bg-h-green
			.title--img
				width: 67%
				position: absolute
				right: -40px
				&.bottom-2
					bottom: -8vw
				&.stuck-bottom
					bottom: 0
				&Bottom-3
					width: 80px
					position: relative
					top: -60px
					left: 18px
					display: none
					@media (max-width: 768px)
						display: block
				@media (min-width: 768px)	
					display: none
			@media (max-width: 767.98px)
				&:before
					background: radial-gradient(54.26% 161.68% at 0% 16.18%, #00CF9E 0%, #019A9A 100%) no-repeat center / cover
		&-inner.bg-h-blue
			.title--img
				width: 67%
				position: absolute
				right: -40px
				bottom: -8vw
				@media (min-width: 768px)	
					display: none
			@media (max-width: 767.98px)
				&:before
					background: radial-gradient(54.26% 161.68% at 0% 16.18%, #019A9A 0%, #0083CC 100%) no-repeat center / cover

		&--title
			width: 100%
			margin: 20px 0 10px
			font-family: Nunito, sans-serif
			font-size: 30px
			font-style: normal
			font-weight: normal
			line-height: 40px
			letter-spacing: 0em
			color: #000
			@media (max-width: 767.98px)
				// height: 36vw
				position: relative
				margin: 0
				width: 100%
				padding: 25px 27px 10px
				font-size: 20px
				line-height: 27px
				color: #fff

				&:before
					content: ""
					position: absolute
					height: 100%
				// span,div
				// 	max-width: 70%
				// 	display: block
				

			ul
				font-family: Nunito, sans-serif
				font-size: 16px
				font-weight: 300
				line-height: 1.4em
				font-style: normal
				margin: 5px 0 0 
				padding-left: 28px
				list-style-image: url(../img/Vector1.png)
				@media (max-width: 767.98px)
					font-size: 12px
					line-height: 16px
					filter: brightness(1.5)


		&--desc
			font-family: Nunito, sans-serif
			font-size: 16px
			font-style: normal
			font-weight: 300
			line-height: 26px
			letter-spacing: 0em
			color: #000
			margin-top: 0
			@media (max-width: 767.98px)
				padding: 10px 27px 70px
				padding-right: 39%
				margin: 0
				max-width: none
				font-size: 12px
				line-height: 16px
				color: #fff
				
			// @media (max-width: 700.98px)
			// 	padding-right: 35%
				
			@media (max-width: 367.98px)
				padding-right: 15%

		&--btns
			justify-content: space-between
			margin: 0
			padding: 0
			@media (max-width: 767.98px)
				@include centerFlex(100%)
				flex-direction: column
				align-items: center
				*
					max-width: 325px
					width: 100%
					margin: 0 0 10px !important
			@media (min-width: 768px)
				*
					width: 45%

		.form__main
			@include centerFlex(525px)
			margin: 0 !important
			padding: 0
			flex-direction: column
			position: relative
			z-index: 5
			

			@media (max-width: 767.98px)
				margin: 20px auto 40px !important
				padding-left: $gutter
				padding-right: $gutter
				// @include centerFlex(360px)
			&:not(.form-top--expanded)
				input[type="submit"]
					width: auto
			&.form-top--expanded
				input[type="submit"]
					margin-top: 10px
					position: static

			input[type="password"],
			.main__accept,
			.form__main--check
				display: none

			input[type="password"],
			input[type="email"]
				border: 1px solid #eee
				&.error
					border-color: red

			input[type="submit"]
				position: absolute
				top: -10px
				border: none
				width: 100%
				right: $gutter
				padding-right: 1em
				padding-left: 1em
				@media (min-width: 768px)
					right: -1px

			&--input
				width: 100%
				box-shadow: 7px 7px 35px -5px #00000063
				font-family: Nunito, sans-serif
				font-size: 16px
				font-style: normal
				font-weight: 300
				line-height: 26px
				letter-spacing: 0em
				text-align: left
				background: #FFFFFF
				height: 55px
				padding: 0 20px
				margin: 10px auto 0
				border-radius: 70px
				color: #000
				border: 3px solid #fff
				// @media (max-width: 767.98px)
				// 	max-width: 330px
				&:focus-visible,
				&:focus
					border: 3px solid #019A9A
					outline: none


			&--check
				font-family: Nunito, sans-serif
				font-size: 12px
				font-style: normal
				font-weight: 500
				line-height: 17px
				letter-spacing: 0em
				text-align: left
				color: #767676
				margin: 0 0 10px
				text-decoration: underline
				cursor: pointer
				&.error
					border-color: red

			.main__accept
				max-width: 350px
				position: relative
				width: 100%
				margin: 5px 0
				font-family: Nunito, sans-serif
				font-size: 12px
				font-style: normal
				font-weight: 300
				line-height: 17px
				letter-spacing: 0em
				text-align: left
				color: #767676
				padding: 0 0 0 35px
				-webkit-user-select: none
				-moz-user-select: none
				-ms-user-select: none
				user-select: none
				@media (max-width: 767.98px)
					font-size: 12px
					line-height: 15px

				a, span.label--more
					cursor: pointer
					font-weight: 500
					color: #000

				&--checkbox
					position: absolute
					opacity: 0
					width: 0
					height: 0
					margin: 0

				input:checked ~ span.checkmark::after
					display: block
				input.error ~ span.checkmark
					border-color: red

				.checkmark
					background-color: #FFFFFF
					position: absolute
					border: 1px solid #019A9A
					height: 20px
					width: 20px
					left: 0
					top: 1px
					border-radius: 16px

					&::after
						content: ""
						display: none
						position: absolute
						width: 60%
						height: 60%
						margin: auto
						top: 0
						right: 0
						bottom: 0
						left: 0
						background: url(../img/check.svg) no-repeat center / cover

				.more--text
					display: none

			&--submit
				&:not(.btn-blue):not(.btn-green)
					background: radial-gradient(91.66% 306.32% at 0% 16.18%, #01CC9D 0%, #019A9A 100%)
					
				border: 2px solid #FFFFFF
				box-sizing: border-box
				border-radius: 70px
				font-family: Nunito, sans-serif
				font-size: 18px
				font-style: normal
				font-weight: 700
				line-height: 25px
				letter-spacing: 0em
				text-align: center
				color: #FFFFFF
				height: 55px
				width: 100%
				margin: 20px 0
				cursor: pointer
				transition: 333ms
				@media (max-width:786px)
					font-size: 14px
				&:hover
					filter: brightness(1.1)

		.form__main-with-modal
			@include centerFlex(525px)
			margin: 0 !important
			padding: 0
			flex-direction: column
			position: relative
			z-index: 5


			@media (max-width: 767.98px)
				margin: 20px auto 40px !important
				padding-left: $gutter
				padding-right: $gutter
			// @include centerFlex(360px)
			&:not(.form-top--expanded)
				input[type="submit"]
					width: auto !important
					padding: 0 10%
			&.form-top--expanded
				input[type="submit"]
					margin-top: 20px
					position: static
				.btn-open-modal-form
					display: block

			input[type="password"],
			input[type="text"],
			.btn-open-modal-form,
			.main__accept,
			.form__main-with-modal--check
				display: none

			input[type="password"],
			input[type="text"],
			input[type="email"]
				border: 1px solid #eee
				&.error
					border-color: red

			input[type="submit"]
				position: absolute
				top: 0
				border: none
				width: 100%
				right: $gutter
				padding-right: 1em
				padding-left: 1em
				@media (min-width: 768px)
					right: -1px

			&--input
				width: 100%
				box-shadow: 7px 7px 35px -5px #00000026
				font-family: Nunito, sans-serif
				font-size: 16px
				font-style: normal
				font-weight: 300
				line-height: 26px
				letter-spacing: 0em
				text-align: left
				background: #FFFFFF
				height: 55px
				padding: 0 20px
				margin: 20px auto 0
				border-radius: 70px
				color: #000
				border: 3px solid #fff
				// @media (max-width: 767.98px)
				// 	max-width: 330px
				&:focus-visible,
				&:focus
					border: 3px solid #019A9A
					outline: none


			&--check
				font-family: Nunito, sans-serif
				font-size: 12px
				font-style: normal
				font-weight: 500
				line-height: 17px
				letter-spacing: 0em
				text-align: left
				color: #767676
				margin: 0 0 10px
				text-decoration: underline
				cursor: pointer
				&.error
					border-color: red

			.main__accept
				position: relative
				width: 100%
				margin: 5px 0
				font-family: Nunito, sans-serif
				font-size: 12px
				font-style: normal
				font-weight: 300
				line-height: 17px
				letter-spacing: 0em
				text-align: left
				color: #767676
				padding: 0 0 0 35px
				-webkit-user-select: none
				-moz-user-select: none
				-ms-user-select: none
				user-select: none
				@media (max-width: 767.98px)
					font-size: 12px
					line-height: 15px

				a, span.label--more
					cursor: pointer
					font-weight: 500
					color: #000

				&--checkbox
					position: absolute
					opacity: 0
					width: 0
					height: 0
					margin: 0

				input:checked ~ span.checkmark::after
					display: block
				input.error ~ span.checkmark
					border-color: red

				.checkmark
					background-color: #FFFFFF
					position: absolute
					border: 1px solid #019A9A
					height: 20px
					width: 20px
					left: 0
					top: 1px
					border-radius: 16px

					&::after
						content: ""
						display: none
						position: absolute
						width: 60%
						height: 60%
						margin: auto
						top: 0
						right: 0
						bottom: 0
						left: 0
						background: url(../img/check.svg) no-repeat center / cover

				.more--text
					display: none

			&--submit
				&:not(.btn-blue):not(.btn-green)
					background: radial-gradient(91.66% 306.32% at 0% 16.18%, #01CC9D 0%, #019A9A 100%)

				border: 2px solid #FFFFFF
				box-sizing: border-box
				border-radius: 70px
				font-family: Nunito, sans-serif
				font-size: 18px
				font-style: normal
				font-weight: 700
				line-height: 25px
				letter-spacing: 0em
				text-align: center
				color: #FFFFFF
				height: 55px
				width: 100%
				margin: 20px 0
				cursor: pointer
				transition: 333ms
				@media (max-width:786px)
				font-size: 14px
				&:hover
					filter: brightness(1.1)

	
	&__hero-inner
		.wrapper-ent-top__hero--img.radius
			border-radius: 20px
		.wrapper-ent-top__hero--img.contain
			width: 100%
		&.bg-h-green
			&::before
				content: ''
				border-radius: 20px
				mix-blend-mode: multiply
				background: radial-gradient(54.26% 161.68% at 0% 16.18%, #00CF9E 0%, #019A9A 100%) no-repeat center / cover
				position: absolute
				top: 0
				left: 0
				right: 0
				bottom: 0
				opacity: 1

	&__hero
		padding-bottom: 25%
		align-items: flex-end
		// transform: translateY(-10%)
		margin-bottom: 7.5%
		flex-shrink: 0
		transform: translateY(-19.1%)
		&--mof
			position: absolute
			z-index: 100
			left: -64px
			top: 10px
			width: 139px
			@media (max-width: 991px)
				transform: scale(.8)
				left: -10px
				top: -10px
		&--img
			position: absolute
			bottom: -49.4%
			right: 0
			// width: 130%
			width: 120%
			&.bottom-1
				// bottom: -20%
				bottom: -19.5%
			&.stuck-bottom
				bottom: 0px
		@media (max-width: 767.98px)
			display: none
		@media (min-width: 768px) and (max-width: 1599.98px)
			// right: 0
			width: 48%

			// &--img
				// max-width: 100%
				// margin-top: 9vw

		@media (min-width: 1600px)
			// top: 60px
			width: 48%
			// right: -141px

			// &--img
				// max-width: 100%
				// margin-top: 22%


		@media (min-width: 768px)
			display: flex
			justify-content: flex-end
			// align-items: center
			// position: absolute
			position: relative
			bottom: 0
			right: 0
			background: radial-gradient(54.26% 161.68% at 0% 16.18%, #00CF9E 0%, #019A9A 100%) no-repeat center / cover
			border-radius: 20px
			margin: 0
			// max-height: 375px

.wrapper-acc-price-top
	@include centerFlex(100%)
	align-items: center
	margin: 0 0 20px
	padding: 0 !important
	border-bottom: 1px solid #BDBDBD
	@media (min-width: 768px)
		min-height: 535px

	&__header
		@media (max-width: 767.98px)
			@include centerFlex(100%)
			flex-direction: column
			align-items: center
		@media (min-width: 768px)
			margin-left: 5%
			@include centerBlock(37%)

		&--title
			width: 100%
			margin: 20px auto
			font-family: Nunito, sans-serif
			font-size: 40px
			font-style: normal
			font-weight: 300
			line-height: 55px
			letter-spacing: 0em
			color: #000
			display: block
			p
				margin: 0
			@media (max-width: 767.98px)
				height: 46vw
				position: relative
				margin: 0
				width: 100%
				padding: 35px
				font-size: 20px
				line-height: 27px
				color: #fff
				p
					max-width: 65%

				&:after,
				&:before
					content: ""
					position: absolute
					height: 100%
				&:before
					left: 0
					right: 0
					top: 0
					width: 100%
					z-index: -1
					background: radial-gradient(43.63% 128.84% at 10.62% 27.13%, #019A9A 0%, #0083CC 100%)
				&:after
					right: 0
					bottom: 0
					width: 50%
					background: url(../img/about.png) no-repeat right bottom / cover

		&--desc
			max-width: 455px
			font-family: Nunito, sans-serif
			font-size: 16px
			font-style: normal
			font-weight: 300
			line-height: 26px
			letter-spacing: 0em
			color: #000
			@media (max-width: 767.98px)
				padding: 35px 0
				margin: 0 20px
				max-width: 325px
				font-size: 12px
				line-height: 16px

	&__hero
		@media (max-width: 767.98px)
			display: none
		@media (min-width: 768px) and (max-width: 1600px)
			right: 0
			width: 50%
		@media (min-width: 1600px)
			top: 60px
			width: 65%
			right: -150px
		@media (min-width: 768px)
			display: flex
			justify-content: flex-end
			align-items: center
			position: absolute
			background: radial-gradient(54.26% 161.68% at 0% 16.18%, #00CF9E 0%, #019A9A 100%) no-repeat center / cover
			mix-blend-mode: multiply
			border-top-left-radius: 20px
			border-bottom-left-radius: 20px
			margin: 0
			max-height: 475px
			&:after
				content: ""
				border-top-left-radius: 20px
				border-bottom-left-radius: 20px
				position: absolute
				top: 0
				left: 0
				width: 100%
				height: 100%
				background: url(../img/about.jpg) no-repeat center / cover
				mix-blend-mode: multiply
				z-index: -1

			&--img
				max-width: 100%

.wrapper-vid
	margin: 0
	padding: 0
	width: 100%
	@media (max-width:767.98px)
		display: none
	video
		width: 100%
		display: block
		margin: auto
		background: #eee
		// max-width: 970px

	
.wrapper-icons.wrapper-icons--light-bg
	.wrapper-icons__box 
		.icons--dot
			background-color: #000
			color: #000
			&:before
				background-color: #000
			&:after
				color: #000
.wrapper-icons	
	padding-top: 20px

	@include centerFlex(1300px)
	justify-content: center
	margin: 0 auto !important
	flex-wrap: wrap
	z-index: 1
	@media (max-width: 767.98px)
		flex-direction: column
		align-items: center

	&--title
		width: 100%
		font-family: Nunito, sans-serif
		font-size: 44px
		font-style: normal
		font-weight: 300
		line-height: 49px
		letter-spacing: 0em
		margin:  40px 0 20px
		text-align: center
		color: #000
		@media (max-width: 767.98px)
			font-size: 24px
			line-height: 1.4em
			font-weight: 300
			margin: 20px 0


	&--subtitle
		width: 100%
		font-family: Nunito, sans-serif
		font-size: 24px
		font-style: normal
		font-weight: 300
		line-height: 49px
		letter-spacing: 0em
		margin:  0 0 40px
		text-align: center
		color: #000
		@media (max-width: 767.98px)
			font-size: 16px
			line-height: 25px
			font-weight: 300
			margin: 0 0 40px

	&--desc
		font-family: Montserrat, sans-serif
		font-size: 16px
		font-style: normal
		font-weight: 300
		line-height: 24px
		letter-spacing: 0em
		text-align: center
		margin: 0 0 40px
		color: #000
		width: 100%
		@media (max-width: 767.98px)
			font-size: 12px

	&__box
		margin: 0 0 20px
		flex-direction: column
		align-items: center
		position: relative
		@media (max-width: 767.98px)
			@include centerFlex(380px)
			&:last-of-type
				.icons--dot
					display: none
		@media (min-width: 768px)
			@include centerFlex(25%)

			&:first-of-type
				.icons--dot:after
					color: #fff
					display: none
				.icons--dot:before
					background-color: #fff
					display: none


		&--ico
			align-items: center
			justify-content: center
			align-content: center
			height: 75px
			margin: 0
			color: #000
			background-size: cover
			background-position: center
			background-repeat: no-repeat
			padding: 0 0 12px
			@media (max-width: 767.98px)
				@include centerFlex(85px)
				order: 1
			@media (min-width: 768px)
				@include centerFlex(100%)

			img
				max-height: 100%
				max-width: 100%

		.icons--dot
			height: 11px
			width: 11px
			background-color: #fff
			border-radius: 100%
			margin: 0 auto
			position: relative
			@media (max-width: 767.98px)
				order: 4
				margin: 0 0 50px 0

				&:before
					content: ""
					width: 1px
					height: 45px
					background-color: #fff
					position: absolute
					right: 5px
					top: 25px
				&:after
					position: absolute
					content: "ᐯ"
					color: #fff
					top: 60px
					right: 1.5px
					font-size: 10px

			@media (min-width: 768px)
				&:before
					content: ""
					max-width: 280px
					width: 19vw
					height: 1px
					background-color: #fff
					position: absolute
					right: 28px
					top: 5px
				&:after
					position: absolute
					content: "ᐳ"
					top: -2px
					right: 25px
					font-size: 10px
		.icons--last-dot
			height: 11px
			width: 11px
			// background-color: #fff
			border-radius: 100%
			margin: 0 auto
			position: absolute
			bottom: 0px
			@media (max-width: 767.98px)
				order: 4
				margin: 0 0 50px 0

				&:before
					display: none
			

			@media (min-width: 768px)
				&:before
					content: ""
					max-width: 280px
					width: 19vw
					height: 1px
					background-color: #a4a4a4
					position: absolute
					left: 23px
					top: 5px
				



		&--title
			font-family: Montserrat, sans-serif
			font-size: 16px
			font-style: normal
			line-height: 24px
			letter-spacing: 0em
			text-align: center
			font-weight: 700
			color: #000
			@media (max-width: 767.98px)
				font-size: 14px
				order: 2


		&--desc
			font-family: Montserrat, sans-serif
			font-size: 16px
			font-style: normal
			font-weight: 300
			line-height: 24px
			letter-spacing: 0em
			text-align: center
			margin: 0 0 10px
			color: #000
			@media (max-width: 767.98px)
				font-size: 12px
				order: 3
			@media (min-width: 768px)
				max-width: 80%
			strong
				display: block
				margin: 0 0 5px
				font-weight: 700
				@media (max-width: 767.98px)
					font-size: 14px

	&--btns
		justify-content: space-between
		margin: 0
		padding: 0
		@media (max-width: 767.98px)
			@include centerFlex(100%)
			flex-direction: column
			align-items: center
			*
				max-width: 325px
				width: 100%
				margin: 0 0 10px !important

.wrapper-boxes
	@include centerFlex(1200px)
	justify-content: center
	margin: 0 auto !important
	padding: 0 20px
	flex-wrap: wrap

	.boxes__head
		@include centerBlock(100%)
		margin: 0 auto !important
		padding: 0

		&--title
			font-family: Nunito, sans-serif
			font-size: 36px
			font-style: normal
			font-weight: 300
			line-height: 49px
			letter-spacing: 0em
			text-align: center
			margin: 0 auto 40px
			max-width: 890px
			@media (max-width: 767.98px)
				max-width: 335px
				font-size: 18px
				line-height: 25px
				font-weight: 300
				margin: 20px auto 40px


	.boxes-main
		@include centerFlex(100%)
		margin: 0 auto !important
		padding: 0
		flex-wrap: wrap
		@media (max-width: 767.98px)
			flex-direction: column
			align-items: center
		@media (min-width: 768px)
			justify-content: space-between

		&__post
			@include centerFlex(24%)
			margin: 0 0 20px
			@media (max-width: 767.98px)
				@include centerFlex(380px)
				border-bottom: 2px solid #D8D8D8
			@media (min-width: 768px)
				flex-direction: column

			&--ico
				align-items: center
				height: 75px
				margin: 0 0 15px
				background-size: cover
				background-position: center
				background-repeat: no-repeat
				padding: 0 0 15px
				@media (max-width: 767.98px)
					@include centerFlex(85px)
				@media (min-width: 768px)
					@include centerFlex(100%)
					border-bottom: 2px solid #D8D8D8

				img
					max-height: 100%
					max-width: 100%

			&--title
				font-family: Montserrat, sans-serif
				font-size: 16px
				font-style: normal
				line-height: 24px
				letter-spacing: 0em
				text-align: left
				@media (max-width: 767.98px)
					font-size: 14px


			&--desc
				font-family: Montserrat, sans-serif
				font-size: 16px
				font-style: normal
				font-weight: 300
				line-height: 24px
				letter-spacing: 0em
				text-align: left
				margin: 0 0 20px
				@media (max-width: 767.98px)
					font-size: 12px
				strong
					display: block
					margin: 0 0 5px
					font-weight: 700
					@media (max-width: 767.98px)
						font-size: 14px

.wrapper-imgbox
	@include centerFlex(100%)
	padding: 0 20px
	@media (min-width: 768px)
		margin: 0 0 20px
		min-height: 440px
		// @media (max-width: 1599.98px)
		// overflow: hidden
		&.flex-end
			justify-content: flex-end
			padding: 0 !important
			article
				margin-right: 5vw !important
		// @media (min-width: 768px) and (max-width: 1599.98px)
		&.margin-auto-top
			margin: -50px 0 !important
			figure
				margin: auto 0
			article
				margin: auto 0 auto 5vw

	@media (max-width: 767.98px)
		flex-wrap: wrap
		margin: 20px 0 20px
		&.new-order
			figure
				order: 1
				height: 250px
				top: 0
			article
				order: 2
				.wrapper-imgbox__header--title
					margin: 10px 0px 10px


	&__header
		margin: auto
		height: 100%
		position: relative
		z-index: 3
		@media (max-width: 767.98px)
			@include centerFlex(100%)
			flex-direction: column
			align-items: center
			order: 2
		@media (min-width: 768px)
			padding: 0 20px 0 20px
			@include centerBlock(45%)

		// img
		// 	@media (max-width: 767.98px)
		// 		max-width: 335px
		// 		margin: 30px auto
		// 	@media (min-width: 768px)
		// 		display: none

		&--title
			width: 100%
			margin: 20px auto
			font-family: Nunito, sans-serif
			font-size: 24px
			font-style: normal
			font-weight: 700
			line-height: 32px
			letter-spacing: 0em
			color: #000
			@media (min-width: 768px)
				&.bigger
					font-size: 40px
					line-height: 55px
			@media (max-width: 767.98px)
				// text-align: center
				padding: 0
				margin: 0 0px 30px
				max-width: 100%
				font-size: 24px
				line-height: 1.3em
				// aspect-ratio: 1.2

		// &--desc
		// 	@media (max-width: 767.98px)
		// 		text-align: center

		&--desc,
		&--list
			max-width: 455px
			font-family: Nunito, sans-serif
			font-size: 16px
			font-style: normal
			font-weight: 300
			line-height: 24px
			letter-spacing: 0em
			color: #000
			@media (max-width: 767.98px)
				padding: 0
				margin: 0 0px 20px
				max-width: 100%
				width: 100%
				font-size: 14px
				line-height: 20px

			ul,ol
				text-align: left !important
				padding: 0 0 0 20px
				margin-top: 0

		&--list
			padding: 0 0 0 20px

		&--btns
			justify-content: space-between
			margin: 0
			padding: 0
			@media (min-width: 768px)
				width: 120%
			@media (max-width: 767.98px)
				@include centerFlex(100%)
				flex-direction: column
				align-items: center
				*
					max-width: none
					width: 100%
					margin: 0 0 10px !important
			// @media (min-width: 768px)
			// 	*
			// 		width: 45%

	&__hero
		display: flex
		justify-content: flex-start
		align-items: center
		margin: auto
		overflow: visible
		position: relative
		height: 100%
		width: 50%

		
		&--img
			max-width: 100%
			// width: 110%
			position: absolute
			top: 0
			right: 0
			bottom: 0
			left: 0
			margin: auto

		@media (max-width: 767.98px)
			order: 1	
			width: 100%
			max-width: 335px
			top: 0px
			position: relative
			padding-bottom: 80%
			// aspect-ratio: 1.2
			// &--img
				// position: static
		@media (min-width: 1281.98px)
			// transform: scale(1.2)
			&.margin-left
				margin: auto -40px
				// margin: auto auto auto -50px

			&.margin-right
				margin: auto -40px
				// margin: auto -50px auto auto



.wrapper-occasion
	@include centerFlex(960px)
	margin: 0 auto
	padding: 30px 20px
	@media (max-width: 767.98px)
		flex-direction: column
		align-items: center
	@media (min-width: 768px)
		flex-wrap: wrap
		justify-content: space-between

	&--title
		font-family: Nunito, sans-serif
		font-size: 36px
		font-style: normal
		font-weight: 300
		line-height: 49px
		letter-spacing: 0em
		text-align: center
		margin: 0 auto 60px
		max-width: 890px
		@media (max-width: 767.98px)
			max-width: 100%
			font-size: 24px
			line-height: 1.4em
			font-weight: 300
			text-align: left
			margin: 10px auto 20px

	&-box
		flex-wrap: wrap
		margin: 0px 10px 30px
		padding: 0
		justify-content: space-between
		align-items: flex-start
		@media (max-width: 767.98px)
			@include centerFlex(100%)
		@media (min-width: 768px)
			@include centerFlex(45%)

		&:last-child
			margin-bottom: 10px

		&--title
			width: 100%
			font-family: Nunito, sans-serif
			font-size: 24px
			font-style: normal
			font-weight: 700
			line-height: 1.4em
			letter-spacing: 0em
			text-align: left
			margin: 0 0 20px

		&__head
			flex-wrap: wrap
			@media (max-width: 767.98px)
				@include centerFlex(100%)
			@media (min-width: 768px)
				@include centerFlex(62.5%)

			&--desc
				font-family: Nunito, sans-serif
				font-size: 14px
				font-style: normal
				font-weight: 300
				line-height: 1.5em
				letter-spacing: 0em
				text-align: left
				margin: 0

		&--img
			max-width: 35%
			@media (max-width: 767.98px)
				order: -1
				margin-bottom: 5px

.wrapper-img-box
	@include centerFlex(1300px)
	padding: 0 20px !important
	@media (min-width: 768px)
		margin: 0 0 20px
		min-height: 535px
		// @media (max-width: 1599.98px)
		// overflow: hidden
		&.flex-end
			justify-content: flex-end
			padding: 0 !important
			article
				margin-right: 5vw !important
		// @media (min-width: 768px) and (max-width: 1599.98px)
		&.margin-auto-top
			margin: -50px 0 !important
			figure
				margin: auto 0
			article
				margin: auto 0 auto 5vw

	@media (max-width: 767.98px)
		flex-wrap: wrap
		margin: 40px 0 20px
		&.new-order
			figure
				order: 1
				height: 250px
				top: 0
			article
				order: 2
				.wrapper-imgbox__header--title
					margin: 10px 20px 20px !important


	&__header
		margin: 0 auto
		height: 100%
		@media (max-width: 767.98px)
			@include centerFlex(100%)
			flex-direction: column
			align-items: center
			order: 2
		@media (min-width: 768px)
			padding: 0 20px 0 0
			@include centerBlock(45%)

		// img
		// 	@media (max-width: 767.98px)
		// 		max-width: 335px
		// 		margin: 30px auto
		// 	@media (min-width: 768px)
		// 		display: none

		&--title
			width: 100%
			margin: 20px auto
			font-family: Nunito, sans-serif
			font-size: 40px
			font-style: normal
			font-weight: 700
			line-height: 55px
			letter-spacing: 0em
			color: #000
			@media (max-width: 767.98px)
				text-align: center
				padding: 0
				margin: 0 20px 30px
				max-width: 325px
				font-size: 24px
				line-height: 32px

		&--desc
			@media (max-width: 767.98px)
				text-align: center

		&--desc,
		&--list
			max-width: 455px
			font-family: Nunito, sans-serif
			font-size: 16px
			font-style: normal
			font-weight: 300
			line-height: 24px
			letter-spacing: 0em
			color: #000
			@media (max-width: 767.98px)
				padding: 0
				margin: 0 20px 20px
				max-width: 325px
				font-size: 12px
				line-height: 16px

		&--list
			padding: 0 0 0 20px

		&--btns
			justify-content: space-between
			margin: 0
			padding: 0
			@media (max-width: 767.98px)
				@include centerFlex(100%)
				flex-direction: column
				align-items: center
				*
					max-width: 325px
					width: 100%
					margin: 0 0 10px !important
			// @media (min-width: 768px)
			// 	*
			// 		width: 45%

	&__hero
		display: flex
		margin: auto
		justify-content: flex-start
		align-items: center
		overflow: visible
		position: relative
		height: 100%
		width: 50%
		@media (max-width: 767.98px)
			order: 1
		@media (min-width: 1300px)
			margin: auto auto 15%
		@media (min-width: 1600px)
			// transform: scale(1.2)
			&.margin-left
				// margin: auto -30px
				margin: auto auto auto -100px

			&.margin-right
				// margin: auto -30px
				margin: auto -100px auto auto

		@media (max-width: 767.98px)
			width: 100%
			max-width: 335px
			top: 170px

		&--img
			max-width: 100%
			position: absolute
			top: 0
			right: 0
			bottom: 0
			left: 0
			margin: auto

.form__main-with-modal, #modal-dont-have-nip, .form-dont-have-nip
	input[type="email"]
		border: none
	&--input
		width: 100%
		box-shadow: 0px 0px 30px 0px #00000026
		font-family: Nunito, sans-serif
		font-size: 16px
		font-style: normal
		font-weight: 300
		line-height: 26px
		letter-spacing: 0em
		text-align: left
		background: #FFFFFF
		height: 55px
		padding: 0 20px
		margin: 20px auto 0
		border-radius: 70px
		color: #000
		border: 3px solid #fff
		//@media (max-width: 767.98px)
		//	max-width: 330px
		&:focus
			border: 3px solid #019A9A !important
	&--check
		font-family: Montserrat, sans-serif
		font-size: 14px
		font-style: normal
		font-weight: 500
		line-height: 17px
		letter-spacing: 0em
		text-align: left
		color: #000
		margin: 10px 0
		text-decoration: underline
		cursor: pointer

	.main__accept
		position: relative
		width: 100%
		margin: 5px 0
		font-family: Montserrat, sans-serif
		font-size: 14px
		font-style: normal
		font-weight: 300
		line-height: 17px
		letter-spacing: 0em
		text-align: left
		color: #000
		padding: 0 0 0 35px
		-webkit-user-select: none
		-moz-user-select: none
		-ms-user-select: none
		user-select: none
		@media (max-width: 767.98px)
			font-size: 12px
			line-height: 15px

		a, span.label--more
			cursor: pointer
			font-weight: 500
			color: #000

		&--checkbox
			position: absolute
			opacity: 0
			width: 0
			height: 0
			margin: 0

		input:checked ~ span.checkmark::after
			display: block

		.checkmark
			background-color: #FFFFFF
			position: absolute
			border: 1px solid #019A9A
			height: 20px
			width: 20px
			left: 0
			top: 1px
			border-radius: 16px

			&::after
				content: ""
				display: none
				position: absolute
				width: 60%
				height: 60%
				margin: auto
				top: 0
				right: 0
				bottom: 0
				left: 0
				background: url(../img/check.svg) no-repeat center / cover

		.more--text
			display: none

	&--submit
		background: radial-gradient(91.66% 306.32% at 0% 16.18%, #01CC9D 0%, #019A9A 100%)
		border: 2px solid #FFFFFF
		box-sizing: border-box
		border-radius: 70px
		font-family: Nunito, sans-serif
		font-size: 18px
		font-style: normal
		font-weight: 800
		line-height: 25px
		letter-spacing: 0em
		text-align: center
		color: #FFFFFF
		height: 55px
		width: 100%
		margin: 20px 0
		cursor: pointer



.form__main-with-modal, #modal-dont-have-nip
	.w-100
		width: 100% !important
	.mb-1
		margin-bottom: 0.25rem !important
	.mb-2
		margin-bottom: 0.5rem !important
	.mb-3
		margin-bottom: 0.75rem !important
	.mb-4
		margin-bottom: 1rem !important
	.mb-5
		margin-bottom: 1.25rem !important
	.mt-1
		margin-top: 0.25rem !important


	.mt-0
		margin-top: 0 !important

	.pt-0
		padding-top: 0

	.row

		display: flex
		flex-wrap: wrap
		padding: 0
		box-sizing: border-box
		margin-right: -15px
		margin-left: -15px
		& > *
			width: auto
			flex-grow: 0
			flex-shrink: 0
			box-sizing: border-box
			margin: 0
			padding-left: 15px
			padding-right: 15px
		.algin-center
			align-self: center

		.col-sm-6
			width: 50%
			padding-right: 15px
			padding-left: 15px

		.col-sm-12
			width: 100%
			padding-right: 15px
			padding-left: 15px

		@media (min-width:600px)

			.col-md-3
				width: (100% * (3/12))
				padding-right: 15px
				padding-left: 15px

			.col-md-4
				width: (100% * (4/12))
				padding-right: 15px
				padding-left: 15px

			.col-md-5
				width: (100% * (5/12))
				padding-right: 15px
				padding-left: 15px

			.col-md-6
				width: 50%
				padding-right: 15px
				padding-left: 15px

			.col-md-7
				width:(100% * (7/12))
				padding-right: 15px
				padding-left: 15px

			.col-md-8
				width:(100% * (8/12))
				padding-right: 15px
				padding-left: 15px

			.col-md-12
					width: 100%
					padding-right: 15px
					padding-left: 15px

		@media (min-width:1000px)
			.col-lg-4
				width: (100% * (4/12))
				padding-right: 15px
				padding-left: 15px

			.col-lg-5
				width: (100% * (5/12))
				padding-right: 15px
				padding-left: 15px

			.col-lg-6
				width: 50%
				padding-right: 15px
				padding-left: 15px

			.col-lg-7
				width: (100% * (7/12))
				padding-right: 15px
				padding-left: 15px

			.col-lg-8
				width: (100% * (8/12))
				padding-right: 15px
				padding-left: 15px

			.col-lg-12
				width: 100%
				padding-right: 15px
				padding-left: 15px
	.btn-outline
		background: unset !important
		color: #029494
		border-color: #029494
	.btn-form
		font-size: 16px
		line-height: 26px
		margin-top: 20px
		margin-bottom: 0
	.form-buttons
		margin-bottom: 20px

