//BOOTSTRAP CODE
.modal-open
  overflow: hidden

  .modal
    overflow-x: hidden
    overflow-y: auto

.modal
  position: fixed
  top: 0
  left: 0
  z-index: 1050
  display: none
  width: 100%
  height: 100%
  overflow: hidden
  outline: 0

.modal-dialog
  position: relative
  width: auto
  margin: 0.5rem
  pointer-events: none

.modal
  &.fade .modal-dialog
    transition: -webkit-transform 0.3s ease-out
    transition: transform 0.3s ease-out
    transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out
    -webkit-transform: translate(0, -50px)
    transform: translate(0, -50px)

  &.show .modal-dialog
    -webkit-transform: none
    transform: none

@media (prefers-reduced-motion: reduce)
  .modal.fade .modal-dialog
    transition: none

.modal-dialog-scrollable
  display: -ms-flexbox
  display: flex
  max-height: calc(100% - 1rem)

  .modal-content
    max-height: calc(100vh - 1rem)
    overflow: hidden

  .modal-header, .modal-footer
    -ms-flex-negative: 0
    flex-shrink: 0

  .modal-body
    overflow-y: auto

.modal-dialog-centered
  display: -ms-flexbox
  display: flex
  -ms-flex-align: center
  align-items: center
  min-height: calc(100% - 1rem)

  &::before
    display: block
    height: calc(100vh - 1rem)
    content: ""

  &.modal-dialog-scrollable
    -ms-flex-direction: column
    flex-direction: column
    -ms-flex-pack: center
    justify-content: center
    height: 100%

    .modal-content
      max-height: none

    &::before
      content: none

.modal-content
  position: relative
  display: -ms-flexbox
  display: flex
  -ms-flex-direction: column
  flex-direction: column
  width: 100%
  pointer-events: auto
  background-color: #fff
  background-clip: padding-box
  border: 1px solid rgba(0, 0, 0, 0.2)
  outline: 0

.modal-backdrop
  position: fixed
  top: 0
  left: 0
  z-index: 1040
  width: 100vw
  height: 100vh
  background-color: #000

  &.fade
    opacity: 0

  &.show
    opacity: 0.5

.modal-header
  display: -ms-flexbox
  display: flex
  -ms-flex-align: start
  align-items: flex-start
  -ms-flex-pack: justify
  justify-content: space-between
  padding: 1rem 1rem
  border-bottom: 1px solid #dee2e6
  border-top-left-radius: 0.3rem
  border-top-right-radius: 0.3rem

  .close
    cursor: pointer
    padding: 1rem 1rem
    margin: -1rem -1rem -1rem auto

.modal-title
  margin-bottom: 0
  line-height: 1.5

.modal-body
  position: relative
  -ms-flex: 1 1 auto
  flex: 1 1 auto
  padding: 1rem

.modal-footer
  display: -ms-flexbox
  display: flex
  -ms-flex-align: center
  align-items: center
  -ms-flex-pack: end
  justify-content: flex-end
  padding: 1rem
  border-top: 1px solid #dee2e6
  border-bottom-right-radius: 0.3rem
  border-bottom-left-radius: 0.3rem



.modal-scrollbar-measure
  position: absolute
  top: -9999px
  width: 50px
  height: 50px
  overflow: scroll

@media (min-width: 576px)
  .modal-dialog
    max-width: 500px
    margin: 1.75rem auto

  .modal-dialog-scrollable
    max-height: calc(100% - 3.5rem)

    .modal-content
      max-height: calc(100vh - 3.5rem)

  .modal-dialog-centered
    min-height: calc(100% - 3.5rem)

    &::before
      height: calc(100vh - 3.5rem)

  .modal-sm
    max-width: 300px

@media (min-width: 992px)
  .modal-lg, .modal-xl
    max-width: 800px

@media (min-width: 1200px)
  .modal-xl
    max-width: 1140px
// END BOOTSTRAP CODE

#modal-dont-have-nip
  background: #000000cc
  border-radius: unset !important

  .close
    font-size: 1.8rem
    font-weight: 300
    color: #019A9A

  form label
    margin-top: 22px
    margin-bottom: 32px

  .modal-dialog
    max-width: 814px

  .modal-header
    margin: 10px 20px 0
    padding: unset
    border-bottom: unset

  .modal-content .modal-body
    padding: 20px 70px
    margin-bottom: 40px

  .main__accept
    display: block
    margin-bottom: 22px
    margin-top: 22px
  p
    /*font-family: 'Nunito Sans';
    font-style: normal
    font-weight: 300
    font-size: 16px
    line-height: 160%
    margin: 0
    margin-bottom: 1rem

  h3
    font-size: 30px
    font-weight: 200

  h4
    font-size: 24px
    font-weight: 200x

  .font-weight-bold
    font-weight: bold

  .boxes .box
    .box-content
      font-style: normal
      font-size: 12px
      line-height: 160%

    img
      margin-bottom: 10px

@media (max-width: 814px)
  #modal-dont-have-nip .modal-content .modal-body
    padding: 20px 2em