.thank-you-page
    display: flex
    flex-direction: column



    @include for-tablet-up
        flex-direction: row


    h1, h1        
        font-family: Nunito
        font-style: normal
        font-weight: normal
        font-size: 30px
        line-height: 1.1
        margin-bottom: 1em
        /* or 133% */

        letter-spacing: -0.02em

        color: #000000
        @include for-desktop-down
            font-size: 24px


    &__aside,
    &__text
        flex-shrink: 0
        flex-grow: 0
    &__aside-bg
        position: absolute
        // top: 0
        bottom: 0
        right: 0
        object-fit: contain
        object-position: right bottom
        opacity: 0.3
        width: 50%
        @include for-desktop-up
            top: 0
            right: 0
            width: auto
            opacity: 1
            height: 100%

        
    &__aside
        padding-bottom: 100%
        background:  radial-gradient(88.44% 99.66% at 91.99% 96.44%, #A3FAE5 0%, #009A9A 100%)
        color: #fff
        position: relative
        overflow: hidden
        @include for-tablet-up
            padding-bottom: 0
            flex-grow:0
            width: 50%

    &__text
        padding-right: $gutter
        @include for-phone-only
            padding-left: $gutter
            padding-right: $gutter
            padding-top: 30px
            padding-bottom: 30px

        @include for-tablet-up
            flex-grow:0
            width: 50%
            padding-top: 7%
            padding-bottom: 7%
            padding-left: max(20px, calc((100vw - 1220px) / 2))

.thank-you-page__aside-steps
    position: absolute
    top: 10%
    bottom: 10%
   
    list-style: none
    padding: 0
    margin: 0
    // height: 100%
    display: flex
    flex-direction: column
    justify-content: stretch
    left: 15px

    @include for-tablet-up
        left: 60px
        right: 30%
    li
        display: flex
        align-items: center
        flex-grow: 1
        position: relative

        
        &:before,
        &:after
            content: ''
            background: #ffffff86
            position: absolute
            left: calc(39px / 2)
            height: calc(50% - 30px)
            width: 1px
        &:before
            bottom: calc(50% + 30px)
        &:after
            top: calc(50% + 30px)

        &:first-of-type:before 
            height: 100%
        &:last-of-type:after 
            height: 100%
            

        &.current
            .s-label
                font-weight: 900
                font-size: 36px
                @include for-desktop-down
                    font-size: 26px
            .s-icon
                background: #fff
                color: #01A99B
        &.next
            .s-label
                opacity: 0.5
        .s-icon
            width: 39px
            height: 39px
            background: rgba(255, 255, 255, 0.3)
            border: 1px solid #FFFFFF
            box-sizing: border-box
            margin-right: 20px
            display: flex
            align-items: center
            justify-content: center
            border-radius: 20px
            
            font-family: Nunito
            font-style: normal
            font-weight: 900
            font-size: 16px
            line-height: 150%
            flex-grow: 0
            flex-shrink: 0
            /* identical to box height, or 24px */

            text-align: center


        .s-label
            
            font-family: Nunito
            font-weight: 300
            font-size: 24px
            line-height: 1.1
            /* identical to box height, or 36px */
            color: #FFFFFF
            @include for-desktop-down
                font-size: 18px
            @include for-desktop-down
                font-size: 18px
            
            .small
                font-weight: 300
                font-size: 12px
                line-height: 1
                margin-top: -1em
                display: block

