// =============================================================================
// String Replace
// =============================================================================

@function str-replace($string, $search, $replace: "")
  $index: str-index($string, $search)

  @if $index
    @return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace)

  @return $string

// =============================================================================
// Font Face
// =============================================================================

@mixin font-face($name, $path, $weight: null, $style: null, $exts: woff ttf)
  $src: null
  $extmods: (eot: "?",svg: "#" + str-replace($name, " ", "_"))
  $formats: (otf: "opentype",ttf: "truetype")

  @each $ext in $exts
    $extmod: if(map-has-key($extmods, $ext), $ext + map-get($extmods, $ext), $ext)
    $format: if(map-has-key($formats, $ext), map-get($formats, $ext), $ext)
    $src: append($src, url(quote("../../" + $path + "." + $extmod)) format(quote($format)), comma)

  @font-face
    font-family: quote($name)
    font-style: $style
    font-weight: $weight
    src: $src

@mixin centerBlock($max-width)
  display: block
  max-width: $max-width
  width: 100%

@mixin centerFlex($max-width)
  display: flex
  max-width: $max-width
  width: 100%

@mixin transition
  transition: all .25s
  -o-transition: all .25s
  -ms-transition: all .25s
  -moz-transition: all .25s
  -webkit-transition: all .25s

@mixin transform-scale($scale)
  transform: scale($scale)
  -o-transform: scale($scale)
  -ms-transform: scale($scale)
  -moz-transform: scale($scale)
  -webkit-transform: scale($scale)

@mixin for-phone-only 
  @media (max-width: 767px) 
    @content 

@mixin for-tablet-up 
  @media (min-width: 768px) 
    @content 
@mixin for-tablet-down 
  @media (max-width: 767px) 
    @content 

  
@mixin for-desktop-up 
  @media (min-width: 1100px) 
    @content 
  
@mixin for-desktop-down 
  @media (max-width: 1099px) 
    @content 

@mixin for-big-desktop-up 
  @media (min-width: 1600px) 
    @content 
@mixin for-big-desktop-down 
  @media (min-width: 1599px) 
    @content 
