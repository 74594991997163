.wrapper-hero
	@include centerBlock(900px)
	margin: 0 auto !important
	padding: 0

	.hero__main
		@include centerFlex(100%)
		margin: 0 auto !important
		padding: 0
		justify-content: center
		flex-wrap: wrap
		position: relative

		&:nth-of-type(2)
			max-width: 400px
			@media (max-width: 767.98px)
				max-width: 330px

		&--title
			width: 100%
			margin: 20px auto 10px
			font-family: Nunito, sans-serif
			font-size: 40px
			font-style: normal
			font-weight: 300
			line-height: 55px
			letter-spacing: 0em
			text-align: center
			color: #000
			@media (max-width: 767.98px)
				font-size: 28px
				line-height: 1.3
				// text-align: left
				margin-top: 0px

		&--desc
			max-width: 620px
			justify-self: center
			font-family: Montserrat, sans-serif
			font-size: 14px
			font-style: normal
			font-weight: 300
			line-height: 22px
			letter-spacing: 0em
			text-align: center
			color: #000
			@media (max-width: 767.98px)
				max-width: none
				width: 100%
				font-size: 14px
				line-height: 20px

		&--search
			width: 100%
			box-shadow: 0px 0px 30px 0px #00000026
			font-family: Nunito, sans-serif
			font-size: 16px
			font-style: normal
			font-weight: 300
			line-height: 26px
			letter-spacing: 0em
			text-align: center
			background: #FFFFFF
			height: 55px
			margin: 20px auto
			max-width: 400px
			border-radius: 70px
			border: none
			color: #000
			@media (max-width: 767.98px)
				max-width: 330px

		&--date
			font-family: Montserrat, sans-serif
			font-size: 12px
			font-style: normal
			font-weight: 300
			line-height: 24px
			letter-spacing: 0em
			text-align: left
			margin: 0
			color: #000
			justify-self: flex-end

		&--button
			background: transparent
			border: none
			position: absolute
			left: 87.67%
			right: 7.42%
			top: 30.24%
			font-size: 20px
			bottom: 32.47%
			box-sizing: border-box
			color: #0003
			cursor: pointer

	.hero__img
		max-width: 100%
		height: 285px
		margin: 0 0 15px
		color: #000
		background-size: cover
		background-position: center
		background-repeat: no-repeat

	&.hero-docs
		.hero__main
			@include centerFlex(900px)
			@media (min-width: 768px)
				justify-content: flex-start
				&--title,
				&--desc
						text-align: left
			&--title
				margin: 20px 0 5px
			&--desc
				margin: 5px 0 20px

.wrapper-content
	@include centerBlock(700px)
	margin: 30px auto 0
	padding: 0

	&__main
		@include centerBlock(100%)
		margin: 0 auto
		padding: 0

		&--xcrpt
			margin: 0
			padding: 0 0 1.2em
			color: #000
			font-family: Nunito, sans-serif
			font-size: 18px
			font-style: normal
			font-weight: 600
			line-height: 1.4
			letter-spacing: 0em
			text-align: left
			@media (max-width: 767.98px)
				padding-bottom: 0

		&--text
			font-family: Nunito, sans-serif
			font-size: 14px
			font-style: normal
			font-weight: 300
			line-height: 1.4
			letter-spacing: 0em
			text-align: left
			margin: 0
			padding: 0 0 10px
			color: #000
			h2, h3
				font-weight: 500
				margin-top: 1em
				margin-bottom: 0.6em
			a
				color: #009A9A
				font-weight: bold

.wrapper-popular
	@include centerFlex(1040px)
	justify-content: center
	margin: 0 auto !important
	padding: 0
	flex-wrap: wrap

	.popular__head
		@include centerBlock(100%)
		margin: 0 auto !important
		padding: 0

		&--title
			font-family: Nunito, sans-serif
			font-size: 24px
			font-style: normal
			font-weight: 300
			line-height: 33px
			letter-spacing: 0em
			text-align: left
			color: #000
			@media (max-width: 767.98px)
				font-size: 18px
				line-height: 25px
				text-align: center


	.popular-main
		@include centerFlex(1040px)
		padding: 0
		flex-wrap: wrap
		
		margin: 0 -7px
		width: auto

	
		@media (max-width: 767.98px)
			flex-direction: column
			align-items: center
		@media (min-width: 768px)
			justify-content: space-between

		&__post:hover 
			.popular-main__post--thumb
				transform: scale(1.04)

		&__post
			@include centerFlex(33.3%)
			text-decoration: none
			flex-direction: column
			padding: 7px
			margin: 0px
			@media (max-width: 767.98px)
				max-width: none
				width: 100%
				// padding: 0
				margin-bottom: 30px

	
			&:last-of-type
				margin-right: auto 			

			&--thumb
				max-width: none
				padding-bottom: 50%
				// height: 130px
				margin: 0 0 15px
				color: #000
				background-size: cover
				background-position: center
				background-repeat: no-repeat
				transition: 333ms ease-in-out

			&--date
				font-family: Montserrat, sans-serif
				font-size: 12px
				font-style: normal
				font-weight: 300
				line-height: 24px
				letter-spacing: 0em
				text-align: left
				margin: 0
				color: #000


			&--title
				font-family: Montserrat, sans-serif
				font-size: 20px
				font-style: normal
				font-weight: 700
				line-height: 24px
				letter-spacing: 0em
				text-align: left
				margin: 0 0 20px
				color: #000
				@media (max-width: 767.98px)
					font-weight: 500

			&--xcrpt
				font-family: Montserrat, sans-serif
				font-size: 12px
				font-style: normal
				font-weight: 300
				line-height: 22px
				letter-spacing: 0em
				text-align: left
				margin:  0
				color: #000
				@media (max-width: 767.98px)
					font-size: 14px
					line-height: 20px

			&--href
				font-family: Montserrat, sans-serif
				font-size: 12px
				font-style: normal
				font-weight: 700
				line-height: 22px
				letter-spacing: 0em
				text-align: left
				color: #000
				text-decoration: underline

.wrapper-posts
	@include centerFlex(100%)
	justify-content: center
	margin: 0 auto !important
	padding: 0
	flex-wrap: wrap

	.posts__head
		@include centerBlock(1000px)
		margin: 0 auto !important
		padding: 0

		&--title
			margin: 20px 0
			font-family: Nunito, sans-serif
			font-size: 40px
			font-style: normal
			font-weight: 300
			line-height: 55px
			letter-spacing: 0em
			text-align: left
			color: #000
			@media (max-width: 767.98px)
				font-size: 24px
				line-height: 1.3
				text-align: center


	.posts-main
		@include centerFlex(1000px)
		justify-content: center
		margin: 15px auto !important
		padding: 0
		flex-wrap: wrap
		@media (max-width: 767.98px)
			flex-direction: column
			align-items: center
		@media (min-width: 768px)
			justify-content: space-between
		
		&-post:hover
			.posts-main-post__head
				transform: scale(1.02)

	
					
		&-post
			@include centerFlex(49%)
			flex-direction: column
			margin: 0 0 60px
			text-decoration: none
			@media (max-width: 767.98px)
				width: 100%
				max-width: none
				// @include centerFlex(380px)	
		

			&__head
				height: 190px
				padding-bottom: 40%
				position: relative
				margin: 0 0 15px
				background-position: center
				background-size: cover
				background-repeat: no-repeat
				transition: 333ms ease-in-out

				&--title
					margin: 0
					padding: 15px 30px
					font-family: Nunito, sans-serif
					font-size: 24px
					font-style: normal
					font-weight: 300
					line-height: 33px
					letter-spacing: 0em
					text-align: left
					left: 0
					bottom: 25px
					position: absolute
					background-color: #fff
					color: #000
					@media (max-width: 767.98px)
						font-size: 18px
						line-height: 25px

			&--xcrpt
				margin: 15px 0 0
				font-family: Montserrat, sans-serif
				font-size: 14px
				font-style: normal
				font-weight: 300
				line-height: 22px
				letter-spacing: 0em
				text-align: left
				color: #000
				@media (max-width: 767.98px)
					font-size: 12px
					line-height: 22px

			&--href
				margin: 10px 0 0
				font-family: Montserrat, sans-serif
				font-size: 12px
				font-style: normal
				font-weight: 700
				line-height: 22px
				letter-spacing: 0em
				text-align: left
				text-decoration: underline
				color: #000

	
	
.wrapper-faq
	@include centerFlex(100%)
	justify-content: flex-start
	align-content: center
	flex-direction: column
	margin: 0 auto 100px !important
	padding: 30px
	min-height: 700px
	flex-wrap: wrap

	.faq__head
		@include centerBlock(1040px)
		margin: 35px auto 25px !important
		padding: 30px 0

		&--title
			font-family: Nunito, sans-serif
			font-size: 40px
			font-style: normal
			font-weight: 300
			line-height: 55px
			letter-spacing: 0em
			text-align: center
			color: #fff
			@media (max-width: 767.98px)
				font-size: 24px
				font-weight: 400
				line-height: 33px

		&--subtitle
			font-family: Nunito, sans-serif
			font-size: 14px
			font-style: normal
			font-weight: 300
			line-height: 19px
			letter-spacing: 0em
			text-align: center



	.faq__art
		@include centerFlex(930px)
		text-decoration: none
		border-bottom: 1px solid #ffffff5b
		flex-direction: column
		margin: 0 auto
		@media (max-width: 767.98px)
			@include centerFlex(95%)

		&--title
			@include centerFlex(100%)
			justify-content: space-between
			color: #fff
			font-family: Nunito, sans-serif
			font-size: 18px
			font-style: normal
			font-weight: 300
			letter-spacing: 0em
			text-align: left
			margin: 0
			cursor: pointer
			padding: 15px 0
			@media (max-width: 767.98px)
				font-size: 12px


			span
				width: 40px
				margin: 0 40px 0 0
				font-family: Montserrat, sans-serif
				font-size: 18px
				font-style: normal
				font-weight: 700
				letter-spacing: 0em
				text-align: center
				@include transition
				@media (max-width: 767.98px)
					margin: 0
					font-size: 12px

		&--desc
			display: block
			
			margin: 0
			padding: 20px 28px 35px
			font-family: Nunito, sans-serif
			font-size: 14px
			line-height: 1.5em
			font-style: normal
			font-weight: 300
			letter-spacing: 0em
			text-align: left
			color: #000
			display: none
			width: 100%
			background-color: #fff
			border-radius: 5px
			.faq__art--desc-wrap
				min-height: none
				column-count: 2
				column-gap: 20px
				@media (max-width: 767.98px)
					column-count: 1

			p
				color: #000
				margin: 0 7px
				padding: 5px 0
				break-inside: avoid-column
				display:table             
				&:after
					content: ''
					display: block
					border-bottom: 1px solid #000
					break-after: avoid-column

html .wrapper-main .wrapper-steps,
.wrapper-steps
	@include centerFlex(1040px)
	justify-content: center
	margin: 0 auto !important
	// padding: 20px 0
	flex-wrap: wrap

	.steps__head
		@include centerBlock(100%)
		margin: 0 auto !important
		padding: 40px 0 0
		@media (max-width: 767.98px)
			padding-top: 10px

		&--title
			font-family: Nunito, sans-serif
			font-size: 36px
			font-style: normal
			font-weight: 700
			line-height: 1.3
			letter-spacing: 0em
			text-align: center
			margin-top: 1.3em
			@media (max-width: 767.98px)
				font-size: 24px
				font-weight: 300
				margin: 0px 0 25px

		&--desc
			font-family: Montserrat, sans-serif
			font-size: 18px
			font-style: normal
			font-weight: 300
			line-height: 22px
			letter-spacing: 0em
			text-align: center
			margin: 0 0 40px
			@media (max-width: 767.98px)
				font-size: 14px
				line-height: 20px



	.steps-main
		@include centerFlex(1040px)
		margin: 0 auto
		padding: 0 0 20px
		flex-wrap: wrap
		@media (max-width: 767.98px)
			flex-direction: column
			align-items: center
			margin-bottom: 0
			padding-bottom: 0
		@media (min-width: 768px)
			justify-content: space-between

		&__post
			@include centerFlex(32.5%)
			margin: 0 0 20px
			@media (max-width: 767.98px)
				@include centerFlex(380px)
				border-bottom: 1px solid #ffffff6e
				align-items: center
			@media (min-width: 768px)
				flex-direction: column

			&--ico
				align-items: center
				height: 75px
				margin: 0 0 15px
				background-size: cover
				background-position: center
				background-repeat: no-repeat
				padding: 0 0 15px
				@media (max-width: 767.98px)
					@include centerFlex(85px)
					padding-bottom: 0
					margin-bottom: 0
				@media (min-width: 768px)
					@include centerFlex(100%)
					border-bottom: 1px solid #ffffff6e

				img
					max-width: 100%

			&--title
				font-family: Montserrat, sans-serif
				font-size: 16px
				font-style: normal
				line-height: 24px
				letter-spacing: 0em
				text-align: left
				@media (max-width: 767.98px)
					font-size: 14px


			&--desc
				font-family: Montserrat, sans-serif
				font-size: 16px
				font-style: normal
				font-weight: 300
				line-height: 24px
				letter-spacing: 0em
				text-align: left
				margin: 0 0 20px
				@media (max-width: 767.98px)
					font-size: 14px
					margin-bottom: 0
				@media (min-width: 768px)
					max-width: 80%
				strong
					display: block
					margin: 0 0 5px
					font-weight: 700
					@media (max-width: 767.98px)
						font-size: 14px

.wrapper-form
	@include centerFlex(100%)
	justify-content: flex-start
	align-content: center
	flex-direction: column
	margin: 0 auto !important
	padding: 30px !important
	// min-height: 600px
	flex-wrap: wrap
	// background: radial-gradient(122.72% 100% at 2.53% 0%, #52D2B4 0%, #11425E 100%)


	.form__head
		@include centerBlock(1040px)
		margin: 0 auto !important
		padding: 30px 0

		&--title
			max-width: 865px
			font-family: Montserrat, sans-serif
			font-size: 36px
			font-style: normal
			font-weight: 300
			line-height: 44px
			letter-spacing: 0em
			text-align: center
			color: #000
			margin: 0 auto 10px
			padding: 0
			@media (max-width: 767.98px)
				font-size: 24px
				line-height: 1.4em

		&--desc
			font-family: Montserrat, sans-serif
			font-size: 18px
			font-style: normal
			font-weight: 300
			line-height: 22px
			letter-spacing: 0em
			text-align: center
			margin: 0
			padding: 0
			color: #000
			@media (max-width: 767.98px)
				font-size: 14px
				line-height: 1.5em

	.form__main
		@include centerFlex(400px)
		margin: 0 auto !important
		padding: 0
		flex-direction: column

		&--input
			width: 100%
			box-shadow: 0px 0px 30px 0px #00000026
			font-family: Nunito, sans-serif
			font-size: 16px
			font-style: normal
			font-weight: 300
			line-height: 26px
			letter-spacing: 0em
			text-align: center
			background: #FFFFFF
			height: 55px
			margin: 10px auto 0
			max-width: 400px
			border-radius: 70px
			color: #000 !important
			border: 3px solid #fff
			&.error
				border-color: red
			@media (max-width: 767.98px)
				max-width: 330px
			&:focus
				border: 3px solid #019A9A !important

		&--check
			font-family: Montserrat, sans-serif
			font-size: 14px
			font-style: normal
			font-weight: 500
			line-height: 17px
			letter-spacing: 0em
			text-align: left
			color: #000 !important
			margin: 10px 0
			text-decoration: underline
			cursor: pointer

		.main__accept
			//cursor: pointer
			position: relative
			@include centerBlock(100%)
			margin: 5px 0
			font-family: Montserrat, sans-serif
			font-size: 14px
			font-style: normal
			font-weight: 300
			line-height: 17px
			letter-spacing: 0em
			text-align: left
			color: #000
			padding: 0 0 0 35px
			-webkit-user-select: none
			-moz-user-select: none
			-ms-user-select: none
			user-select: none
			@media (max-width: 767.98px)
				font-size: 12px
				line-height: 15px

			a, span.label--more
				cursor: pointer
				font-weight: 500
				color: #000

			&--checkbox
				position: absolute
				opacity: 0
				width: 0
				height: 0
				margin: 0

			input:checked ~ span.checkmark::after
				display: block

			.checkmark
				background-color: #FFFFFF
				position: absolute
				border: 1px solid #000
				height: 24px
				width: 24px
				left: 0
				top: 5px
				border-radius: 16px

				&::after
					content: ""
					display: none
					position: absolute
					width: 60%
					height: 60%
					margin: auto
					top: 0
					right: 0
					bottom: 0
					left: 0
					background: url(../img/check.svg) no-repeat center / cover
					
			input.error ~ span.checkmark
				border-color: red

			.more--text
				display: none

		&--submit
			background: radial-gradient(91.66% 306.32% at 0% 16.18%, #01CC9D 0%, #019A9A 100%)
			border: 2px solid #FFFFFF
			box-sizing: border-box
			border-radius: 70px
			font-family: Nunito, sans-serif
			font-size: 18px
			font-style: normal
			font-weight: 800
			line-height: 25px
			letter-spacing: 0em
			text-align: center
			color: #FFFFFF
			height: 55px
			width: 100%
			margin: 20px 0
			cursor: pointer
			transition: 333ms
			&:hover
				filter: brightness(1.1)

.wrapper-docs
	@include centerFlex(900px)
	justify-content: space-between
	align-content: center
	flex-wrap: wrap
	margin: 0 auto 50px !important
	padding: 0
	@media (max-width: 767.98px)
		justify-content: center


	.docs__head
		@include centerBlock(900px)
		margin: 0 auto !important
		padding: 0

		&--title
			font-family: Nunito, sans-serif
			font-size: 24px
			font-style: normal
			font-weight: 300
			line-height: 33px
			letter-spacing: 0em
			text-align: left
			color: #000
			margin: 20px 0 10px
			@media (max-width: 767.98px)
				text-align: center
				font-size: 20px
				line-height: 28px


	.docs__art
		@include centerFlex(100%)
		flex-wrap: wrap
		justify-content: space-between
		color: #000
		margin: 0
		@media (max-width: 767.98px)
			@include centerFlex(445px)
		&--title
			@include centerFlex(49%)
			justify-content: space-between
			color: #000
			font-family: Nunito, sans-serif
			font-size: 12px
			font-style: normal
			font-weight: 300
			letter-spacing: 0em
			text-align: left
			margin: 0
			padding: 12px 0
			border-bottom: 1px solid #999
			text-decoration: none
			transition: 333ms
				
			&:hover
				border-bottom: 1px solid #009A9A 
				
			@media (max-width: 767.98px)
				max-width: 100%
				width: 100%

			a
				width: 40px
				margin: 0 10px 0 0
				font-family: Montserrat, sans-serif
				font-size: 0
				font-style: normal
				font-weight: 700
				letter-spacing: 0em
				text-align: center
				text-decoration: none
				color: #000
				@include transition
				&:hover
					transform: scale(1.1)
				@media (max-width: 767.98px)
					margin: 0

				img
					height: 100%
					max-width: 100%

.wrapper-top
	@include centerFlex(100%)
	align-items: center
	margin: 0 0 20px
	padding: 0 !important
	border-bottom: 1px solid #BDBDBD
	@media (min-width: 768px)
		min-height: 535px

	&__header
		@media (max-width: 767.98px)
			@include centerFlex(100%)
			flex-direction: column
			align-items: center
		@media (min-width: 768px)
			margin-left: 5%
			@include centerBlock(37%)
			&.longer-than-before
				@include centerBlock(50%)

			.title--img
				display: none

		&--title
			width: 100%
			margin: 20px auto
			font-family: Nunito, sans-serif
			font-size: 40px
			font-style: normal
			font-weight: 300
			line-height: 55px
			letter-spacing: 0em
			color: #000
			@media (max-width: 767.98px)
				height: 46vw
				position: relative
				margin: 0
				width: 100%
				padding: 35px
				font-size: 20px
				line-height: 27px
				color: #fff

				&:after,
				&:before
					content: ""
					position: absolute
					height: 100%
				&:before
					left: 0
					right: 0
					top: 0
					width: 100%
					z-index: -1
					background: radial-gradient(54.26% 161.68% at 0% 16.18%, #00CF9E 0%, #019A9A 100%) no-repeat center / cover
				&:after
					right: 0
					bottom: 0
					width: 50%
					background: url(../img/about.png) no-repeat right bottom / cover

		&--desc
			max-width: 455px
			font-family: Nunito, sans-serif
			font-size: 16px
			font-style: normal
			font-weight: 300
			line-height: 26px
			letter-spacing: 0em
			color: #000
			@media (max-width: 767.98px)
				padding: 35px 0
				margin: 0 20px
				max-width: 325px
				font-size: 12px
				line-height: 16px

		.form__main
			@include centerFlex(425px)
			margin: 0 !important
			padding: 0
			flex-direction: column
			position: relative
			@media (max-width: 767.98px)
				margin: 0 0 40px !important
				@include centerFlex(330px)

			input[type="password"],
			.main__accept,
			.form__main--check
				display: none

			input[type="email"]
				border: none

			input[type="submit"]
				position: absolute
				top: -10px
				border: none
				@media (max-width: 767.98px)
					max-width: 150px
					font-size: 12px
					left: 56%
				@media (min-width: 768px)
					max-width: 55%
				@media (min-width: 768px) and (max-width: 1599.98px)
					left: 20vw
				@media (min-width: 1600px)
					left: 85%


			&--input
				width: 100%
				box-shadow: 0px 0px 30px 0px #00000026
				font-family: Nunito, sans-serif
				font-size: 16px
				font-style: normal
				font-weight: 300
				line-height: 26px
				letter-spacing: 0em
				text-align: left
				background: #FFFFFF
				height: 55px
				padding: 0 20px
				margin: 10px auto 0
				border-radius: 70px
				color: #000
				border: 3px solid #fff
				@media (max-width: 767.98px)
					max-width: 330px
				&:focus
					border: 3px solid #019A9A !important


			&--check
				font-family: Montserrat, sans-serif
				font-size: 14px
				font-style: normal
				font-weight: 500
				line-height: 17px
				letter-spacing: 0em
				text-align: left
				color: #000
				margin: 10px 0
				text-decoration: underline
				cursor: pointer

			.main__accept
				max-width: 350px
				position: relative
				width: 100%
				margin: 5px 0
				font-family: Montserrat, sans-serif
				font-size: 14px
				font-style: normal
				font-weight: 300
				line-height: 17px
				letter-spacing: 0em
				text-align: left
				color: #000
				padding: 0 0 0 35px
				-webkit-user-select: none
				-moz-user-select: none
				-ms-user-select: none
				user-select: none
				@media (max-width: 767.98px)
					font-size: 12px
					line-height: 15px

				a, span.label--more
					cursor: pointer
					font-weight: 500
					color: #000

				&--checkbox
					position: absolute
					opacity: 0
					width: 0
					height: 0
					margin: 0

				input:checked ~ span.checkmark::after
					display: block

				.checkmark
					background-color: #FFFFFF
					position: absolute
					border: 1px solid #000
					height: 24px
					width: 24px
					left: 0
					top: 5px
					border-radius: 16px

					&::after
						content: ""
						display: none
						position: absolute
						width: 60%
						height: 60%
						margin: auto
						top: 0
						right: 0
						bottom: 0
						left: 0
						background: url(../img/check.svg) no-repeat center / cover

				.more--text
					display: none

			&--submit
				background: radial-gradient(91.66% 306.32% at 0% 16.18%, #01CC9D 0%, #019A9A 100%)
				border: 2px solid #FFFFFF
				box-sizing: border-box
				border-radius: 70px
				font-family: Nunito, sans-serif
				font-size: 18px
				font-style: normal
				font-weight: 800
				line-height: 25px
				letter-spacing: 0em
				text-align: center
				color: #FFFFFF
				height: 55px
				width: 100%
				margin: 20px 0
				cursor: pointer

	&__hero
		@media (max-width: 767.98px)
			display: none
		@media (min-width: 768px) and (max-width: 1600px)
			right: 0
			width: 50%
		&.smaller-in-smaller
			@media (min-width: 768px) and (max-width: 1600px)
				width: 40%
		@media (min-width: 1600px)
			top: 60px
			width: 65%
			right: -150px
		@media (min-width: 768px)
			display: flex
			justify-content: flex-end
			align-items: center
			align-items: flex-end
			position: absolute
			background: radial-gradient(54.26% 161.68% at 0% 16.18%, #00CF9E 0%, #019A9A 100%) no-repeat center / cover
			mix-blend-mode: multiply
			// border-top-left-radius: 20px
			// border-bottom-left-radius: 20px
			border-radius: 20px
			margin: 0
			max-height: 475px
			overflow: hidden
			&:after
				content: ""
				// 	border-top-left-radius: 20px
				// border-bottom-left-radius: 20px
				border-radius: 20px
				position: absolute
				top: 0
				left: 0
				width: 100%
				height: 100%
				background: url(../img/about.jpg) no-repeat center / cover
				mix-blend-mode: multiply
				z-index: -1

			&--img
				max-width: 100%

.wrapper-years
	@include centerFlex(1040px)
	padding: 0
	flex-wrap: wrap
	@media (max-width: 767.98px)
		flex-direction: column
		align-items: center
		margin: 0 auto 0 !important
	@media (min-width: 768px)
		margin: 70px auto 0 !important
		justify-content: space-between

	&__box
		@include centerFlex(32.5%)
		flex-direction: column
		margin: 0 0 20px
		@media (max-width: 767.98px)
			@include centerFlex(380px)

		&--img
			@include centerFlex(100%)
			flex-direction: column
			align-items:center
			justify-content: flex-end
			align-items: flex-start
			height: 270px
			margin: 0 0 15px
			color: #000
			background-size: cover
			background-position: center
			background-repeat: no-repeat
			@media (max-width: 767.98px)
				align-items: center

			img
				max-width: 100%
				max-height: 100%

		&--title
			@include centerFlex(100%)
			@media (max-width: 767.98px)
				border-bottom: 2px solid #D8D8D8
			@media (min-width: 768px)
				flex-direction: column

			span
				font-weight: 700
				font-style: normal
				text-align: left
				letter-spacing: 0em
				color: #000
				// @media (max-width: 767.98px)
				&:nth-of-type(1)
					font-family: Nunito, sans-serif
					font-size: 24px
					line-height: 24px
					@media (max-width: 767.98px)
						font-size: 16px
						margin-right: 10px
					@media (min-width: 768px)
						padding: 0 0 15px
						border-bottom: 2px solid #D8D8D8

				&:nth-of-type(2)
					font-family: Montserrat, sans-serif
					font-size: 16px
					line-height: 24px
					@media (max-width: 767.98px)
						font-family: Nunito, sans-serif
					@media (min-width: 768px)
						margin: 15px 0

.wrapper-whoweare
	@include centerFlex(1040px)
	justify-content: center
	margin: 0 auto !important
	padding: 0 0 60px
	flex-wrap: wrap

	&__head
		@include centerBlock(100%)
		margin: 0 auto !important
		padding: 0

		&--title
			font-family: Nunito, sans-serif
			font-size: 36px
			font-style: normal
			font-weight: 700
			line-height: 49px
			letter-spacing: 0em
			color: #000
			text-align: center
			@media (max-width: 767.98px)
				margin: 40px auto 0
				font-weight: 300
				font-size: 18px
				line-height: 25px

	&-main
		@include centerFlex(1040px)
		padding: 0
		margin: 40px auto
		@media (max-width: 767.98px)
			flex-direction: column
			align-items: center
			align-content: center
			margin: 0 auto 40px

		&--title
			flex-shrink: 2
			@include centerBlock(250px)
			font-family: Nunito, sans-serif
			font-style: normal
			font-weight: 700
			font-size: 24px
			line-height: 33px
			letter-spacing: 0em
			text-align: left
			margin: 0 0 20px
			@media (max-width: 767.98px)
				text-align: center
				font-size: 16px
				line-height: 22px

		&__gfx
			@include centerFlex(160px)
			flex-shrink: 1
			margin: 0 20px
			justify-content: center
			align-items: center
			align-content: center
			height: 160px
			clip-path: circle(50% at 50% 50%)
			-o-clip-path: circle(50% at 50% 50%)
			-ms-clip-path: circle(50% at 50% 50%)
			-moz-clip-path: circle(50% at 50% 50%)
			-webkit-clip-path: circle(50% at 50% 50%)
			background: radial-gradient(94.23% 94.23% at 0% 5.77%, #01CC9D 0%, #019A9A 100%)
			&--img
				max-width: 100%

		&__desc
			flex-shrink: 1
			@include centerBlock(465px)
			@media (max-width: 767.98px)
				margin-top: 10px
				text-align: center

			&--title
				font-family: Nunito, sans-serif
				font-size: 24px
				font-style: normal
				font-weight: 700
				line-height: 33px
				letter-spacing: 0em
				text-align: left
				margin: 0
				@media (max-width: 767.98px)
					text-align: center
					font-size: 16px
					line-height: 22px

			&--subtitle
				font-family: Nunito, sans-serif
				font-size: 18px
				font-style: normal
				font-weight: 300
				line-height: 24px
				letter-spacing: 0em
				text-align: left
				margin: 0 0 10px
				@media (max-width: 767.98px)
					text-align: center
					font-size: 16px
					line-height: 22px

			&--txt
				margin: 0 auto
				font-family: Montserrat, sans-serif
				font-size: 14px
				font-style: normal
				font-weight: 300
				line-height: 24px
				letter-spacing: 0em
				text-align: left
				@media (max-width: 767.98px)
					margin: 0 auto 40px
					text-align: center
					font-size: 12px
					line-height: 16px
					max-width: 325px

.wrapper-crew
	@include centerBlock(100%)
	background: radial-gradient(122.72% 100% at 2.53% 0%, #52D2B4 0%, #11425E 100%)
	mix-blend-mode: multiply
	position: relative
	&:after
		content: ""
		width: 100%
		height: 100%
		position: absolute
		top: 0
		left: 0
		opacity: .2
		@media (max-width: 767.98px)
			background: url(../img/crewbg.png) no-repeat left bottom/ cover
			transform: matrix(-1, 0, 0, 1, 0, 0)
		@media (min-width: 768px)
			background: url(../img/crewbg.png) no-repeat center bottom/ cover

	&__main
		@include centerFlex(725px)
		flex-direction: column
		align-items: center
		margin: 40px auto 100px
		@media (max-width: 767.98px)
			margin: 40px auto 0
			padding: 0 10px 20px
		*
			width: 100%

		&--title
			font-family: Nunito, sans-serif
			font-style: normal
			font-weight: 500
			font-size: 40px
			line-height: 55px
			letter-spacing: 0em
			text-align: center
			color: #FFFFFF
			margin: 40px 0 10px
			@media (max-width: 767.98px)
				text-align: left
				font-size: 16px
				line-height: 12px

		&--subtitle
			font-family: Montserrat, sans-serif
			font-size: 14px
			font-style: normal
			font-weight: 400
			line-height: 24px
			letter-spacing: 0em
			text-align: center
			color: #FFFFFF
			margin: 10px 0 40px
			@media (max-width: 767.98px)
				margin: 10px 0
				text-align: left
				font-size: 10px
				line-height: 15px

		&--desc
			font-family: Montserrat, sans-serif
			font-size: 16px
			font-style: normal
			font-weight: 400
			line-height: 24px
			letter-spacing: 0em
			text-align: left
			color: #FFFFFF
			margin: 10px 0 40px
			@media (max-width: 767.98px)
				text-align: left
				font-size: 12px
				line-height: 18px

.wrapper-contact
	@include centerFlex(100%)
	min-height: 800px
	margin: 0 auto !important
	background-color: #000
	position: relative
	@media (max-width: 767.98px)
		flex-direction: column
		align-items: center
	@media (min-width: 768px)
		padding: 180px 30px 0 !important
		justify-content: center
	&:before,
	&:after
		z-index: 0
		content: ""
		width: 100%
		height: 100%
		position: absolute
		top: 0
		left: 0
	&:before
		background: radial-gradient(54.26% 161.68% at 0% 16.18%, #00CF9E 0%, #0F8763 100%)
		opacity: .5
	&:after
		mix-blend-mode: multiply
		background: url(../img/contact.jpg) no-repeat center/ cover

	&__address
		flex-direction: column
		z-index: 2
		@media (max-width: 767.98px)
			@include centerFlex(330px)
		@media (min-width: 768px)
			@include centerFlex(500px)
		@media (min-width: 768px) and (max-width: 1299.98px)
			margin-right: 5vw
		@media (min-width: 1300px)
			margin-right: 100px

		&--title
			font-family: Nunito, sans-serif
			font-size: 36px
			font-style: normal
			font-weight: 300
			line-height: 1.4
			letter-spacing: 0em
			color: #fff
			margin: 0
			@media (max-width: 767.98px)
				text-align: left
				margin: 40px auto 0
				font-size: 18px
				line-height: 1.4
				margin-left: 0

		&--subtitle
			font-family: Montserrat, sans-serif
			font-size: 18px
			font-style: normal
			font-weight: 300
			line-height: 1.4
			letter-spacing: 0em
			color: #fff
			margin: 12px 0
			@media (max-width: 767.98px)
				text-align: center
				margin: 40px auto 0
				font-size: 14px
				line-height: 1.4

		&--desc
			font-family: Montserrat, sans-serif
			font-size: 18px
			font-style: normal
			font-weight: 300
			line-height: 34px
			letter-spacing: 0em
			color: #fff
			margin: 12px 0
			a
				text-decoration: none
				color: #fff

		&--addr
			background-color: #fff
			font-family: Montserrat, sans-serif
			font-size: 14px
			font-style: normal
			font-weight: 300
			line-height: 24px
			letter-spacing: 0em
			color: #000
			margin: auto 0 0
			padding: 30px
			max-width: 345px
			@media (max-width: 767.98px)
				display: none

	&-form
		justify-content: flex-start
		align-content: center
		flex-direction: column
		margin: 0 !important
		padding: 20px 0 !important
		flex-wrap: wrap
		z-index: 2
		@media (max-width: 767.98px)
			@include centerFlex(330px)
		@media (min-width: 768px)
			@include centerFlex(435px)

		&__head
			@include centerBlock(100%)
			margin: 0 auto !important
			padding: 20px 0

			&--desc
				font-family: Montserrat, sans-serif
				font-size: 18px
				font-style: normal
				font-weight: 300
				line-height: 22px
				letter-spacing: 0em
				margin: 0
				padding: 0
				color: #fff

		&__main
			@include centerFlex(435px)
			margin: 0 auto !important
			padding: 0
			flex-direction: column
			z-index: 2
			position: relative

			::-webkit-input-placeholder
				font-weight: 300 !important
			::placeholder
				font-weight: 300 !important
			::-ms-placeholder
				font-weight: 300 !important
			::-moz-placeholder
				font-weight: 300 !important

			&--input
				font-family: Nunito, sans-serif
				width: 100%
				box-shadow: 0px 0px 30px 0px #00000026
				font-family: Nunito, sans-serif
				font-size: 16px
				font-style: normal
				font-weight: 700
				line-height: 26px
				letter-spacing: 0em
				background: #FFFFFF
				height: 55px
				margin: 10px auto 0
				border-radius: 70px
				border: 3px solid #019A9A
				color: #000
				padding: 17px
				@media (max-width: 767.98px)
					max-width: 330px


			&--textarea
				font-family: Nunito, sans-serif
				width: 100%
				box-shadow: 0px 0px 30px 0px #00000026
				font-family: Nunito, sans-serif
				font-size: 16px
				font-style: normal
				font-weight: 700
				line-height: 26px
				letter-spacing: 0em
				background: #FFFFFF
				padding: 17px
				margin: 10px auto 0
				border-radius: 25px
				border: 3px solid #019A9A
				color: #000
				@media (max-width: 767.98px)
					max-width: 330px

			&--submit
				background: radial-gradient(91.66% 306.32% at 0% 16.18%, #01CC9D 0%, #019A9A 100%)
				border: 2px solid #FFFFFF
				box-sizing: border-box
				border-radius: 70px
				font-family: Nunito, sans-serif
				font-size: 18px
				font-style: normal
				font-weight: 800
				line-height: 25px
				letter-spacing: 0em
				text-align: center
				color: #FFFFFF
				height: 55px
				width: 100%
				margin: 20px 0
				cursor: pointer
