

.no-break
    white-space: nowrap
.d-none
    display: none

.m-auto
    margin: auto

.line-clamp-10
  display: -webkit-box
  -webkit-line-clamp: 10
  -webkit-box-orient: vertical  
  overflow: hidden
  text-overflow: ellipsis
  

.no-padding
    @media (max-width: 767.98px)
        padding: 0 !important

.pt-0
    padding-top: 0
.pt-20
    padding-top: 20px
.pb-0
    padding-bottom: 0
.mb-0
    margin-bottom: 0
.pb-30
    padding-bottom: 30px
.pb-20
    padding-bottom: 20px

.bb-0
    border-bottom:0 !important

.bb-1
    border-bottom:1px solid #BDBDBD

.relative
    position: relative

.z-index-20
    z-index: 20

@media (max-width: 1279.98px)
    .d-tablet-none
        display: none
    .d-tablet-block
        display: block

// sm
@media (max-width: 767.98px)
    .d-phone-none
        display: none
    .d-phone-block
        display: block
    .phone-text-left
        text-align: left
    .sm-pt-20
        padding-top: 20px !important
    .sm-pt-30
        padding-top: 30px !important
    .sm-pb-20
        padding-bottom: 20px !important
    .sm-pb-30
        padding-bottom: 30px !important
    .sm-pt-0
        padding-top: 0px !important
    .sm-pb-0
        padding-bottom: 0px !important
    .sm-mb-0
        margin-bottom: 0px !important

// md
@media (min-width: 767.99px)
    .md-pt-20
        padding-top: 20px !important
    .md-pb-20
        padding-bottom: 20px !important
    .md-pb-30
        padding-bottom: 30px !important
    .md-pt-30
        padding-top: 30px !important

    .md-mw-1280
        max-width: 1280px