.main-przedsiebiorca
	.wrapper-ent-top__hero,
	.form__main--submit,
	.wrapper-numbers-boxes-num__ico
		background: radial-gradient(54.26% 161.68% at 0% 16.18%, #00CF9E 0%, #019A9A 100%) no-repeat center/cover

	@media (min-width: 1080px)
		.wrapper-used-function-main
			&:first-of-type .func-active .wrapper-used-function-main-box__main:after,
			&:last-of-type .func-active .wrapper-used-function-main-box__main:after
				position: absolute
			// &:first-of-type .func-active .wrapper-used-function-main-box__main:after
			// 	content: url(../img/lapbox1.png)
			// 	width: 58%
			// 	height: 118%
			// 	bottom: 0
			// 	right: 0
			// &:last-of-type .func-active .wrapper-used-function-main-box__main:after
			// 	content: url(../img/lapbox2.png)
			// 	right: 0

// ==========================================
// ==========================================

.main-ksiegowy

	.margin-auto-top
		margin: 0 !important

	.form__main--submit:not(.btn-green),
	.wrapper-numbers-boxes-num__ico
		background: radial-gradient(43.63% 128.84% at 10.62% 27.13%, #019A9A 0%, #0083CC 100%) !important

	.slick-dots li
		border: 1px solid #0083cc !important
		&.slick-active
			background: #0083cc !important

	.wrapper-used-function-main
		// justify-content: flex-start
		&-box
			&__main--show
				max-width: 100% !important
			&.func-active
				@media (min-width: 1080px)
					// min-height: 420px !important
					.wrapper-used-function-main-box__main
						min-height: 310px !important
						padding: 55px 85px 35px
						// &:before
						// 	right: 26px !important
						// *
						// 	max-width: 100% !important

	.wrapper-icons
		margin: 0 auto 4px !important
		&--title
			font-size: 25px
			line-height: 34px
			margin: 40px 0 15px
		&__box
			.icons--dot,
			.icons--dot:before
				background-color: #D8D8D8
			.icons--dot:after
				color: #D8D8D8
			@media (min-width: 768px)
				&:first-of-type
					.icons--dot:before
						max-width: 1000px
						width: 50vw

	// ==========================================
	// ==========================================

	@media (max-width:767.98px)
		.wrapper-ent-top__header
			// &--desc
			// 	max-width: 300px
			.form__main
				// margin: 20px auto 40px !important

		.wrapper-acc-top
			flex-direction: column
			&__hero
				display: flex
				margin: 0
				&--img
					margin: -4vw 0 -4vw auto
					max-width: 40%
		.wrapper-ent-top__header
		.wrapper-ent-top__header--title,
		.wrapper-acc-top__header--title,
		.wrapper-top__header--title
			&:before
				background: radial-gradient(122.72% 100% at 2.53% 0%, #019A9A 0%, #0B70A9 100%)
			span,div
				max-width: 65%
			ul
				margin: 0

		.margin-auto
			margin: auto

	@media (max-width:400px)
		.wrapper-ent-top__header--title
			span,div
				max-width: 80%

	// ==========================================
	// ==========================================
	@media (min-width:768px)
		.wrapper-top__hero
			background: radial-gradient(122.72% 100% at 2.53% 0%, #019A9A 0%, #0B70A9 100%)
			mix-blend-mode: initial
			width: 50%
			&--img
				max-width: 130%

		.wrapper-acc-top
			border: none
			flex-wrap: wrap
			max-width: 1600px
			&__hero
				justify-content: flex-start
				top: auto

			&--desc
				max-width: 100%
				width: 100%
				span
					max-width: 940px
					margin: 0 auto !important
					display: block

		.wrapper-imgbox__hero
			margin: auto -60px
		.flex-end
			article
				margin-right: 2vw

		// .wrapper-imgbox__hero
		// 	transform: scale(1.275)

		.wrapper-ent-top__header
			&--title
				max-width: 500px
			&--desc
				max-width: 450px

	// ==========================================
	// ==========================================
	@media (min-width:768px) and (max-width: 1599.98px)
		.wrapper-acc-top__hero
			bottom: calc(-25vw + 420px) !important

	// ==========================================
	// ==========================================
	@media (min-width: 1600px)
		.wrapper-acc-top__hero
			top: 230px !important
			&--img
				width: 130%
				max-width: 130%
				margin: 200px 40px 0 -60px
