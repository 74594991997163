.tooltip
  position: absolute
  z-index: 1070
  display: block
  margin: 0
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"
  font-style: normal
  font-weight: 400
  line-height: 1.5
  text-align: left
  text-align: start
  text-decoration: none
  text-shadow: none
  text-transform: none
  letter-spacing: normal
  word-break: normal
  word-spacing: normal
  white-space: normal
  line-break: auto
  font-size: 0.875rem
  word-wrap: break-word
  opacity: 0

  &.show
    opacity: 0.9

  .arrow
    position: absolute
    display: block
    width: 0.8rem
    height: 0.4rem

    &::before
      position: absolute
      content: ""
      border-color: transparent
      border-style: solid

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"]
  padding: 0.4rem 0

.bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow
  bottom: 0

.bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before
  top: 0
  border-width: 0.4rem 0.4rem 0
  border-top-color: #000

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"]
  padding: 0 0.4rem

.bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow
  left: 0
  width: 0.4rem
  height: 0.8rem

.bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before
  right: 0
  border-width: 0.4rem 0.4rem 0.4rem 0
  border-right-color: #000

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"]
  padding: 0.4rem 0

.bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow
  top: 0

.bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before
  bottom: 0
  border-width: 0 0.4rem 0.4rem
  border-bottom-color: #000

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"]
  padding: 0 0.4rem

.bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow
  right: 0
  width: 0.4rem
  height: 0.8rem

.bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before
  left: 0
  border-width: 0.4rem 0 0.4rem 0.4rem
  border-left-color: #000

.tooltip-inner
  max-width: 200px
  padding: 0.25rem 0.5rem
  color: #fff
  text-align: center
  background-color: #000
  border-radius: 0.25rem