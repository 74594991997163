.current-perspektywa
	background-color: #F2F2F230

.perspektywa-mobile
	@media (max-width: 1023.98px)
		position: absolute
		font-size: 12px
		width: 110px
		bottom: 22px
		right: 160px
		text-align: center
		padding: 0 0 3px 0
		border-bottom: 1px solid #fff
	@media (min-width: 1024.98px)
		display: none

.border-bottom
	border-bottom: 1px solid #BDBDBD
	&.mobile
		@media (min-width: 768px)
			border: none !important

.font-price-tit
	font-family: Nunito, sans-serif
	font-size: 30px !important
	font-style: normal
	line-height: 33px !important
	letter-spacing: 0em
	text-align: left
	@media (max-width: 767.98px)
		font-size: 18px !important
		line-height: 24px !important
	&:after
		background-size: contain !important

.font-price-subtit
	font-family: Nunito, sans-serif
	font-size: 25px !important
	font-style: normal
	line-height: 29px !important
	letter-spacing: 0em
	text-align: left
	@media (max-width: 767.98px)
		font-size: 16px !important
		line-height: 24px !important

.line-center:after
	position: absolute
	bottom: 50%
	left: -150px
	right: 0
	margin: auto
	width: 1600px
	height: 1px
	background: #BDBDBD
	content: ""

.line-bottom
	position: relative
	&:after
		position: absolute
		bottom: 0%
		margin: auto
		width: 100vw
		height: 1px
		background: #BDBDBD
		content: ""
		z-index: -1
	&.bottom-margin:after
		bottom: 15%
	&.mobile:after
		@media (max-width: 768px)
			visibility: hidden

.mh-440
	@media (min-width: 768px)
		max-height: 440px !important
.mw-55
	@media (min-width: 768px)
		max-width: 55% !important

.mt-90
	@media (min-width: 768px) and (max-width: 1599.98px)
		margin-top: 90px !important

.mb-60
	@media (min-width: 768px) and (max-width: 1599.98px)
		margin-bottom: 60px !important

.mt-mob-desk
	@media (min-width: 768px) and (max-width: 1599.98px)
		margin-top: calc(15vw - 150px) !important
	@media (min-width: 1600px)
		margin-top: 100px !important

.img-bigger
	@media (min-width: 768px) and (max-width: 1599.98px)
		max-width: 110% !important
		margin: 0 0 0 -10px

.gm-style .place-card-large
	display: none !important

.fz-12
	@media (max-width: 767.98px)
		font-size: 12px !important
