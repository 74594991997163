.wrapper-footer
	@include centerFlex(100%)
	font-family: Montserrat, sans-serif
	margin: 0
	padding: 30px 10px
	justify-content: center
	align-items: center
	align-content: center
	transition: all .25s
	background-color: #131313
	*
		color: #fff

	@media (max-width: 767.98px)
		padding: 40px

	.footer-content
		@include centerFlex(1000px)
		margin: 0 auto
		padding: 0
		justify-content: space-between
		align-items: center

		&-nav
			@include centerFlex(100%)
			margin: 0
			padding: 0
			justify-content: space-between
			@media (max-width: 767.98px)
				flex-direction: column

			&__choose
				@include centerFlex(20%)
				margin: 30px 0
				padding: 0
				@media (max-width: 767.98px)
					@include centerFlex(100%)
					margin: 0 !important
					&--item
						width: 100%


			&__menu
				@include centerFlex(80%)
				justify-content: space-between

				@media (max-width: 767.98px)
					flex-direction: column
					@include centerFlex(100%)


			&__menu,
			&__choose
				margin: 30px 0
				padding: 0
				&--item
					font-size: 14px
					line-height: 17px
					@media (max-width: 767.98px)
						font-size: 18px
						line-height: 22px

			&__subchoose,
			.sub-menu
				margin: 40px 0 0
				padding: 0
				@media (max-width: 767.98px)
					margin: 18px 0 60px
				&--item,
				.footer-content-nav__menu--item
					font-size: 12px
					line-height: 15px
					margin: 0
					padding: 8px 0
					@media (max-width: 767.98px)
						line-height: 15px


			&__subchoose--item
				padding: 8px !important
				border-radius: 4px
				@media (max-width: 767.98px)
					width: 100%
					transition: all .25s
					padding: 18px !important
					border: 1px solid #DFDFDF30
					text-align: center
					&:first-of-type
						margin-bottom: 3px
				&:hover
					background-color: #F2F2F230

			&__menu,
			&__choose,
			&__subchoose,
			.sub-menu
				list-style: none
				flex-direction: column
				justify-content: flex-start
				gap: 28px
				&--item
					font-style: normal
					font-weight: 300
					letter-spacing: 0em
					padding: 0
					a
						text-decoration: none
					@media (min-width: 768px)
						padding: 0 10px
