
.otworz-dzialalnosc-gospodarcza{

    .zakladanie-firmy {
        background-image: url("../otworz-dzialalnosc-gospodarcza/img/main-header.png");
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;

    }

    .bg-logo {
        background-image: url("../otworz-dzialalnosc-gospodarcza/img/logo-bg.png");
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        min-height: 640px;

    }

    .wrapper-ent-top {
        max-width: 1040px;
        @media (min-width: 768px) {
            min-height: 458px;

        }
            &__header {
                margin: 80px 4vw 40px;
                align-self: flex-start;
                &--desc {
                    margin-top: 20%;
                    @media (max-width: 1300px) {
                        margin-top: 16%
                    }
                }
            }
            &__hero--img {
            height: 100%;
            width: auto;
            top: 0;
            right: 18%;
                @media (max-width: 1256px){
                    right: 4%;

                }
                @media (max-width: 767.98px) {
                    display: none;
                }
        }
    }
    .main-bubble {
        /* Auto layout */

        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 38px 36px;
        gap: 10px;

        position: absolute;

        right: 5%;
        top: 30%;

        background: #F5F5F5;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 0px 20px 20px 20px;
        span {
            font-family: 'Nunito';
            font-style: normal;
            font-weight: 600;
            font-size: 24px;
            line-height: 100%;
            color: #019A9A;
            white-space: nowrap;
        }
        @media (max-width: 1256px) {
            display: none;
            padding: 15px;
            right: 3%
        }
    }

    &--submit {
        &:not(.btn-blue):not(.btn-green) {
            background: radial-gradient(91.66% 306.32% at 0% 16.18%, #01CC9D 0%, #019A9A 100%);
        }
        border: 2px solid #FFFFFF;
        box-sizing: border-box;
        border-radius: 70px;
        font-family: Nunito, sans-serif;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 25px;
        letter-spacing: 0em;
        text-align: center;
        color: #FFFFFF;
        height: 55px;
        width: 100%;
        margin: 20px 0;
        cursor: pointer;
        transition: 333ms;
        @media (max-width:786px) {
            font-size: 14px;
        }
        &:hover {
            filter: brightness(1.1);
        }
    }

    .bg-green2{
        background: radial-gradient(99.17% 375.69% at 98.26% 92.93%, #00CF9E 0%, #009A9A 100%);
        h2{
            color: #fff;
        }

    }
    .bg-green-solid{
        background: #019A9A;
        color: #fff;
        p{
            color: #fff;
        }
    }

    .bg-gray {
        background: #f9f9f9;
    }
    .form-dont-have-nip {
        max-width: 724px;
        width: 100%;
        .flex-form {
            display: flex;
            flex-direction: row;
            gap: 25px;
        }
        &--input {
            background: #FFFFFF;
            box-shadow: 5px 5px 42px rgba(0, 207, 158, 0.24);
            border-radius: 4px;
        }
        &--submit {
            width: 80%;
        }
    }

    .wrapper-icons {
        &__box {
            &--title {
                font-size: 22px;
                font-weight: 600;
            }
            &--desc {
                font-family: 'Nunito';
                font-weight: 300;
                font-size: 18px;
                line-height: 25px;
                text-align: center;

            }
        }
    }

    // background-color: red;
    --gutter: 25px;

    img{
        max-width: 100%;
    }

    .row{
        display: flex;
        flex-wrap: wrap;
        padding: 0;
        box-sizing: border-box;
        @media (max-width: 767.98px) {
            padding-left: 10px;
            padding-right: 10px;
        }
        margin-left: calc(var(--gutter) * -1);
        margin-right: calc(var(--gutter) * -1);
    

        & > *{
            width: auto;
            flex-grow: 0;
            flex-shrink: 0;
            box-sizing: border-box;
            margin: 0;
            padding-left: var(--gutter);
            padding-right: var(--gutter);
        }
        .algin-center{
            align-self: center;
        }

        .col-sm-6{
            width: 50%;
        }
        .col-sm-12{
            width: 100%;
        }
        
        @media (min-width:600px){
            .col-md-6{
                width: 50%;
            }
            .col-md-12{
                width: 100%;
            }
        }
        @media (min-width:1000px){
            .col-lg-4{
                width: (100% * (4/12));
            }
            .col-lg-5{
                width: (100% * (5/12));
            }
            .col-lg-6{
                width: 50%;
            }
            .col-lg-7{
                width: (100% * (7/12));
            }
            .col-lg-8{
                width: (100% * (8/12));
            }
            .col-lg-12{
                width: 100%;
            }
        }
    }

    p.thick{
        font-weight: bold;
       
            background-image: url(../img/thick.svg);
            background-repeat: no-repeat;
            background-position: 1px 6px;
            background-size: 21px 10px;
            font-weight: bold;
            padding-left: 30px;
        
    }
    p.bigger{
        @media (min-width:600px){
            font-size: 22px;
        }
    }
    .text-font-bigger {
        @media (min-width:600px){
            font-size: 20px;
        }
    }
    h1{
        
        font-size: 25px;
        font-weight: 300;
        @media (min-width:600px){
            font-size: 40px;
        }
    }
    h2{
        font-size: 22px;
        font-weight: 300;

        @media (min-width:600px){
            font-size: 40px;
            .smaller {
                font-size: 26px;
            }
        }
    }
    .subtitle{
        @media (min-width:600px){
            font-size: 22px;
        }
    }

    .v-paddings{
        padding-top: var(--gutter);
        padding-bottom: var(--gutter);
    }
    .v-paddings-2{
        padding-top: calc(var(--gutter) * 2);
        padding-bottom: calc(var(--gutter)  * 2);
    }
    .content{
        max-width: 1200px;
        margin-left: auto;
        margin-right: auto;
        padding: var(--gutter);
    } 
    .text-left{
        text-align: left;
    }   
    .text-center{
        text-align: center;
    }
    @media (min-width:600px){
        .text-md-center{
            text-align: center;
        }
    }
    
    // typo
    .light{
        font-weight: 300;
    }
    .bold{
        font-weight: bold;
    }
    p.list-title {
        font-weight: 600;
        @media (min-width:600px){
            // padding-left: 1em;
            font-size: 20px;
        }
    }
    ul.list{
        list-style: decimal;
        margin-left: 0;
        padding-left: 0.7em;
        line-height: 1.4;
        @media (min-width:600px){
            // padding-left: 1em;
            font-size: 20px;
        }
        li{
            margin-bottom: 0.3em;
            font-weight: 600;
            a{
                text-decoration: none;
                &:hover{
                    text-decoration: underline;
                }
            }
        }
    }
    .steps.steps-4{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: 100%;
        margin-left: calc(var(--gutter) * -1);
        margin-right: calc(var(--gutter) * -1);
        > article{
            width: 50%;
            padding: var(--gutter);
            @media (min-width:1000px){
                width: 25%;
            }
        }

        .steps3__post--ico{
            width: 50%;
            margin: auto;
            img{
                width: 100%;
            }
        }
        .steps3__post--desc{
            border-top: 1px solid #000;
            padding-top: 0.5em;

        }
    }
    .steps{
        display: flex;
        flex-direction: row;

        flex-wrap: wrap;
        width: 100%;
        gap: 42px;
        padding: 0;
        @media (min-width: 1050px){
            flex-wrap: nowrap;
        }
        > article{
            width: 100%;
           @media (min-width: 1050px){
                width: 33%;
            }
        }
        .steps__post
        {
            &--desc{
                border-top: 1px solid #000;
                padding-top: 1em;
                margin-top: 0.5em;
                line-height: 1.2;
            }

            &--ico {
                width: 30%;
                margin: 0;
                align-items: center;
                display: flex;
                height: 105px;
                img {
                    width: 100%;
                }
            }
            &--title {
                font-size: 22px;
            }
        }
    }

    .wrapper-icons{

        .middle__text{
            position: absolute;
            top: -1em;
            right: 60%;
            width: 80%;
            text-align: center;
            padding: var(--gutter);
            @media (max-width:999px){
                display: none;
            }
        }
    }

    .wrapper-start {
        max-width: 1050px;
        h2 {
            max-width: 790px;
            text-align: center;
            margin-left: auto;
            margin-right: auto;
        }
        .content-with-image {
            display: flex;
            width: 100%;
            flex-direction: row;
        }
    }

    .list-wrapper {
        padding: 35px 50px 35px;
        background: #FFFFFF;
        box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
        border-radius: 0px 20px 20px 20px;
        .list-header {
            font-family: 'Nunito';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            .text-green {
                color: #019A9A
            }
        }
        .list-content {
            padding-left: 0;

            li {
                list-style-type: none;
                margin-bottom: 0.5rem;
                line-height: 205.23%;
                &::before {
                    content: "";
                    background-image: url(../img/check-circle.svg);
                    background-repeat: no-repeat;
                    background-position: 0px 4px;
                    background-size: 16px 16px;
                    font-weight: bold;
                    padding-left: 28px;
                }

            }
        }
        .steps-wrapper {
            display: flex;
            flex-direction: row;

            .steps-content {
                padding-left: 50px;
                .title {
                    font-weight: 600;
                    font-size: 20px;
                    line-height: 151.4%;
                }
            }

        }
    }

    .opinions {
        &__post {
            margin-bottom: 3rem;
            max-width: 1050px;
            display: flex;
            gap: 42px;
            flex-direction: row;
            align-items: center;
            @media (max-width: 767.98px) {
                flex-direction: column !important;
               }

            &--image {
                margin: 0;
                position: relative;
                width: 100%;
            }
            &__content {
                width: 100%;
                font-family: 'Nunito';
                font-style: normal;
                font-weight: 300;
                font-size: 24px;
                @media (max-width: 767.98px) {
                        font-size: 18px;
                }
                line-height: 150%;
                &--desc {
                }
                &--sign {
                    font-weight: bold;
                }
            }
            &:nth-of-type(even) {
                flex-direction: row-reverse;
                .opinions__bubble {
                    right: -25%; left: unset
                }
            }
        }
        &__bubble {
            background: #F5F5F5;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            border-radius: 20px 0px 20px 20px;
            position: absolute;
            padding: 38px;
            bottom: 20%;
            left: -25%;
            right: unset;
            color: #019A9A;
            font-size: 24px;
            @media (max-width: 767.98px) {
                font-size: 16px;
                padding: 15px;
                margin-left: auto;
                margin-right: auto;
                left: 0 !important;
                right: 0 !important;
                bottom: -3%;
                text-align: center;
            }

        }
    }

    .table__worth {
        text-align: center;
        border-collapse: collapse;
        thead {
            th {
                font-style: normal;
                font-weight: 600;
                font-size: 22px;
                line-height: 30px;
                text-align: center;
                padding: var( --gutter);
                img {
                    max-width: 30px;
                }
            }
        }
        tbody {
            td {
                border-bottom: 1px solid #000;
                line-height: 370.23%;
                &:first-child {
                    text-align: left;
                }
            }
            tr:last-child {
                td {border-bottom: unset;}
            }
        }
    }
    .w-100{
        width: 100%;
    }

    .mb-1{
        margin-bottom: calc(var(--gutter) * 1);
    }

    .mb-2{
        margin-bottom: calc(var(--gutter) * 2);
    }
    .mt-1{
        margin-top: calc(var(--gutter) * 1);
    }

    .mt-0{
        margin-top: 0 !important;
    }
    .mb-0{
        margin-bottom: 0 !important;
    }
    .pt-0{
        padding-top: 0 !important;
    }
    .pt-1{
        padding-top: 1em !important;
    }
    .pt-2{
        padding-top: 2em !important;
    }
    .pt-3{
        padding-top: 3em !important;
    }
    .pt-4{
        padding-top: 4em !important;
    }
    .pt-5{
        padding-top: 5em !important;
    }


    .pb-0{
        padding-bottom: 0 !important;
    }
    .pb-1{
        padding-bottom: 1em !important;
    }
    .pb-2{
        padding-bottom: 2em !important;
    }
    .pb-3{
        padding-bottom: 3em !important;
    }
    .pb-4{
        padding-bottom: 4em !important;
    }
    .pb-5{
        padding-bottom: 5em !important;
    }


    .accordion {
        cursor: pointer;
        padding: 18px;
        width: 100%;
        border: none;
        text-align: left;
        outline: none;
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 151.4%;
        transition: 0.4s;
        border-top: 1px solid #000;
    }


    .accordion:after {
        content: "";
        width: 12px;
        height: 12px;
        border-left: 2px solid #019A9A;
        border-bottom: 2px solid  #019A9A;
        transform: rotate(315deg);
        float: right;
        margin-bottom: 5px;
    }

    .active:after {
        transform: rotate(135deg);
        margin-top: 5px;

    }

    .panel {
        padding: 0 18px;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.2s ease-out;
    }
    .faq {
        margin-top: 2rem;
        max-width: 1080px;
        border-bottom: 1px solid #000;
        padding: 0;

    }
}