
.gotowi-na-ksef{

    .bg-green2{
        background: radial-gradient(99.17% 375.69% at 98.26% 92.93%, #00CF9E 0%, #009A9A 100%);
        h2{
            color: #fff;
        }

    }

    // background-color: red;
    --gutter: 25px;

    img{
        max-width: 100%;
    }

    .row{
        display: flex;
        flex-wrap: wrap;
        padding: 0;
        box-sizing: border-box;
        
        margin-left: calc(var(--gutter) * -1);
        margin-right: calc(var(--gutter) * -1);
    

        & > *{
            width: auto;
            flex-grow: 0;
            flex-shrink: 0;
            box-sizing: border-box;
            margin: 0;
            padding-left: var(--gutter);
            padding-right: var(--gutter);
        }
        .algin-center{
            align-self: center;
        }

        .col-sm-6{
            width: 50%;
        }
        .col-sm-12{
            width: 100%;
        }
        
        @media (min-width:600px){
            .col-md-6{
                width: 50%;
            }
            .col-md-12{
                width: 100%;
            }
        }
        @media (min-width:1000px){
            .col-lg-4{
                width: (100% * (4/12));
            }
            .col-lg-5{
                width: (100% * (5/12));
            }
            .col-lg-6{
                width: 50%;
            }
            .col-lg-7{
                width: (100% * (7/12));
            }
            .col-lg-8{
                width: (100% * (8/12));
            }
            .col-lg-12{
                width: 100%;
            }
        }
    }

    p.thick{
        font-weight: bold;
       
            background-image: url(../img/thick.svg);
            background-repeat: no-repeat;
            background-position: 1px 6px;
            background-size: 21px 10px;
            font-weight: bold;
            padding-left: 30px;
        
    }
    p.bigger{
        @media (min-width:600px){
            font-size: 22px;
        }
    }
    .text-font-bigger {
        @media (min-width:600px){
            font-size: 20px;
        }
    }
    h1{
        
        font-size: 25px;
        font-weight: 300;
        @media (min-width:600px){
            font-size: 40px;
        }
    }
    h2{
        font-size: 22px;
        font-weight: 300;
        @media (min-width:600px){
            font-size: 44px;
        }
    }
    .subtitle{
        @media (min-width:600px){
            font-size: 22px;
        }
    }

    .v-paddings{
        padding-top: var(--gutter);
        padding-bottom: var(--gutter);
    }
    .v-paddings-2{
        padding-top: calc(var(--gutter) * 2);
        padding-bottom: calc(var(--gutter)  * 2);
    }
    .content{
        max-width: 1200px;
        margin-left: auto;
        margin-right: auto;
        padding: var(--gutter);
    } 
    .text-left{
        text-align: left;
    }   
    .text-center{
        text-align: center;
    }
    @media (min-width:600px){
        .text-md-center{
            text-align: center;
        }
    }
    
    // typo
    .light{
        font-weight: 300;
    }
    .bold{
        font-weight: bold;
    }
    p.list-title {
        font-weight: 600;
        @media (min-width:600px){
            // padding-left: 1em;
            font-size: 20px;
        }
    }
    ul.list{
        list-style: decimal;
        margin-left: 0;
        padding-left: 0.7em;
        line-height: 1.4;
        @media (min-width:600px){
            // padding-left: 1em;
            font-size: 20px;
        }
        li{
            margin-bottom: 0.3em;
            font-weight: 600;
            a{
                text-decoration: none;
                &:hover{
                    text-decoration: underline;
                }
            }
        }
    }
    .ksef-steps.steps-4{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: 100%;
        margin-left: calc(var(--gutter) * -1);
        margin-right: calc(var(--gutter) * -1);
        > article{
            width: 50%;
            padding: var(--gutter);
            @media (min-width:1000px){
                width: 25%;
            }
        }

        .ksef-steps__post--ico{
            width: 50%;
            margin: auto;
            img{
                width: 100%;
            }
        }
        .ksef-steps__post--desc{
            border-top: 1px solid #ccc;
            padding-top: 0.5em;

        }
    }
    .ksef-steps2{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: 100%;
        padding: 0;
        > article{
            width: 100%;
            padding: calc(var(--gutter)/2);
    
            @media (min-width: 450px){
                width: 33%;
            }
    
            @media (min-width: 1000px){
                width: 20%;
            }
        }
        .ksef-steps2__post--desc{
            border-top: 1px solid #fff;
            padding-top: 1em;
            margin-top: 0.5em;
            line-height: 1.2;
        }
        .ksef-steps2__post--ico{
            width: 30%;
            margin: 0;
            img{
                width: 100%;
            }

        }
    }

    .wrapper-icons{

        .middle__text{
            position: absolute;
            top: -1em;
            right: 60%;
            width: 80%;
            text-align: center;
            padding: var(--gutter);
            @media (max-width:999px){
                display: none;
            }
        }
    }

    .w-100{
        width: 100%;
    }

    .mb-1{
        margin-bottom: calc(var(--gutter) * 1);
    }

    .mb-2{
        margin-bottom: calc(var(--gutter) * 2);
    }
    .mt-1{
        margin-top: calc(var(--gutter) * 1);
    }

    .mt-0{
        margin-top: 0 !important;
    }
    .pt-0{
        padding-top: 0;
    }
}